import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import BaseType from "./base-type";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("common-api.types.boolean."+id, args);
}


export default class PnBoolean extends BaseType {
    constructor(value) {
        super();
        this.value = value;
        this.$type = "boolean";
        this.$typeCategory = "literal";
    }

    $toPrintable() { return this.value ? "True" : "False" }
    $toDebugDisplay() { return this.value }
    $toJsLiteral() { return this.value }

    $validateOperation(op, rightSide) {
        const opType = this.$getOpType(op);
        const rsType = typeof rightSide.value;
        let valid = false;

        if (opType === "boolean" && rsType !== "boolean")
            reportError("incompatible_boolean_op", {op});

        if (opType === "boolean" && rsType === "boolean")
            valid = true;

        if ((op === "eq" || op === "neq") && rsType === "boolean")
            valid = true;           

        !valid && this.$reportIncompatibleTypes(op, rightSide);
    }

}