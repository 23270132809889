import { createObject } from "../../interpreter/api-helpers";
import $Global from './_global';
import Circle from "./circle";
import Rect from "./rect";
import Ellipse from "./ellipse";
import Wedge from "./wedge";
import Line from "./line";
import Polygon from "./polygon";
import Text from "./text";
import Stage from "./stage";
import Group from "./group";

export const api = {
    globalObj: createObject($Global),
    classes: {
        $Global, Circle, Rect, Ellipse, Wedge, Line, Polygon, Text, Group
    }
}
