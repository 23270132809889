import React, { useState, useEffect, useRef } from "react";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import "./file-explorer.css";
import { eventHandler } from './internal-handlers';
import { NodeContainer } from './node-container';

let WAITING_TIME_UNTIL_OVERLAY = 300;
let waitingTimer = null;
export const FileExplorer = (props) => {
    let [tree, _setTree] = useState(null);
    let [uiState, setUiState] = useState("waiting"); // can be "normal", "naming", "waiting", "timeout, or "error"
    let [errorMessage, setErrorMessage] = useState("");
    let [activeNode, setActiveNode] = useState(null); // The node that will be highlighted
    let [hasWaitingOverlay, setHasWaitingOverlay] = useState(false);

    const setTree = (newTree) => {
        props.treeUpdateHandler(newTree);
        _setTree(newTree);
    }

    const onEventError = (msg) => {
        setErrorMessage(msg);
        setUiState("error");
    }

    const onTimeout = () => {
        setUiState("timeout");
    }

    const retryServerConnect = () => {
        setUiState("waiting");
        dispatchEvent("getTree", "initialRequest", "/");
    }

    const dispatchEvent = (type, phase, payload) => {
        eventHandler({
            externalHandlers: props.handlers,
            errorCallback:  onEventError,
            timeoutCallback: onTimeout,
            openFileCallback: props.onOpenFile,
            tree, 
            setTree,
            uiState,
            setUiState,
            type, 
            phase,
            payload
        });
    }

    const refresh = (fileToOpen=null, stateOfFileToOpen=null) => {
        dispatchEvent("getTree", "initialRequest", { fileToOpen, stateOfFileToOpen, setActiveNode, projectId: props.projectId });
    }

    useEffect(() => {   
        props.refreshSetter(refresh);
        refresh();
    }, []);
    
    // Display the "waiting" overlay if we've been waiting for the server for awhile
    props.overlay.style.display = uiState === "waiting" ? "block" : "none";
    if (uiState !== "waiting" && hasWaitingOverlay) {
        setHasWaitingOverlay(false);
        waitingTimer = null;
    }
    if (uiState === "waiting" && waitingTimer === null) {
        waitingTimer = setTimeout(() => {
            if (uiState === "waiting")
                setHasWaitingOverlay(true);
        }, WAITING_TIME_UNTIL_OVERLAY);
    }
    props.overlay.style.opacity = hasWaitingOverlay ? 0.5 : 0;

    return <>
        { (uiState === "normal" || uiState === "naming" || (uiState === "waiting" && tree !== null)) && <FileExplorerUI 
            tree={tree} 
            eventDispatcher={dispatchEvent} 
            uiState={uiState}
            activeNode={activeNode}
            setActiveNode={setActiveNode}
        /> }
        { uiState === "error" && <FileExplorerError message={errorMessage} clearError={() => setUiState("normal")}/> }
        { uiState === "timeout" && <FileExplorerTimeout retry={retryServerConnect}/> }
    </>

}

export const FileExplorerError = (props) => {

    return <div className="file-explorer-error">
        <h3>An Error Occurred</h3>
        <p>{props.message}</p>
        <p style={{ textAlign: "center" }}><button onClick={props.clearError}>OK</button></p>
    </div>
}

export const FileExplorerTimeout = (props) => {

    return <div className="file-explorer-error">
        <h3>An Error Occurred</h3>
        <p>Could not connect to server.</p>
        <p style={{ textAlign: "center" }}><button onClick={props.retry}>Retry</button></p>
    </div>
}

const FileExplorerUI = (props) => {
    const { activeNode, setActiveNode } = props;
    const inputFileRef = useRef();

    const flattened = flatten(props.tree);
    return <DndProvider backend={HTML5Backend}>
        <div className="file-explorer">
        {flattened.map((node, i) =>
            <NodeContainer
                node={node}
                key={i}
                eventDispatcher={props.eventDispatcher}
                activeNode={activeNode}
                setActiveNode={setActiveNode}
                tree={props.tree}
                inputFileRef={inputFileRef}
                uiState={props.uiState}
            />)
        }
        <FileChooser inputFileRef={inputFileRef} eventDispatcher={props.eventDispatcher} />
        </div>
    </DndProvider>
}

const FileChooser = ({inputFileRef, eventDispatcher}) => {
    
    const handleChange = (event) => {
        eventDispatcher("uploadFile", "userConfirm", { file: event.target.files[0] }); 
    }

    return <form>
        <input 
            type="file" 
            onChange={handleChange} 
            ref={inputFileRef} 
            onClick={ev => {ev.target.value = ""} }
            style={{ display: "none" }} />
      
    </form>
}

const flatten = (tree) => {
    const processFolder = (branch, level, path, isTrash) => {
        for (let node of branch) {
            isTrash = node.type === "trash" ? true : isTrash;
            result.push({
                name: node.name,
                type: node.type,
                level: isTrash? 0 : level,
                fullPath: path + node.name,
                isExpanded: node.isExpanded,
                isTrash,
                state: node.state
            });
            if ((node.type === "folder" || node.type === "trash") && node.isExpanded) {
                processFolder(node.children, level + 1, path + node.name + "/", isTrash);
            }
        }
    }

    let result = [];
    processFolder([tree], 0, "/", false);
    return result;

}