import Konva from "konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import KonvaShape from "./konva-shape";

export default class Wedge extends KonvaShape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Create a new _Circle_ object, with the specified initial properties.
        @number x The x coordinate of the center of the circle
        @number y The y coordinate of the center of the circle
        @number radius
        @number angle
        @any color
    ` ]
    constructor(x=100, y=100, radius=50, angle=90, color="black") {
        super();
        this.$boundsRelativeToXY = { top: -radius, bottom: radius, left: -radius, right: radius }
        this.$createShape(Konva.Wedge, color, { x, y, radius, angle });
    }

    static $propdoc_radius = "The radius of the circle that the wedge is taken from."
    set radius(value) { this.$konvaSetter('radius', value, "number") } 
    get radius() { return this.$konvaGetter('radius') }

    static $propdoc_angle = "The angle, in degrees, representing how much of a slice of the circle is used for the wedge."
    set angle(value) { this.$konvaSetter('angle', value, "number") } 
    get angle() { return this.$konvaGetter('angle') }

}