import { debugHandler } from "./common-api";
import * as interpreter from "../interpreter/interpreter";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, OPTIONAL } from "../interpreter/api-helpers";
import * as execution from '../interpreter/execution/execution';
import PnError from "../interpreter/pn-error";

const reportError = (id, args) => { 
    throw new PnError("common-api._global."+id, args);
}


export default class $Global {

    /*** pNatural specific functions ***/
    static $_debug = [NO_BLOCK, NO_RETURN, `
    Prints a value to the debug console.
        @any value  The value to be printed.`, true ];
    debug(value) { 
        debugHandler(value.$toDebugDisplay(), interpreter.curLocation);  }


    static $_now = [NO_BLOCK, RETURNS,
    `Returns the number of milliseconds elapsed since January 1, 1970.`]
    now() {  return Date.now();   }

    static $_openUrl = [NO_BLOCK, NO_RETURN, `
    Opens a URL in a new window
        @string url  The URL to open.`];
    openUrl(value) { 
        window.open(value);  }

    /***  Python equivalent functions ***/

    static $_abs = [NO_BLOCK, RETURNS,
    `Returns the absolute value of the given number.
        @number number `]
    abs(num) {  return Math.abs(num);   }


    static $_chr = [NO_BLOCK, RETURNS,
    `Given the number of a unicode character, returns the character.
        @number number `]
    chr(num) {   return String.fromCharCode(num);  }

    static $_exit = [NO_BLOCK, NO_RETURN,
    `Stops the program.`]
    exit() {  execution.stop() }


    static $_hasattr = [NO_BLOCK, RETURNS, `
    Given an object and an attribute name (aka property name), returns **True** if the object contains an 
    attribute by that name. Otherwise returns **False**.
        @object object      The object to search
        @string attr_name   The name of the attribute to serach for `];
    hasattr(obj, attr_name) {  return attr_name in obj;  }


    static $_int = [NO_BLOCK, RETURNS, `
    Returns the integer representation of the given string, so that it can be used in a mathematical expression.
        @string string `]
    int(num) {  return parseInt(num);  }


    static $_irange = [NO_BLOCK, RETURNS, `
    Returns a list of numbers consisting of the range specified by the parameters.
        @number start The number the range starts at, inclusive.
        @number stop The number the range stops at, inclusive.
        ?number by The number to count by
    ` ]
    irange(start, stop, by) {  
        if (by === undefined)
            by = 1;
        else
            by = Math.abs(by);

        const result = [];
      
        if (start < stop) {
            for (let i = start; i <= stop; i += by)
                result.push(i);
        } 
        else {
            for (let i = start; i >= stop; i -= by)
                result.push(i);
        }
      
        return result;
    }


    static $_len = [NO_BLOCK, RETURNS, `
    Returns the length of the given list or string.
        @any value `, true];
    len(value) { 
        value.$type !== "list" && value.$type !== "string" && reportError("invalid_type_with_len", { type: value.$type });
        return value.value.length;  
    }


    static $_max = [NO_BLOCK, RETURNS, `
    Returns the maximum value of all given parameters.
        *number numbers You can provide as many numbers as you'd like.`];
    max() { return Math.max(...arguments);  }


    static $_min = [NO_BLOCK, RETURNS, `
    Returns the minimum value of all given parameters.
        *number numbers You can provide as many numbers as you'd like.`];
    min() { return Math.min(...arguments);  }


    static $_ord = [NO_BLOCK, RETURNS,
    `Given a unicode character, returns the unicode number. If the given string is longer than one character, the
    unicode number of the first character will be returned.
        @string character `]
    ord(str) {   return str.length ? str.charCodeAt(0) : 0; }


    static $_pow = [NO_BLOCK, RETURNS, `
    Returns the result of _base_ to the power of _exp_.
        @number base
        @number exp `]
    pow(base, exp) {  return Math.pow(base, exp);  }


    static $_range = [NO_BLOCK, RETURNS, `
    Returns a list of numbers consisting of the range specified by the parameters.
        @number start The number the range starts at, inclusive. If only one parameter is provided, then the returned range starts at zero and ends at this number minus one.
        ?number stop The number the range stops at, excusive.
        ?number step The amount to count up by. The default is 1.` ]
    range(start, stop, step=1) {  
        if (stop === undefined) {
            stop = start;
            start = 0;
        }

        const result = [];
        if (step > 0) {
            for (let i = start; i < stop; i += step)
                result.push(i); 
        }
        else {
            for (let i = start; i > stop; i += step)
                result.push(i); 
        }

        return result;
    }


    static $_real = [NO_BLOCK, RETURNS, `
    Returns the real (ie decimal) representation of the given string, so that it can be used in a mathematical
    expression. Equivalent to Python's _float_ funciton.
        @string string `]
    real(num) {  return Number(num);  }


    static $_random = [NO_BLOCK, RETURNS, `
    Returns a random decimal number between 0 and 1` ]
    random() {  return Math.random();  }


    static $_randomInt = [NO_BLOCK, RETURNS, `
    Returns a random integer between _lower_bound_ and _upper_bound_.
        @number lower_bound
        @number upper_bound
    ` ]
    randomInt(lower, upper) {  
        const range = upper - lower + 1;
        return Math.floor(Math.random() * range + lower);
    }


    static $_round = [NO_BLOCK, RETURNS, `
    Returns a given number to a specified number of digits. If no number of digits is specified, rounds to the
    nearest integer.
        @number number      The number to round.
        ?number digits      How many digits to round to. `]
    round(num, digits=0) {  return Number(num.toFixed(digits));   }


    static $_type = [NO_BLOCK, NO_RETURN, `
    Returns the data type of the given value. Can be number, string, boolean, list or object.
        @any value  The value to be printed.`, true ];
    type(value) { return value.$type; }

}