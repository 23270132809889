import Sprite from './sprite';
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import phaser from "../phaser";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("host-phaser.phaser-api.player-sprite."+id, args);
}

export default class PlayerSprite extends Sprite {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Creates a new PlayerSprite at the given coordinates. 
        @number x
        @number y
        @string path The path to the image that the sprite should use. It should be an absolute path beginning with /.
    `]
    constructor(x=20, y=20, path="") {
        super(x, y, path);
        this.update = this.update.bind(this);
        this.playerSpriteAutoUpdate = this.playerSpriteAutoUpdate.bind(this);
        this.$playerProps = {
            downKey: ["string", "ArrowDown"],
            upKey: ["string", "ArrowUp"],
            leftKey: ["string", "ArrowLeft"],
            rightKey: ["string", "ArrowRight"],
            speed: ["number", 50],
            isMoving: ["boolean", false],
            allowOffScreen: ["boolean", false]
        }
        for (let name in this.$playerProps) {
            this.$addProp(name,
                (pnValue) => { 
                    const expectedType = this.$playerProps[name][0];
                    const receivedType = pnValue.$type;
                    expectedType !== receivedType && reportError("player_prop_invalid_type", {name, expectedType, receivedType})
                    this.$playerProps[name][1] = pnValue.value 
                },
                () => createBuiltInTypeObject(this.$playerProps[name][1])
            );
        }
    }

    static $propdoc_downKey = "The key used to move the sprite down. Default is the down arrow.";
    static $propdoc_upKey = "The key used to move the sprite up. Default is the up arrow.";
    static $propdoc_leftKey = "The key used to move the sprite left. Default is the left arrow.";
    static $propdoc_rightKey = "The key used to move the sprite right. Default is the right arrow.";
    static $propdoc_speed = "The speed the sprite should move at. Default is 50.";
    static $propdoc_isMoving = "This is automatically set on each frame update to indicate if the sprite is moving.";



    static $_update = [NO_BLOCK, NO_RETURN, 
    `If your PlayerSprite does not override the *update* method, this one will be used, which simply calls *playerSpriteAutoUpdate*. 
        @object events
    `];
    update(events) {
        this.playerSpriteAutoUpdate(events);
    } 

    static $_playerSpriteAutoUpdate = [NO_BLOCK, NO_RETURN, 
    `If you override the *update* method, you should call this method somewhere within your own custom *update* method. It will make the PlayerSprite respond to key presses.
    `];
    playerSpriteAutoUpdate() {
        const isKeyDown = (name) => !!phaser.getKeysDown()[this.$playerProps[name][1]];
        const speed = this.$playerProps.speed[1];
        let xVel = 0;
        let yVel = 0;
        
        xVel += isKeyDown("rightKey") ? speed : 0;
        xVel -= isKeyDown("leftKey") ? speed : 0;
        yVel += isKeyDown("downKey") ? speed : 0;
        yVel -= isKeyDown("upKey") ? speed : 0;

        if (this.$phaserObject.x <= 0 && xVel < 0 && !this.$playerProps.allowOffScreen[1])
            xVel = 0;
        if (this.$phaserObject.x > phaser.getMapDimensions().width && xVel > 0 && !this.$playerProps.allowOffScreen[1])
            xVel = 0;
        if (this.$phaserObject.y <= 0 && yVel < 0 && !this.$playerProps.allowOffScreen[1])
            yVel = 0;
        if (this.$phaserObject.y > phaser.getMapDimensions().height && yVel > 0 && !this.$playerProps.allowOffScreen[1])
            yVel = 0;

        this.$playerProps.isMoving = xVel !== 0 || yVel !== 0;

        this.setVelocityX(xVel);

        if (phaser.getGravity().y == 0 || !this.$allowsGravity)
            this.setVelocityY(yVel);
    } 

}