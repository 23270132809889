import { setHTMLById, createElement, getElementById } from "./dom-helpers";
import { openTab, ERROR_TAB, DEBUG_CONSOLE_TAB } from './status-pane/tabs.js';
import { config } from "./index";
import { setDebugEntries } from './section-sidebar-right';
import { handleEvent as serverRequest } from "./file-explorer/handlers";
import './status-pane/errors.css';

export const handleProgramTermination = () => {
    getElementById("run-button").disabled = false;
    getElementById("stop-button").disabled = true;
}

export const handleError = (err) => {
    getElementById("run-button").disabled = false;
    getElementById("stop-button").disabled = true;
    const tbody = createElement("tbody");
    const addRow = (heading, data) => {
        const tr = createElement("tr");
        const tdHead = createElement("td", heading, { className: 'error-detail-table-heading'});
        const tdValue = createElement("td", data);
        tr.appendChild(tdHead);
        tr.appendChild(tdValue);
        tbody.appendChild(tr);
    }
    
    openTab(ERROR_TAB);
    
    err.filename !== "" && addRow("File:", err.filename);
    err.line !== "" && addRow("Line Number:", err.line);
    addRow("Details:", err.message);

    const errorHtml = `
    <div class="error-message">
        <h2 class="error-header">An Error Occurred</h2>
        <table id="error-detail-table" class="error-detail-table"></table>
    </div>`;
    setHTMLById("error-tab", errorHtml);
    getElementById("error-detail-table").appendChild(tbody);
}

export const handleDebug = (value, location) => {
    if (config.testMode) return;
    openTab(DEBUG_CONSOLE_TAB);
    let locStr = location.filename.length > 0 ? 
        location.filename : config.curOpenFile.substring(config.curOpenFile.lastIndexOf('/')+1);
     
    if (String(location.line).length > 0)
        locStr += ":"+location.line;

    config.debugEntries.push({name: "", data: value, location: locStr })
    setDebugEntries([...config.debugEntries]);
}

export const fetchFile = path => {

    return new Promise((resolve, reject) => {
            serverRequest("downloadFile", { path: "/[Project Root]/"+path }, 
            (request, result) => resolve(window.atob(result.fileContent)), 
            err => reject(err),
            () => reject("server timeout")
        ); 
    })  
}
