import Konva from "konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import KonvaShape from "./konva-shape";

export default class Ellipse extends KonvaShape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Create a new _Ellipse_ object, with the specified initial properties.
        @number x The x coordinate of the center of the ellipse
        @number y The y coordinate of the center of the ellipse
        @number xRadius
        @number yRadius
        @any color
    ` ]
    constructor(x=100, y=100, xRadius=50, yRadius=50, color="black") {
        super();
        this.$boundsRelativeToXY = { top: -yRadius, bottom: yRadius, left: -xRadius, right: xRadius }
        this.$createShape(Konva.Ellipse, color, { x, y, radiusX: xRadius, radiusY: yRadius });
    }

    static $propdoc_xRadius = "The x radius of the ellipse."
    set xRadius(value) { this.$konvaSetter('radiusX', value, "number") } 
    get xRadius() { return this.$konvaGetter('radiusX') }

    static $propdoc_yRadius = "The y radius of the ellipse."
    set yRadius(value) { this.$konvaSetter('radiusY', value, "number") } 
    get yRadius() { return this.$konvaGetter('radiusY') }
}