import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import { createObject, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import BaseType from "./base-type";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("common-api.types.list."+id, args);
}


export default class PnList extends BaseType {
    constructor(value) {
        super();
        this.value = value;
        this.$type = "list";
        this.$typeCategory = "list";
        this.append = this.append.bind(this);
        this.extend = this.extend.bind(this);
        this.insert = this.insert.bind(this);
        this.remove = this.remove.bind(this);
        this.pop = this.pop.bind(this);
        this.clear = this.clear.bind(this);
        this.count = this.count.bind(this);
        this.reverse = this.reverse.bind(this);
        this.delete = this.delete.bind(this);
    }

    $toPrintable() { 
        return this.value.map(item => {
            if (item.$type === "object")
                return "[Object]"
            if (item.$type === "list")
                return "[List]"
            return item.value;
        })
    }
    $toDebugDisplay(reflist=[]) { 
        if (reflist.includes(this))
            return "[Circular Reference]";
        reflist.push(this);
        return this.value.map(item => item.$toDebugDisplay(reflist)) 
    }
    $toJsLiteral() { return this.value }

    $index(isRange, start, end) {

        //console.log(start);

        this.$validateIndexRange(isRange, start, end, this.value.length-1);

        if (!isRange) return this.value[start.value];
        let newArr;
        if (end === null) 
            newArr = [...this.value].splice(start.value);
        else if (start === null)
            newArr = [...this.value].splice(0, end.value+1);
        else 
            newArr = [...this.value].splice(start.value, end.value-start.value+1);
        

        return createObject(PnList, [newArr]);
        
    }

    $getOperationResult(op, rightSide) {

        if (op === "plus")
            return createObject(PnList, [this.value.concat(rightSide.value)]);

        if (op === "multiply") {
            let cur = [];
            for (let i = 0; i < rightSide.value; i++) {
                cur = cur.concat(this.value);
            }
            return createObject(PnList, [cur]);
        }
    }

    $validateOperation(op, rightSide) {
        const rsType = typeof rightSide.value;
        let valid = false;
        if (op === "index" && rsType === "number")
            valid = true

        if (op === "plus" && rsType === "object" && Array.isArray(rightSide.value))
            valid = true;

        if (op === "multiply" && rsType === "number")
            valid = true;

        !valid && this.$reportIncompatibleTypes(op, rightSide);
    }

    static $_append = [NO_BLOCK, NO_RETURN,
    `Adds the given value to the list.
        @any value` ]
    append(item)  { this.value.push(createBuiltInTypeObject(item)) }


    static $_extend = [NO_BLOCK, NO_RETURN,
    `Extends the list by joining _list2_ onto the end of it.
        @list list2` ]
    extend(list)  { this.value = this.value.concat(list) }


    static $_insert = [NO_BLOCK, NO_RETURN,
    `Inserts the given item at the specified index. A negative index will count from the end of the array.
        @number index
        @any item` ]
    insert(index, item)  { this.value.splice(index, 0, createBuiltInTypeObject(item)) }


    static $_remove = [NO_BLOCK, NO_RETURN,
    `Remove the first occurrence of the given item from the list.
        @any item` ]
    remove(search)  { 
        const index = this.value.findIndex(item => item.value === search)
        if (index > -1)
            this.value.splice(index, 1);
    }

    static $_pop = [NO_BLOCK, RETURNS,
    `Remove the last item from the list and return its value. Returns 0 if the array is empty.`  ]
    pop()  { return this.value.length === 0 ? createBuiltInTypeObject(0) : this.value.pop() }


    static $_clear = [NO_BLOCK, NO_RETURN,
    `Remove the last item from the list and return its value. Returns 0 if the array is empty.`  ]
    clear()  { this.value.splice(0, this.value.length) }


    static $_count = [NO_BLOCK, RETURNS,
    `Returns the number of occurrences of the specified item in the list.
        @any item` ]
    count(search)  { 
        return this.$toDebugDisplay().reduce((matches, cur) => cur === search ? matches+1 : matches, 0);
    }

    static $_reverse = [NO_BLOCK, NO_RETURN,
    `Reverses the order of the elements in the list.`  ]
    reverse()  { this.value.reverse() }

    static $_delete = [NO_BLOCK, NO_RETURN,
    `Deletes the list item at the given index. A negative index will count from the end of the array.
        @number index` ]
    delete(index)  { this.value.splice(index, 1);  }
}