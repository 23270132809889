import Konva from "konva";

export let layer;
export const init = () => {
    let stage = new Konva.Stage({
        container: 'output-container',
        width: 640,
        height: 400
    });
    layer = new Konva.Layer();
    stage.add(layer);
}

export let autoAdd = false;
export const setAutoAdd = value => { autoAdd = value };

export const addToImage = obj => layer.add(obj);
export const reset = () => layer.destroyChildren();

export const addGrid = () => {

    // Draw vertical lines and labels
    for (let i = 50; i <= layer.width(); i += 50) {
        let line = new Konva.Line({
            points: [i, 0, i, layer.height()],
            stroke: '#CCC',
            strokeWidth: 1,
        });
        layer.add(line)
        let text = new Konva.Text({
            x: i - 8,
            y: 0,
            text: i,
            fontSize: 10,
            fontFamily: 'Calibri',
            fill: '#999',
        });
        layer.add(text)
    }

    // Draw horizontal lines and labels
    for (let i = 50; i <= layer.height(); i += 50) {
        let line = new Konva.Line({
            points: [0, i, layer.width(), i],
            stroke: '#CCC',
            strokeWidth: 1,
        });
        layer.add(line)
        let text = new Konva.Text({
            x: 0,
            y: i - 10,
            text: i,
            fontSize: 10,
            fontFamily: 'Calibri',
            fill: '#999',
        });
        layer.add(text)
    }
}
