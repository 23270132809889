export const createElement = (type, content ="", config = {}) => {
    const el = document.createElement(type);
    el.innerHTML = content;
    for (let key in config.attributes) {
        el.setAttribute(key, config.attributes[key]);
    }
    for (let key in config.events) {
        el.addEventListener(key, config.events[key])
    }
    if ("className" in config) {
        el.setAttribute("class", config.className);
    }
    if ("id" in config) {
        el.setAttribute("id", config.id);
    }
    return el;
}

export const getElementById = (id) => document.getElementById(id);
export const appendChildById = (id, element) => getElementById(id).appendChild(element);
export const setHTMLById = (id, html) => getElementById(id).innerHTML = html;

export const showWaitingDialog = (msg) => {
    getElementById("waiting-dialog-content").innerHTML = msg;
    getElementById("waiting-dialog").showModal();
}

export const hideWaitingDialog = () => getElementById("waiting-dialog").close();