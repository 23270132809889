import React from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { Node } from "./node";

// The NodeContainer handles context menu operations

export const NodeContainer = (props) => {
    let { node, inputFileRef } = props;

    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : null,
        );
    };

    const handleClose = () => setContextMenu(null);

    const handleMenuSelection = (selection) => {
        setContextMenu(null);
        const handlers = {
            moveToTrash: () => props.eventDispatcher("moveToTrash", "initialRequest",  { path: node.fullPath }),
            rename: () => props.eventDispatcher("rename", "initialRequest", { path: node.fullPath }),
            emptyTrash: () => props.eventDispatcher("emptyTrash", "initialRequest", { path: node.fullPath }),
            deletePermanently: () => props.eventDispatcher("deletePermanently", "initialRequest", { path: node.fullPath }),
            newFile: () => props.eventDispatcher("addNode", "initialRequest", { path: node.fullPath, type: "file" }),
            newFolder: () => props.eventDispatcher("addNode", "initialRequest", { path: node.fullPath, type: "folder" }),
            uploadFile: () => props.eventDispatcher("uploadFile", "initialRequest", { path: node.fullPath, inputFileRef }), 
        }
        handlers[selection]();
    }

    const menuProps = {
        open: contextMenu !== null,
        onClose: handleClose,
        anchorReference: "anchorPosition",
        anchorPosition: 
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
    }

    return <div onContextMenu={handleContextMenu}>
        { node.type === "trash" && <hr/> } 
        <Node {...props} isContextMenuOpen={contextMenu !== null}/>    

        {   node.type === "trash" && // The node is the trash icon itself
                <Menu {...menuProps}>
                    <MenuItem onClick={() => handleMenuSelection("emptyTrash")}>Empty Trash</MenuItem>
                </Menu>
        }
        {   node.isTrash && node.type !== "trash" && // The node is a file inside the trash
                <Menu {...menuProps}>
                    <MenuItem onClick={() => handleMenuSelection("deletePermanently")}>Delete Permanently</MenuItem>
                </Menu>
        }       
        {
            !node.isTrash &&  // The node is not trash             
                <Menu {...menuProps}>
                    { !node.state && <MenuItem onClick={() => handleMenuSelection("rename")}>Rename</MenuItem> }
                    { !node.state && <MenuItem onClick={() => handleMenuSelection("moveToTrash")}>Move to Trash</MenuItem> }
                    { !node.state && <Divider/> }
                    <MenuItem onClick={() => handleMenuSelection("newFile")}>New File Here</MenuItem>
                    <MenuItem onClick={() => handleMenuSelection("newFolder")}>New Folder Here</MenuItem>
                    <MenuItem onClick={() => handleMenuSelection("uploadFile")}>Upload File Here</MenuItem>
                </Menu>
        }
            
    </div>

}