import { createObject } from "../../interpreter/api-helpers";
import $Global from './_global';

export const api = {
    globalObj: createObject($Global),
    classes: {
        $Global
    }
}

