import Split from 'split.js';
import './main.css';
import { getElementById } from "./dom-helpers";
import { handleEvent as fileExplorerHandlers } from './file-explorer/handlers';
import * as hostText from '../host-text/host-text';
import * as hostKonva from '../host-konva/host-konva';
import * as hostPhaser from '../host-phaser/host-phaser';
import * as hostHandlers from './handlers-host';
import { handleSubmitConfirm } from './handlers-ui';
import * as leftSidebar from './section-sidebar-left';
import * as rightSidebar from './section-sidebar-right';
import * as header from './section-header';
import * as uiHandlers from './handlers-ui';
import { getDocFileContent } from '../doc-gen/doc-gen';
import { getUsername } from './file-explorer/handlers';

export const config = {
    host: null,
    projectId: 0,
    testMode: false,
    curOpenFile: null,
    curTestYaml: null,
    debugEntries: [],
    curTree: null
}

/****** Event Listener Setup *******/
addEventListener("keydown", (ev) => {
    if (ev.key === "F5") {
        ev.preventDefault();
        uiHandlers.handleRunButton();
    }
    if (ev.key === "Escape") {
        uiHandlers.handleStopButton()
    }
    if (config.host && config.host.handleKeyPress)
        config.host.handleKeyPress(ev.key)
});

const handleTreeUpdate = newTree => {
    config.curTree = JSON.parse(JSON.stringify(newTree));
    config.host.handleTreeUpdate && config.host.handleTreeUpdate(newTree);
}

const initProject = ({ type, id }) => {
    config.projectId = id ? id : 0;

    if (type === "terminal") {
        getElementById("logo").innerHTML = "pNatural Terminal";
        getElementById("top-header").style = "background-color: #455e4b";
        config.host = hostText;
    }
    else if (type === "graphics") {
        getElementById("logo").innerHTML = "pNatural Graphics";
        getElementById("top-header").style = "background: linear-gradient(150deg, rgba(140,47,16,1) 0%, rgba(90,235,70,0.8323530095632002) 48%, rgba(9,13,213,0.6614846622242647) 100%);";
        config.host = hostKonva;
    }
    else if (type === "game") {
        getElementById("logo").innerHTML = "pNatural Game Engine";
        getElementById("top-header").style = "background: darkblue";
        config.host = hostPhaser;
    }
    else {
        alert("Not a valid pNatural Project");
        throw new Error("invalid project type");
    }

    Split(['#left-sidebar-container', '#center-container'], {
        sizes: [25, 75],
    })
    getElementById("submit-asst-button").addEventListener("click", handleSubmitConfirm);
    header.init();
    leftSidebar.init(handleTreeUpdate);
    rightSidebar.init();
    config.host.init({
        outputContainer: getElementById("output-container"), 
        terminationHandler: hostHandlers.handleProgramTermination,
        errorHandler: hostHandlers.handleError,
        debugHandler: hostHandlers.handleDebug,
    });
}

fileExplorerHandlers(
    "getProjectInfo", {},
    (req, res) => initProject(res),
    (err) => { 
        if (getUsername() === null)
            window.location = "/html/login.html";
        else if (getUsername() === "dcstewart")
            window.location = "/html/browser.html";
        else
            window.location = "/html/projectmanager.html";
    },
    () => console.log("getProjectInfo request timed out")
)

if (window.location.search.indexOf("doc-download") > -1) {
    const docObject = getDocFileContent();
    console.log(docObject);
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(docObject)));
    element.setAttribute('download', "doc-data.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}