import Konva from "konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import KonvaShape from "./konva-shape";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("host-konva.konva-api.line."+id, args);
}

export default class Line extends KonvaShape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Create a new _Line_ object, with the specified initial properties.
        @list points A list of points in the format [x1, y1, x2, y2, x3, y3, ...]
        @any color
    ` ]
    constructor(points=[0, 0, 100, 100], color="black") {
        super();
        this.$validatePointsArray(points, "line");
        const x = points[0].value;
        const y = points[1].value;
        points = points.map((point, i) => i%2===0 ? point.value-x : point.value-y);
        this.$createShape(Konva.Line, color, { stroke: color, strokeWidth: 2, points });
        this.$shape.x(x);
        this.$shape.y(y);
    }

    static $propdoc_points = "A list of points in the format [x1, y1, x2, y2, x3, y3, ...]"
    set points(pnList) { 
        const points = pnList.value.map(point => point.value);
        this.$shape.points(points);
    } 
    get points() { 
        return createBuiltInTypeObject(this.$shape.points());
        
    }

    static $propdoc_color = "The colour of the line."
    set color(value) { 
        const isColor = (strColor) => {
            const s = new Option().style;
            s.color = strColor;
            return s.color !== '';
        }

        let valid = value.$type === "string";
        valid = valid && isColor(value.value);
        !valid && reportError("invalid_color", { color: value.$toPrintable() })
        this.$konvaSetter('stroke', value) 
    } 
    get color() { return this.$konvaGetter('stroke') }

    static $propdoc_width = "The width of the line";
    set width(value) { this.$konvaSetter('strokeWidth', value, "number") } 
    get width() { return this.$konvaGetter('strokeWidth') }
}