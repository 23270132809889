import PnNumber from "../common-api/types/number";
import PnString from "../common-api/types/string";
import PnBoolean from "../common-api/types/boolean";
import PnList from "../common-api/types/list";
import PnObject from "../common-api/types/object";
export const NO_BLOCK = false;
export const BLOCKS = true;
export const RETURNS = true;
export const NO_RETURN = false;
export const OPTIONAL = "optional";

export const getMethodMeta = (obj, methodName) => getMethodMetaFromClassRef(obj.$jsClassRef, methodName);

export const getMethodMetaFromClassRef = (classRef, methodName) => {
	const raw = classRef["$_"+methodName];
	if (!raw) {
		if (methodName === "constructor")
			return null;
		throw new Error("Could not find meta data for "+methodName);
	}

	const [blocks, returns, str, passFullObjectForLiterals] = raw;

	const lines = str.split(/\r?\n|\r|\n/g);
	let desc = "";
	const params = [];
	let lastParamCanRepeat = false;
	lines.forEach(line => {
		const parseParam = line => {
			const tokens = line.split(/\s+/);
			if (tokens.length < 2)
				throw new Error("Invalid parameter declaration. Expected at least two tokens: "+line);
			if (!["@", "?", "*"].includes(tokens[0][0]))
				throw new Error("Parameter declaration doesn't start with @, ? or *: "+line);
			const optional = tokens[0][0] !== "@";
			lastParamCanRepeat = tokens[0][0] === "*";
			const type = tokens[0].substring(1);
			if (!["any", "number", "string", "boolean", "list", "object"].includes(type))
				throw new Error("Parameter declaration unknown type: "+line);
			const name = tokens[1];
			const desc = tokens.slice(2).reduce((str, token) => str+" "+token, "").trim();
			params.push({ name, type, desc, optional });
		}

		line = line.trim();
		if (["@", "?", "*"].includes(line[0]))
			parseParam(line);
		else
			desc += " "+line;
	});

	return {
		methodName,
		blocks, 
		desc: desc.trim(), 
		numParams: params.length,
		lastParamCanRepeat,
		params,
		returns,
		returnDesc: "Return description not yet implemented",
		passFullObjectForLiterals: !!passFullObjectForLiterals
	}
	
}

export const createObject = (cls=PnObject, params=[], pnClassHierarchy=[]) => {
	let result = new cls(...params);
	result.$jsClassRef = cls;
	result.$pnClassHierarchy = pnClassHierarchy;
	return result;
}

export const createBuiltInTypeObject = value => {
	//console.log(value);
	if (Array.isArray(value)) {
		const result = [];
		for (let i = 0; i < value.length; i++) {
			result.push(createBuiltInTypeObject(value[i]));
		}
		return createObject(PnList, [result]);
	}

	if (typeof value === "object") {
		if (value.$type) {
			return value;
			//throw new Error("creating built-in type of built-in type");
		}
		const result = createObject();
		for (let key in value) {
			result[key] = createBuiltInTypeObject(value[key]);
		}
		return result;
	}
		 

	const typeToClass = {
		number: PnNumber,
		boolean: PnBoolean,
		string: PnString
	}
	const cls = typeToClass[typeof value];
	if (!cls) throw new Error("Invalid built in type: "+(typeof value));
	return createObject(cls, [value]);
}

