import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import GameObject from "./game-object";
import { colorNameToHex } from "../util";

export default class Shape extends GameObject {

    constructor(fillColor) {
        super();

        this.$fillColor = createBuiltInTypeObject(fillColor);

        this.$addProp('color',
            (pnValue) => {
                this.$phaserObject.fillColor = colorNameToHex(pnValue.value) 
                this.$fillColor = pnValue;
            },
            () => this.$fillColor
        );
        
    }

    static $propdoc_color = "The colour of the shape.";

}