import objectScan from 'object-scan';
import { setCurLocation } from "../../interpreter.js";
import { api as commonApi } from "../../../common-api/common-api";
import { getMethodMeta } from "../../api-helpers";
import PnError from "../../pn-error";
import { fetchFile } from '../../../ui/handlers-host.js';
import { parse } from "../../parser/parser";
const reportError = (id, args, location) => { 
    throw new PnError("interpreter.execution.model.structure."+id, args, location);
}

let structure;



export const init = async (ast, hostApi, curTree) => {
    const addStatements = (statementDest, fnDefDest, destType, stmts) => {
        const classes = structure.classes;
        for (let stmt of stmts) {
            setCurLocation(stmt);
            if (stmt.type === "class_definition") {
                destType !== "top_level" && reportError("misplaced_class_def"); 
                const className = stmt.name.value;
                className in classes && reportError("class_defined_multiple_times", { name: className });
                classes[className] = { 
                    superclassName: stmt.superclass === null ? null : stmt.superclass.value,
                    name: className,
                    defs: {}
                }
                addStatements(null, classes[className].defs, "class", stmt.body);
            }
            else if (stmt.type === "function_definition") {
                destType === "def" && reportError("nested_functions"); 
                const fnName = stmt.name.value;
                fnDefDest.hasOwnProperty(fnName) && reportError("function_defined_multiple_times", { name: fnName });
                fnDefDest[fnName] = { 
                    params: stmt.parameters.map(id => id.value),
                    stmts: []
                };
                const duplicateParams = findDuplicates(fnDefDest[fnName].params);
                duplicateParams.length > 0 && reportError("duplicate_params", { fnName, pName: duplicateParams[0] });
                addStatements(fnDefDest[fnName].stmts, null, "def", stmt.body.statements);
            }
            else if (stmt.type === "import_statement") {
                reportError("misplaced_import_statement")
            }
            else {
                destType === "class" && reportError("executable_stmt_in_class_top_level");
                statementDest.push(stmt);
            }
        }
    }
    
    const calculateSuperclassRefs = () => {
        const classes = structure.classes;
        for (let className in classes) {
            const classRef = classes[className];
            const superName = classRef.superclassName;

            if (superName === null) {
                classRef.superType = "none";
                continue;
            }

            // Check for a user defined class
            if (classes[superName]) {
                classRef.superType = "pn-class";
                classRef.superRef = classes[superName];
                continue;
            }

            // Check for a hostApi defined class
            if (hostApi.classes[superName]) {
                classRef.superType = "js-class";
                classRef.superRef = hostApi.classes[superName];
                continue;
            }

            // Check for a commonApi defined class
            if (commonApi.classes[superName]) {
                classRef.superType = "js-class";
                classRef.superRef = commonApi.classes[superName];
                continue;
            }

            reportError("superclass_not_found", { parent: superName});
        }
    }
    
    structure = { 
        topLevelStatements: [],
        topLevelDefs: {},
        classes: {},
        hostApi
    }

    for (let className in structure.hostApi.classes) {
        structure.hostApi.classes[className].$className = className;
    }

    if (hostApi.entryPoint)
        ast = await getFullProjectAst(curTree, hostApi.entryPoint);
    if (window.location.search.indexOf("show-json") > -1)
        console.log(JSON.stringify(ast));

    /****** PRELIMINARY CODE FOR PROCESSING IMPORTS
    console.log(curTree);    
    // This will be used for looking up imports
    const getSearchTree = entries => {
        const result = {}
        for (let entry of entries) {
            if (entry.children)
                result[entry.name] = getSearchTree(entry.children);
            else
                result[entry.name] = {};
        }
        return result;
    }
    const searchTree = getSearchTree(curTree.children);
    console.log(searchTree);
    ast = await processImports(ast, searchTree);
    */

    addStatements(structure.topLevelStatements, structure.topLevelDefs, "top_level", ast);   
    calculateSuperclassRefs();
    console.log(structure);
}

const getFullProjectAst = async (curTree, entryPoint) => {
    let ast = [];

    const addFilenameToAst = (node, filename) => {
        if (!node || typeof(node) !== 'object')
            return; // nothing to do with a leaf

        if (Array.isArray(node)) {
            for (const child of node) {
                addFilenameToAst(child, filename);
            }
        }

        else { // we're an object

            for (const key in node) {
                if (key === 'start' || key === 'end') {
                    if (node[key] === undefined)
                        node[key] = {};
                    node[key].filename = filename;
                }

                addFilenameToAst(node[key], filename);
            }
        }
    }

    const buildAst = async (subDirStr, entries) => {
        for (let entry of entries) {
            if (entry.children) {
                await buildAst(subDirStr+"/"+entry.name, entry.children);
                continue;
            }
            const suffix = entry.name.split('.').pop();
            if (suffix !== "pn")
                continue;
            const pathFn = subDirStr+"/"+entry.name;
            const code = await fetchFile(pathFn);
            const fileAst = parse(code, pathFn);
            if (pathFn !== entryPoint) {
                for (let stmt of fileAst) {
                    !["class_definition", "function_definition"].includes(stmt.type) && reportError("top_level_code_in_import", {}, { filename: pathFn });
                }
            }
            addFilenameToAst(fileAst, pathFn);
            ast = ast.concat(fileAst);
        }
    }
    await buildAst("", curTree.children);
    return ast;
}

// const processImports = async (ast, searchTree) => {
//     const processImport = async (path) => {
//         try {
//             const ast2 = parse(await fetchFile(path));
//             return processImports(ast2);
//         } catch (err) {
//             reportError("import_not_found");
//         }
//     }

//     const findMatches = pattern => {
//         let osStr = pattern.replaceAll('.', '\\.');
//         console.log(osStr);
//         console.log(objectScan([osStr], { joined: true })(searchTree));
//     }

//     while (ast.length > 0 && ast[0].type === "import_statement") {
//         const files = findMatches(ast[0].path.value);
//         const importAst = await processImport(ast[0].path.value);
//         ast = ast.concat(importAst);
//         ast.shift();
//     }
    
//     return ast;
// }

export const getFullStructure = () => structure;
export const getTopLevelStatements = () => structure.topLevelStatements;
export const getTopLevelDefs = () => structure.topLevelDefs;
export const getClasses = () => structure.classes;
export const getTopLevelDef = name => structure.topLevelDefs[name];
export const getApiFunction = name => {
    // First check for a definition in the host api
    if (name in structure.hostApi.globalObj.__proto__){
        return { 
            ref: structure.hostApi.globalObj[name],
            meta: getMethodMeta(structure.hostApi.globalObj, name)
        };
    }

    // Next check for a definition in the common api
    if (name in commonApi.globalObj.__proto__) {
        return { 
            ref: commonApi.globalObj[name],
            meta: getMethodMeta(commonApi.globalObj, name)
        };
    }
}

export const getApiClass = name => {
    // First check for a definition in the host api
    if (name in structure.hostApi.classes) 
        return structure.hostApi.classes[name];        

    // Next check for a definition in the common api
    if (name in commonApi.classes) 
        return commonApi.classes[name]
}

export const getPnClassDef = (className, defName) => {
    const classRef = getClasses()[className];
    if (!classRef) throw new Error("Class name not found: "+className);
    return classRef.defs[defName];
}

export const getAllStatements = () => {
    let stmts = structure.topLevelStatements;
    for (let name in structure.topLevelDefs) { 
        stmts = stmts.concat(structure.topLevelDefs[name].stmts);
    }
    for (let className in structure.classes) {
        const curClass = structure.classes[className];
        for (let fnName in curClass.defs) {
            stmts = stmts.concat(curClass.defs[fnName].stmts);
        }
    }
    const matches = objectScan(['**.type'], { filterFn: ({ value }) => value === "code_block" })(stmts);
    for (let match of matches) {
        if (match.length < 2) {
            console.log("Why are we here?");
            continue;
        }
        let cur = stmts;
        for (let i = 0; i < match.length-1; i++) {
            cur = cur[match[i]];
        }
        stmts = stmts.concat(cur.statements);
    }


    return stmts;
}

// Helper function to find duplicates in an array
const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] == sorted_arr[i]) {
            results.push(sorted_arr[i]);
        }
    }
    return results;
}