// Difficulty: "Moderate"
// Python language definition.
// Only trickiness is that we need to check strings before identifiers
// since they have letter prefixes. We also treat ':' as an @open bracket
// in order to get auto identation.
export const monarch = {
	defaultToken: '',
	tokenPostfix: '.python',

	keywords: [
        'repeat',
        'until',
        'forever',
		'for',
		'in',
        'class',
        'extends',
		'import',

		'and',
		'break',
		'continue',
		'def',
		'elif',
		'else',
		'if',
		'or',
		'return',
		'not',

		'True',
		'False',
	],

	brackets: [
		{ open: '{', close: '}', token: 'delimiter.curly' },
		{ open: '[', close: ']', token: 'delimiter.bracket' },
		{ open: '(', close: ')', token: 'delimiter.parenthesis' }
	],

	tokenizer: {
		root: [
			{ include: '@whitespace' },
			{ include: '@numbers' },
			{ include: '@strings' },

			[/[,:;]/, 'delimiter'],
			[/[{}[\]()]/, '@brackets'],

			[/@[a-zA-Z]\w*/, 'tag'],
			[/[a-zA-Z]\w*/, {
				cases: {
					'@keywords': 'keyword',
					'@default': 'identifier'
				}
			}]
		],

		// Deal with white space, including single and multi-line comments
		whitespace: [
			[/\s+/, 'white'],
			[/(^#.*$)/, 'comment'],
			[/('''.*''')|(""".*""")/, 'string'],
			// [/(===.*===)/, 'string'],
			[/'''.*$/, 'string', '@endDocString'],
			[/""".*$/, 'string', '@endDblDocString']
		],
		endDocString: [
			[/\\'/, 'string'],
			[/.*'''/, 'string', '@popall'],
			[/.*$/, 'string']
		],
		endDblDocString: [
			[/\\"/, 'string'],
			[/.*"""/, 'string', '@popall'],
			[/.*$/, 'string']
		],

		// Recognize hex, negatives, decimals, imaginaries, longs, and scientific notation
		numbers: [
			[/-?0x([abcdef]|[ABCDEF]|\d)+[lL]?/, 'number.hex'],
			[/-?(\d*\.)?\d+([eE][+-]?\d+)?[jJ]?[lL]?/, 'number']
		],

		// Recognize strings, including those broken across lines with \ (but not without)
		strings: [
			// [/'$/, 'string.escape', '@popall'],
			// [/'/, 'string.escape', '@stringBody'],
			//[/"$/, 'string.escape', '@popall'],
			[/"/, 'string.escape', '@dblStringBody']
		],
		// stringBody: [
		// 	[/[^\\']+$/, 'string', '@popall'],
		// 	[/[^\\']+/, 'string'],
		// 	[/\\./, 'string'],
		// 	[/'/, 'string.escape', '@popall'],
		// 	[/\\$/, 'string']
		// ],
		dblStringBody: [
            [/\{/, { token: 'number', next: '@varSub' }],
			//[/[^\\"]+$/, 'string', '@popall'],
			[/[^"\{]+/, 'string'],
			// [/\\./, 'string'],
			[/"/, 'string.escape', '@popall'],
			// [/\\$/, 'string']


            // [/\{/, { token: 'delimiter.bracket', next: '@bracketCounting' }],
			// [/[^\\"]+$/, 'string', '@popall'],
			// [/[^\\"]+/, 'string'],
			// [/\\./, 'string'],
			// [/"/, 'string.escape', '@popall'],
			// [/\\$/, 'string']
		],

        varSub: [
			[/[^\}]+/, 'delimiter.bracket'],
			[/\}/, 'number', '@pop'],
			//[/[^\}]+/, 'identifier']
		],
	}
};