import objectScan from 'object-scan';
import { parse } from "./parser/parser.js";
import * as exec from "./execution/execution.js"
import PnError from "./pn-error.js";
import * as commonApi from "../common-api/common-api";
import * as structure from "./execution/model/structure.js";
import { createBuiltInTypeObject, createObject } from './api-helpers.js';

export let curLocation = { filename: "", line: "" };
export let setCurLocation = (astNode) => {
    curLocation.filename = astNode.start && astNode.start.filename ? astNode.start.filename : "";
    curLocation.line = astNode.start && astNode.start.line ? astNode.start.line : "";
}
export let config;

export const init = (cfg) => {
    config = cfg;
    commonApi.init(config.debugHandler);
}

export const run = (code, curTree, globalVars={}) => { 
    try {
        globalVars.math = createObject(commonApi.api.classes.PnMath);
        let ast = [];
        if (!("entryPoint" in config.hostApi))
            ast = parse(code);
        exec.run(ast, config.hostApi, handleError, config.terminationHandler, globalVars, curTree);
    } catch (err) {
        handleError(err);
    }
}

export const getSymbol = name => {
    const symbol = exec.getSymbol(name);
    if (symbol && symbol.$toDebugDisplay)
        return symbol.$toDebugDisplay();
    return null;
}
export const setSymbol = (name, value) => exec.setSymbol(name, createBuiltInTypeObject(value));

const objToStringList = (obj) => {
    const strings = [];
    const process = (path, obj) => {
        if (Array.isArray(obj)) {
            for (let i = 0; i < obj.length; i++) {
                if (typeof(obj[i]) === "object") {
                    process(path+"["+i+"]", obj[i]);
                }
                else {
                    strings.push(path+"["+i+"]="+obj[i]);
                }
            }
        }
        else {
            for (let key in obj) {
                if (typeof(obj[key]) === "object") {
                    process(path+"."+key, obj[key]);
                }
                else {
                    strings.push(path+"."+key+"="+obj[key]);
                }
            }
        }
    }

    process("root", obj);
    return strings;
}

const isSubObject = (full, sub) => {
    const fullStrings = objToStringList(full);
    const subStrings = objToStringList(sub);
    // console.log(fullStrings);
    // console.log(subStrings);
    for (let str of subStrings) {
        if (!fullStrings.includes(str)) {
            console.log(str);
            return false;
        }
            
    }
    return true;
}

export const runStructureTests = async (code, tests) => {
    
    try {
        const ast = parse(code);
        const results = await exec.runStructureTests(ast, config.hostApi, tests.structure);
        if (tests.jsScript) {
            const fn = new Function('structure', 'allStatements', 'objectScan', 'isSubObject', tests.jsScript);
            const jsResults = fn(structure.getFullStructure(), structure.getAllStatements(), objectScan, isSubObject);
            if (!Array.isArray(jsResults)) 
                throw new Error("Results not array");
            for (let result of jsResults) {
                if (typeof result !== "object") 
                    throw new Error("Result not an object: "+result);
                if (typeof result.name !== "string")
                    throw new Error("Bad result name property: "+ result.name);
                if (typeof result.result !== "boolean")
                    throw new Error("Bad result result property: "+ result.result);
                results.push(result);
            }
        }
        return results; 
        
    } catch (err) {
        handleError(err);
    }
    
}

export const forceStop = () => { 
    exec.stop();
}

export const resolve = (value) => exec.resolve(value);

const handleError = (err) => {
    if (err.isFailedRuntimeTest) 
        return; 
    if (err instanceof PnError) 
        config.errorHandler(err);
    else
        throw err;
}