import Phaser from 'phaser';
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import Shape from './shape';
import phaser from "../phaser";
import { colorNameToHex } from '../util';

export default class Rectangle extends Shape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `desc 
        @number x
        @number y
        @number width
        @number height
        @string color
    `]
    constructor(x=0, y=0, width=50, height=50, color="black") {
        super(color);
        this.$phaserObject = new Phaser.GameObjects.Rectangle(phaser.getScene(), x, y, width, height, colorNameToHex(color));
        this.$phaserObject.setOrigin(0, 0);
    }

}