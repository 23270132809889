import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import BaseType from "./base-type";


export default class PnNumber extends BaseType {
    constructor(value) {
        super();
        this.value = value;
        this.$type = "number";
        this.$typeCategory = "literal";
    }

    $toPrintable() { return Math.round(this.value*100000000)/100000000 }
    $toDebugDisplay() { return Math.round(this.value*100000000)/100000000 }
    $toJsLiteral() { return this.value }

    $validateOperation(op, rightSide) {
        const opType = this.$getOpType(op);
        !(opType === "arithmetic" || opType === "comparison") && this.$reportIncompatibleTypes(op, rightSide);
        !(typeof rightSide.value === "number") && opType === "arithmetic" && this.$reportIncompatibleTypes(op, rightSide);
    }

    static $_isnumeric = [NO_BLOCK, RETURNS,
    `Returns _True_ - (remove from docs)` ]
    isnumeric()  { return true; }

}