import React, { useState, useEffect } from "react";
import { Inspector, chromeLight } from 'react-inspector';
import "./debug-console.css";


export const DebugConsole = (props) => {
    const [entries, setEntries] = useState([]);

    useEffect(() => {
        props.setEntrySetter(setEntries);
    }, [props])

    return <div style={{padding: 5}}>
        <table id="debug-console-table"><tbody>
            { entries.map((entry, i) => <DebugEntry key={i} entry={entry} />) }
        </tbody></table>
    </div>
}

const DebugEntry = (props) => {
    const {name, data, location} = props.entry;
    const iProps = { data, theme:{...chromeLight, BASE_FONT_SIZE: 14, TREENODE_FONT_SIZE: 14 } };
    if (name !== "")
        iProps.name = name;
    return <tr>
        <td className="debug-console-entry"><Inspector {...iProps} /></td>
        <td className="debug-console-location">
            {location}
        </td>
    </tr>
}