import Phaser from 'phaser';
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import GameObject from './game-object';
import phaser from "../phaser";
import { colorNameToHex } from '../util';

export default class Text extends GameObject {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Creates a new Text object at the given coordinates. 
        @number x
        @number y
        @string text The message that the Text object should display.
    `]
    constructor(x=0, y=0, content="") {
        super();

        this.$style = {};
        this.$addStyleProp("color", "black");
        this.$addStyleProp("fontFamily", "Courier");
        this.$addStyleProp("fontSize", "16pt");
        this.$addStyleProp("backgroundColor", "");
        this.$addStyleProp("fontStyle", "");
        this.$phaserObject = new Phaser.GameObjects.Text(phaser.getScene(), x, y, content, this.$style);
        this.$phaserObject.setOrigin(0, 0);
        this.$addDirectAccessors(['text']);
    }

    static $propdoc_color = "The colour of the text.";
    static $propdoc_fontFamily = "The font of the text.";
    static $propdoc_fontSize = "The size of the text.";
    static $propdoc_backgroundColor = "The background colour of the text. Produces the effect of the text being highlighted.";
    static $propdoc_fontStyle = "The style of the text. Valid values are *bold*, *italic*, *bold italic* and *normal*.";
    static $propdoc_text = "The message that the Text object should display.";
    $addStyleProp(name, defaultValue) {
        this.$style[name] = defaultValue;
        this.$addProp(name, 
            pnValue => {
                this.$style[name] = pnValue.value;
                this.$phaserObject.setStyle(this.$style);
            },
            () => createBuiltInTypeObject(this.$style[name])
        )
    }

}