import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import BaseType from "./base-type";


export default class PnObject extends BaseType {
    constructor(initialProps={}) {
        super();
        this.$type = "object";
        this.$typeCategory = "object";
        //this.value = this;
        for (let key in initialProps) {
            this[key] = initialProps[key];
        }
    }

    //get value() { return this.$toDebugDisplay() }

    $toPrintable() { return "[Object]" }
    $toDebugDisplay(reflist=[]) { 
        if (reflist.includes(this)) {
            //console.log(reflist);
            return "[Circular Reference]";
        }
            
        reflist.push(this);
        const result = {};

        const addProp = prop => {
            if (this[prop] === this) {
                result[prop] = "[Self Reference]";
            }
            else if (prop[0] !== "$" && (typeof this[prop] !== "function")) {
                // console.log(prop);
                // console.log(this[prop])
                result[prop] = this[prop].$toDebugDisplay(reflist);
            }
        }

        for (let prop in Object.getOwnPropertyDescriptors(this)) {
            // if (prop === "value")
            //     continue;
            addProp(prop);
        }

        // Also add getters
        let cls = this.$jsClassRef;
        while (cls.prototype) {
            const protoProps = Object.getOwnPropertyDescriptors(cls.prototype)
            for (let prop in protoProps) {
                if (typeof protoProps[prop].get === "function") {
                    addProp(prop);
                }
            }
            cls = cls.__proto__;
        }

        return result;
    }
    $toJsLiteral() { return null }

    $validateOperation(op, rightSide) {
        this.$reportIncompatibleTypes(op, rightSide);
    }

//     get value() {
//         return this.$toDebugDisplay();
//     }
}