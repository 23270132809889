import PnError from "../pn-error";

export const preprocess = (code, filename) => {
    code = code.replace(/\r/g, "");
    code = code+"\n";
    let lineNum = 1;
    let output = "";
    const bracketStack = [];
    let indentLevel = 0;
    let leadingSpacesSeen = 0;
    let inputIndex = 0;

    // Possible states:
    const PRE_STATEMENT = "preStatement";
    const NORMAL = "normal";
    const STRING = "string";
    const COMMENT = "comment";
    const NEW_CODEBLOCK_READY = "new_codeblock_ready";
    let state = PRE_STATEMENT;   

    const reportError = (id, args) => { 
        throw new PnError("interpreter.parser.preprocessor."+id, args, { filename, line: lineNum });
    }

    const processPreStatementState = char => {
        const handleUnindent = levels => {
            let lastNewLine = output.lastIndexOf("\n");
            if (lastNewLine < -1) 
                throw new Error("Could not find previous newline");
            const first = output.substring(0, lastNewLine);
            const last = output.substring(lastNewLine);
            output = first;
            for (let i = 0; i < levels; i++)
                output += "}";
            output += last;
            indentLevel -= levels;
        }

        if (char === " ") {
            leadingSpacesSeen++;
            output += char;
        }
        else if (char === "#") {
            leadingSpacesSeen = 0;
            state = COMMENT;
        }
        else if (char === "\n") { // a fully blank line
            output += char;
            leadingSpacesSeen = 0;
            lineNum++;
        }
        else {
            if (leadingSpacesSeen % 4 !== 0) {
                reportError("leading_spaces_not_multiple_of_4", {});
            }
            else if (leadingSpacesSeen/4 < indentLevel) {
                handleUnindent(indentLevel - leadingSpacesSeen/4);
            }

            else if (leadingSpacesSeen / 4 !== indentLevel) {
                reportError("unexpected_indentation_level", {});
            }
            inputIndex--; // requeue this character
            state = NORMAL;
            leadingSpacesSeen = 0;
        }
    }

    const processNormalState = char => {
        let surpressOutput = false;

        if (char === "#") {
            state = COMMENT;
            surpressOutput = true;
        }

        if (char === "\"") {
            state = STRING;
        }

        if ("([{".includes(char)) {
            bracketStack.push(char);
        }

        if (")]}".includes(char)) {
            if (bracketStack.length === 0)
                reportError("no_open_bracket", { char });
            const open = bracketStack.pop();
            let expected;
            if (open === "(") expected = ")";
            if (open === "[") expected = "]";
            if (open === "{") expected = "}";
            if (char !== expected) {
                reportError("unmatched_brackets", { expected, found: char });
            }   
        }

        if (char === "\n") {
            lineNum++;
            if (bracketStack.length === 0)
                state = PRE_STATEMENT;
        }

        if (char === ":") {
            state = NEW_CODEBLOCK_READY; 
        }

        if (!surpressOutput)
            output += char;
    }

    const processStringState = char => {
        if (char === "\n") {
            lineNum++;
        }

        if (char === "\"") {
            state = NORMAL;
        }

        output += char;
    }

    const processCommentState = char => {
        if (char === "\n") {
            lineNum++;
            output += char;
            state = PRE_STATEMENT;
        }
    }

    const processNewCodebockReadyState = char => {
        if (char === "\n") {
            lineNum++;
            indentLevel++;
            output += "{" + char;
            state = PRE_STATEMENT;
        }
        else if (char === " ") {
            output += char;
        }
        else if (char === "#") {
            state = COMMENT;
            indentLevel++;
            output += "{";
        }
        else {
            inputIndex--; // requeue this character
            state = NORMAL;
        }
    }

    const stateFunctions = {
        [PRE_STATEMENT]: processPreStatementState,
        [NORMAL]: processNormalState,
        [STRING]: processStringState,
        [COMMENT]: processCommentState,
        [NEW_CODEBLOCK_READY]: processNewCodebockReadyState
    }

    // Iterate through each character in the input
    while (inputIndex < code.length) {
        //console.log({ char: code.charAt(inputIndex), lineNum, state })
        stateFunctions[state](code.charAt(inputIndex));
        inputIndex++;
    }
    for (let i = 0; i < indentLevel; i++) 
        output += "}";

    lineNum = "unknown";

    if (state === STRING) 
        reportError("eof_unterminated_string", {});
        
    if (bracketStack.length > 0) 
        reportError("eof_unmatched_bracket", { type: bracketStack[bracketStack.length-1]});

    return output;
}