import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../interpreter/api-helpers";
import PnObject from "../common-api/types/object";

export default class PnMath extends PnObject {
    static $doc_title = "Math Module";
    static $doc_description = `Functions in this class should be prefixed by \`math.\` For example,
    
    mySquareRoot = math.sqrt(number)`;

    static $_ceil = [NO_BLOCK, RETURNS,
    `Rounds the given number up to the nearest integer, and returns the result.
        @number number `]
    ceil(num) {  return Math.ceil(num);   }


    static $_floor = [NO_BLOCK, RETURNS,
    `Rounds the given number down to the nearest integer, and returns the result.
        @number number `]
    floor(num) {  return Math.floor(num);   }


    static $_isfinite = [NO_BLOCK, RETURNS,
    `Returns _True_ if the given number is neither an infinity nor a NaN, and _False_ otherwise. 
        @number number `]
    isfinite(num) {  return isFinite(num);   }


    static $_isinf = [NO_BLOCK, RETURNS,
    `Returns _True_ if the given number is a positive or negative infinity, and _False_ otherwise.
        @number number `]
    isinf(num) {  return !isFinite(num);   }


    static $_isnan = [NO_BLOCK, RETURNS,
    `Returns _True_ if the given number is a NaN (not a number), and _False_ otherwise.
        @number number `]
    isnan(num) {  return isNaN(num);   }

    
    static $_sqrt = [NO_BLOCK, RETURNS,
    `Returns the square root of the given number.
        @number number `]
    sqrt(num) {  return Math.sqrt(num);   }
    

    static $_sin = [NO_BLOCK, RETURNS,
    `Returns the sin of the given angle in degrees.
        @number angle `]
    sin(angle) {  return Math.sin(angle*Math.PI/180);   }


    static $_cos = [NO_BLOCK, RETURNS,
    `Returns the cos of the given angle in degrees.
        @number angle `]
    cos(angle) {  return Math.cos(angle*Math.PI/180);   }


    static $_tan = [NO_BLOCK, RETURNS,
    `Returns the tan of the given angle in degrees.
        @number angle `]
    tan(angle) {  return Math.tan(angle*Math.PI/180);   }
    

    static $_asin = [NO_BLOCK, RETURNS,
    `Returns the arc sine (ie, the inverse sine) in degrees of the given number. The returned angle will be between -90 and 90 degrees.
        @number number `]
    asin(number) {  return Math.asin(number)*180/Math.PI;  }


    static $_acos = [NO_BLOCK, RETURNS,
    `Returns the arc cosine (ie, the inverse cosine) in degrees of the given number. The returned angle will be between 0 and 180 degrees.
        @number number `]
    acos(number) {  return Math.acos(number)*180/Math.PI;  }


    static $_atan = [NO_BLOCK, RETURNS,
    `Returns the arc tangent (ie, the inverse tangent) in degrees of the given number. The returned angle will be between -90 and 90 degrees.
        @number number `]
    atan(number) {  return Math.atan(number)*180/Math.PI;   }
}