import React, { useState, useEffect, useRef } from "react";
import { FcDocument, FcFullTrash, FcNext, FcExpand, FcCheckmark, FcSelfServiceKiosk } from "react-icons/fc";
import { useDrag, useDrop } from 'react-dnd'
import { curEventState, PATH_TO_ROOT, PATH_TO_TRASH } from './util';

const ItemTypes = { NODE: 'node' }

let dragNode = null;
let isOverNode = null;

export const Node = (props) => {
    let { node, isContextMenuOpen, activeNode, setActiveNode, uiState } = props;

    let isDraggable = true;
    let isDroppable = true;

    // Don't allow any dragging when in the naming state
    if (uiState === "naming")
        isDraggable = false; 

    // Controlled files cannot be dragged
    if (node.type === "file" && node.state)
        isDraggable = false;

    // The root and trash cannot be dragged
    if (node.fullPath === PATH_TO_ROOT || node.fullPath === PATH_TO_TRASH) {
        isDraggable = false;
    }

    // Items in the trash cannot be dragged or dropped
    if (node.fullPath.indexOf(PATH_TO_TRASH) === 0 && node.fullPath !== PATH_TO_TRASH) {
        isDraggable = false;
        isDroppable = false;
    }

    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.NODE,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))

    const [{ isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.NODE,
        drop: () => {
            if (isOverNode.fullPath === PATH_TO_TRASH) 
                props.eventDispatcher("moveToTrash", "initialRequest", { path: dragNode.fullPath });
            else
                props.eventDispatcher("moveNode", "initialRequest", { 
                    dragPathFn: dragNode.fullPath, 
                    dropPathFn: isOverNode.fullPath 
                });
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    }), [props])
    
    if (isDragging) dragNode = node;
    if (isOver) isOverNode = node;

    const handleClick = () => {
        setActiveNode(node.fullPath);
        props.eventDispatcher("click", "initialRequest", { path: node.fullPath });
    }

    const handleNameConfirm = (newName) => {
        props.eventDispatcher(null, "userConfirm", { newName });
    }

    const handleNameCancel = () => {
        props.eventDispatcher(null, "userCancel");
    }

    let icon;
    const iconClass = "file-explorer-icon";
    if (node.type === "file") {
        icon = <FcDocument className={iconClass} />
        if (node.state === "submitted")
            icon = <><FcDocument className={iconClass} /><FcCheckmark className={iconClass} /></>;
    }
    else if (node.type === "folder" && node.isExpanded)
        icon = <FcExpand className={iconClass}/>
    else if (node.type === "folder" && !node.isExpanded)
        icon = <FcNext className={iconClass}/>
    else if (node.type === "trash" && node.isExpanded)
        icon = <><FcExpand className={iconClass}/><FcFullTrash className={iconClass} /></>
    else if (node.type === "trash" && !node.isExpanded)
        icon = <><FcNext className={iconClass}/><FcFullTrash className={iconClass} /></>
    else
        throw new Error("Invalid node type");

    let classes = "file-explorer-node";
    if (isContextMenuOpen)
        classes += " context-open-node";
    if (activeNode === node.fullPath)
        classes += " active-node"
    if (isOver) 
        classes += " dragging-over";
    if (node.fullPath === PATH_TO_ROOT)
        classes += " file-explorer-root-node";
    if (node.state === "unsubmitted")
        classes += " unsubmitted-node";
    if (node.state === "submitted")
        classes += " submitted-node";

    let ref = null;
    if (isDraggable && !isDroppable)
        ref = (node) => drag(node);
    if (isDroppable && !isDraggable)
        ref = (node) => drop(node);
    if (isDraggable && isDroppable)
        ref = (node) => drag(drop(node));

    return <div
            ref={ref}
            className={classes}
            style={{ paddingLeft: node.level * 15 + 3 }}
            onClick={() => !isContextMenuOpen && handleClick()}
        >
        {icon}
        {   uiState === "naming" && curEventState.namingNodePath === node.fullPath ?
            <TextInput onConfirm={handleNameConfirm} onCancel={handleNameCancel} name={node.name}/>
            :
            <span className="file-explorer-filename">{node.name}</span>
        }
    </div>
    
}

const TextInput = (props) => {
    let [newName, setNewName] = useState(props.name);
    const ref = useRef(null);
    const { onConfirm, onCancel } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onConfirm && onConfirm(ref.current.value);
            }
        };
        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                onConfirm && onConfirm(ref.current.value);
            }
            if (event.key === "Escape") {
                onCancel && onCancel();
            }
        }

        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onConfirm, onCancel]);

    return (
        <input 
                autoFocus 
                ref={ref}
                value={newName} 
                onChange={(event) => setNewName(event.target.value)}
                onFocus={(event) => event.target.select()}
            >    
        </input>
    )
}
