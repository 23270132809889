//import * as structure from "./execution/model/structure.js";
import * as interpreter from "./interpreter";
import { errors } from "../error-messages/error-messages";

export default class PnError extends Error {
    constructor(errorId, args, location=null) {
        super();
        console.log(errorId)
        // console.log(errors);
        let template = errors[errorId];
        if (template === undefined) {
            template = `<pre>NO ERROR MESSAGE FOUND.
            id: ${errorId.substring(errorId.lastIndexOf('.')+1)}
            
            parameters:
            `;
            template = template.replace(/\n\s*/g, '\n');
            for (let key in args) {
                template += `\t${key} = ${args[key]}\n`
            }
            template += "</pre>";
        }

        // Replace all variables in the error message with their values
        for (let key in args) {
            const replacer = new RegExp("\\$"+key, 'g')
            template = template.replace(replacer, args[key]);
        }

        this.errorId = errorId;
        this.message = template;
        this.filename = location && location.filename   ? location.filename  : interpreter.curLocation.filename;
        this.line =     location && location.line       ? location.line      : interpreter.curLocation.line;
    }

}