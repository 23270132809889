import * as yaml from 'js-yaml';
import * as monaco from 'monaco-editor';
import { getElementById, createElement, setHTMLById, showWaitingDialog, hideWaitingDialog } from "./dom-helpers";
import { config } from "./index";
import { monacoEditor, centerContainer, imageContainer, monacoContainer } from "./section-content";
import { setDebugEntries } from './section-sidebar-right';
import { openTab, TESTS_TAB } from './status-pane/tabs';
import { handleEvent as fileExplorerHandlers } from './file-explorer/handlers';
import { removeProjectRootFromFilePath } from './util';
import { fileExplorerRefresh } from './section-sidebar-left';
import { addHeaderButton } from './section-header';
import * as testResults from './status-pane/test-results';

export const handleRunButton = (testonly=false) => {
    if (getElementById("run-button").disabled) // could happen if F5 hot-key is used
        return;

    save();
    if (monacoEditor === undefined)
        return;
    
    config.testMode = testonly;

    setHTMLById("error-tab", "");
    setHTMLById("tests-tab", "");

    if (!testonly) {
        getElementById("run-button").disabled = true;
        getElementById("stop-button").disabled = false;
        config.host.run(monacoEditor.getValue(), config.curTree);
        config.debugEntries = [];
        setDebugEntries(config.debugEntries);
    }
    else {
        openTab(TESTS_TAB);
        showWaitingDialog(`Running tests. This may take some time if your program takes awhile to run, for instance 
        if you have some long pauses. The tests may not complete if your program goes into an infinite loop. Please
        refresh your browser if you'd like to cancel the tests.`);
        let tests = yaml.load(config.curTestYaml);
        if (!tests)
            tests = {};
        tests.jsScript = config.curTestJs;
        config.host.runTests(monacoEditor.getValue(), tests, handleTestResults);
    }
}

export const handleStopButton = () => {
    if (getElementById("stop-button").disabled) // could happen if ESC hot-key is used
        return;
    config.host.forceStop();
}

export const handleSubmitButton = () => {
    console.log(testResults.getLatestResults());
    console.log(config.curTestYaml)
    const hasTests = config.curTestYaml !== null || config.curTestJs !== null;
    if (testResults.getLatestResults() === null && hasTests) {
        getElementById("test-before-submit-dialog").showModal();
    }
    else {
        setHTMLById("submission-filename", removeProjectRootFromFilePath(config.curOpenFile));
        getElementById("submission-dialog-test-results-heading").hidden = config.curTestYaml === null;
        getElementById("submission-dialog").showModal();
    }
}

export const handleSubmitConfirm = () => {
    const submitData = {
        code: monacoEditor.getValue(),
        test_results: JSON.stringify(testResults.getLatestResults()),
        student_message: getElementById("submission-message").value,
        filename: removeProjectRootFromFilePath(config.curOpenFile),
        project_id: config.projectId
    }
    showWaitingDialog("Submitting...");
 
    // Tap into the file explorer handlers to submit the file to the server
    fileExplorerHandlers(
        "submit",
        submitData,
        () => {
            hideWaitingDialog();
            fileExplorerRefresh(removeProjectRootFromFilePath(config.curOpenFile), "submitted");
        },
        (err) => console.log("Submit failed with error: "+err),
        () => console.log("Submit request timed out")
    )

}

const handleUnsubmit = () => {
    const data = {
        filename: removeProjectRootFromFilePath(config.curOpenFile),
        project_id: config.projectId
    }

    showWaitingDialog("Unsubmitting...");
    fileExplorerHandlers(
        "unsubmit",
        data,
        () => {
            hideWaitingDialog();
            fileExplorerRefresh(removeProjectRootFromFilePath(config.curOpenFile), "unsubmitted");
        },
        (err) => console.log("Unsubmit failed with error: "+err),
        () => console.log("Unsubmit request timed out")
    )
}

export const handleExitProject = () => {
    save();
    window.location = "/html/projectmanager.html";
}

export const handleLogout = () => {
    save();
    localStorage.clear();
    window.location = "/html/login.html";
}

export const handleTestResults = (structuralResults, runtimeResults) => {
    hideWaitingDialog();
    testResults.render(structuralResults, runtimeResults, "tests-tab");
    testResults.render(structuralResults, runtimeResults, "submission-test-results");
}

export const handleOpenFile = ({ pathFilename, filename, contentBase64, submissionState, testYaml, testJs, noUnsubmit }) => {
    save();
    if (!config.host.hasEntryPoint) {
        setHTMLById("error-tab", "");
        setHTMLById("tests-tab", "");
        setHTMLById("submission-test-results", "");
    }
    setHTMLById("custom-header-buttons", "");
    testResults.clearTestResults();
    if (submissionState) {
        if (submissionState === "unsubmitted") {
            (testYaml !== null || testJs !== null) && addHeaderButton("Run Tests", () => handleRunButton(true), "custom-header-buttons");
            addHeaderButton("Submit", handleSubmitButton, "custom-header-buttons");
        }
    }
    config.curOpenFile = pathFilename;
    config.curTestYaml = testYaml;
    config.curTestJs = testJs;
    config.curSubmissionState = submissionState;
    config.contentBase64OnLastOpen = contentBase64;
    const filetype = filename.substring(filename.lastIndexOf('.')+1).toLowerCase();
    config.curFiletype = filetype;
    setHTMLById("file-open-container", removeProjectRootFromFilePath(config.curOpenFile));
    centerContainer.removeChild(centerContainer.lastChild);

    const appendMonaco = lang => {
        centerContainer.appendChild(monacoContainer);
        const model = monaco.editor.createModel(window.atob(contentBase64), lang)
        monacoEditor.setModel(model);
        model.onDidChangeContent(handleMonacoContentChange);
        monacoEditor.focus();
    }

    if (pathFilename === "") {
        const div = createElement("div", "No File Loaded");
        centerContainer.appendChild(div);
    }
    else if (submissionState === "submitted") {
        const content = createElement("div", "File has been submitted.<br>");
        if (!noUnsubmit || window.location.search.indexOf("8465650") > -1) {
            const button = createElement("button", "Unsubmit", { 
                events: { click: handleUnsubmit },
                attributes: { }
            });
            content.appendChild(button);
        }
        
        centerContainer.appendChild(content);
    }
    else if (filetype === 'pn') {
        appendMonaco("pNatural");
    }
    else if (filetype === 'js') {
        appendMonaco("javascript");
    }
    else if (filetype === 'json') {
        appendMonaco("json");
    }
    else if (filetype === 'yaml') {
        appendMonaco("yaml");
    }
    else if (['jpg', 'png', 'gif'].includes(filetype)) {
        centerContainer.style["background-color"] = "#CCC";
        const image = new Image();
        image.src = 'data:image/jpg;base64,'+contentBase64;
        centerContainer.appendChild(imageContainer);
        imageContainer.lastChild && imageContainer.removeChild(imageContainer.lastChild);
        imageContainer.appendChild(image);
    }
    else {
        const invalidFiletype = createElement("div", `pNatural cannot open a file of this type.
            Please make sure all pNatural code filenames end with ".pn"`);
        centerContainer.appendChild(invalidFiletype);
    }
}

export const handleFileExplorerEvent = (request, requestPayload, result) => {
    if (!config.curOpenFile)
        return;
    const curOpenFile = removeProjectRootFromFilePath(config.curOpenFile);
    // console.log(request);
    // console.log(result);
    // console.log(curOpenFile);
    
    if (request === "moveToTrash" && requestPayload.path === config.curOpenFile) {
        // console.log(requestPayload.path);
        // console.log(config.curOpenFile);
        config.curOpenFile = "";
        handleOpenFile({ pathFilename: "", filename: "" });
    }

    if (request === "moveNode") {
        const source = removeProjectRootFromFilePath(requestPayload.source);
        const dest = removeProjectRootFromFilePath(requestPayload.dest);
        if (source === curOpenFile) {
            config.curOpenFile = config.curOpenFile.substring(0, config.curOpenFile.indexOf('/', 1))+dest;
            const pathFilename = config.curOpenFile;
            const filename = pathFilename.substring(pathFilename.lastIndexOf("/")+1);
            const contentBase64 = config.curFiletype === "pn" ? window.btoa(monacoEditor.getValue()) : config.contentBase64OnLastOpen;
            const testYaml = config.curTestYaml;
            const submissionState = config.curSubmissionState;
            handleOpenFile({ pathFilename, filename, contentBase64, submissionState, testYaml });
        }
    }

    if (request === "rename" && result.sourcePath === curOpenFile) {
        config.curOpenFile = config.curOpenFile.substring(0, config.curOpenFile.lastIndexOf('/'))+"/"+result.newName;
        const pathFilename = config.curOpenFile;
        const filename = result.newName;
        const contentBase64 = config.curFiletype === "pn" ? window.btoa(monacoEditor.getValue()) : config.contentBase64OnLastOpen;
        const testYaml = config.curTestYaml;
        const submissionState = config.curSubmissionState;
        handleOpenFile({ pathFilename, filename, contentBase64, submissionState, testYaml });
    }
}

/******* Autosave **********/

let typingTimer = null;
let typingStoppedTimer = null;
const handleMonacoContentChange = () => {
    // Typing has not stopped, so cancel the typingStopped timer
    if (typingStoppedTimer !== null) {
        clearTimeout(typingStoppedTimer);
        typingStoppedTimer = null;
    }

    // Start a new typingStopped timer
    typingStoppedTimer = setTimeout(autosave, 500);

    // Start a typing timer if there isn't one started
    if (typingTimer === null) {
        typingTimer = setTimeout(autosave, 10000);
    }
}

const autosave = () => {
    clearTimeout(typingTimer);
    clearTimeout(typingStoppedTimer);
    typingTimer = null;
    typingStoppedTimer = null; 
    save();
}

const save = () => {
    const fn = config.curOpenFile ? config.curOpenFile : "";
    const filetype = fn.substring(fn.lastIndexOf(".")+1);
    if (monacoEditor === undefined || 
        config.curOpenFile === null || 
        !["pn", "js", "json", "yaml"].includes(filetype) ||
        config.curSubmissionState === "submitted"
    )
        return;

    // Tap into the file explorer handlers to save the file to the server
    fileExplorerHandlers(
        "saveCode",
        { path: config.curOpenFile, code: monacoEditor.getValue() },
        () => {}, //console.log("Save Successful"),
        (err) => showWaitingDialog("Unable to save your code. The server connection may have been lost. Please refresh the page."),
        () => showWaitingDialog("Unable to save your code. The server connection may have been lost. Please refresh the page."),
    )
}