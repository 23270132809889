import React from "react";
import ReactDOM from "react-dom/client";
import { getElementById } from "./dom-helpers";
import { handleEvent as fileExplorerHandlers, getProjectName } from './file-explorer/handlers';
import { FileExplorer } from "./file-explorer/component/file-explorer";
import * as uiHandlers from "./handlers-ui";
import { config } from "./index";

/****** Left Sidebar ******/
export let fileExplorerRefresh;

export const init = (treeUpdateHandler) => {
    getElementById("project-name").innerHTML = getProjectName();
    const feContainer = getElementById('file-explorer-container');
    const root = ReactDOM.createRoot(feContainer);
    const fileExplorerRefreshSetter = (fn) => { fileExplorerRefresh = fn; }
    root.render(<FileExplorer 
        handlers={fileExplorerHandlers} 
        treeUpdateHandler={treeUpdateHandler}
        onOpenFile={uiHandlers.handleOpenFile}  
        overlay={getElementById("file-explorer-overlay")}
        refreshSetter={fileExplorerRefreshSetter}
        projectId={config.projectId}
    />);
}