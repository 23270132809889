import Konva from "konva";
import KonvaNode from "./konva-node";
import { addToImage } from "../konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";

export default class Group extends KonvaNode {

    static $doc_description = `A group is used to combine multiple shapes into a single entity.`;

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Creates a new _Group_ object.  ` ];
    constructor() { 
        super();
        this.$shape = new Konva.Group();
        this.add = this.add.bind(this);
        addToImage(this.$shape);
        this.$addEventListeners();
    }

    static $_add = [NO_BLOCK, NO_RETURN, 
    `Adds the given shape to the group.
        @object shape   `];
    add(child) {  
        this.$shape.add(child.$shape);
    }
    
}