import { createObject } from "../interpreter/api-helpers";
import $Global from './_global';
import PnMath from './math';

export let debugHandler;

export const init = (dh) => {
    debugHandler = dh;
}

export const api = {
    globalObj: createObject($Global),
    classes: {
        $Global, PnMath
    }
}

