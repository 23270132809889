import * as xterm from 'xterm';
import 'xterm/css/xterm.css';

let term;
let termBuf = "";
let handleEnter;

export const setEnterHandler = (fn) => handleEnter = fn;

export const init = () => {
    term = new xterm.Terminal({ 
        cursorBlink: true,
        drawBoldTextInBrightColors: true,
        cols: 50
    
    });
    term.open(document.getElementById("output-container"));
    term.writeln('\u001b[1mWelcome to pNatural Terminal');
    term.writeln("");

    term.onKey(function ({domEvent}) {
        const key = domEvent.key;
        if (!key) {
            term.write("?");
            return;
        }
    
        const isPrintable = key.length === 1;
        if (isPrintable) {
            termBuf += key;
            term.write(key);
            return;
        }
        
        const controlKeys = {
            "Enter": () => { 
                term.writeln("");
                handleEnter(termBuf);
                termBuf = "";
            },
            "Backspace": () => {
                if (termBuf.length === 0)
                    return;
                termBuf = termBuf.substring(0, termBuf.length-1);
                term.write("\b \b");
            }
        }
    
        if (key in controlKeys) { 
            controlKeys[key]();
        }
    });
}


export const clear = () => { termBuf = ""; term.reset() };
export const focus = () => term.focus();
export const print = (data, newLine=true, color="\u001b[0m") => {
    term.write(color);
    term.write(data);
    term.write("\u001b[0m");
    if (newLine)
        term.writeln("");
}


