let scopes = [];

export const init = () => {
    scopes = []; // Create an empty scope for user global symbols
}

export const getCurScope = () => scopes[scopes.length - 1];

export const isInTopLevelScope = () => scopes.length === 1;

export const pushScope = (args={}, obj) => {
    if (obj === undefined && scopes.length > 0)
        obj = getCurScope().obj;
    const scope = { symbols: {}, obj };
    for (let name in args) {
        scope.symbols[name] = args[name];
    }
    scopes.push(scope)
};

export const popScope = () => scopes.pop();

export const getSymbol = (name) => {

    // First search the user scopes top-down
    for (let i = scopes.length-1; i >= 0; i--) {
        if (name in scopes[i].symbols)
            return scopes[i].symbols[name];
    }
}

export const setSymbol = (name, value) => {
    if (scopes.length === 0)
        return;

    let destScope;

    // If the variable has already been defined, determine the scope it has been
    // defined in
    for (let i = scopes.length-1; i >= 0; i--) {
        if (name in scopes[i].symbols) {
            destScope = scopes[i];
            break;
        }
    }
    destScope = destScope ? destScope : getCurScope();
    destScope.symbols[name] = value;
}


