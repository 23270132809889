import * as interpreter from "../../interpreter/interpreter";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createObject, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import PnObject from "../../common-api/types/object";
import PnError from "../../interpreter/pn-error";
import phaser from "../phaser";
import Events from "./events";
import { api as commonApi } from "../../common-api/common-api";
const reportError = (id, args) => { 
    throw new PnError("host-phaser.phaser-api.game."+id, args);
}


export default class Game extends PnObject {

    // static $_constructor = [NO_BLOCK, NO_RETURN,
    // `desc ` ]
    constructor(defaultScene) {
        super();
        this.loadScene = this.loadScene.bind(this);
        this.getNextEvents = this.getNextEvents.bind(this);
        this.detectCollision = this.detectCollision.bind(this);
        this.hasKeyDown = this.hasKeyDown.bind(this);
        this.debugKeysDown = this.debugKeysDown.bind(this);

        !defaultScene && reportError("user_created_game_instance");
        this.$curScene = defaultScene;
        defaultScene.$load();
    }

    set curScene(value) { reportError("user_setting_cur_scene") }
    get curScene() { return this.$curScene }

    static $_getNextEvents = [BLOCKS, RETURNS, 
    `Waits until the next frame update, then returns an *Events* object containing events that occurred in that update.`];
    getNextEvents() {
        const onUpdate = eventsArr => {
            const eventsObj = createObject(Events, [eventsArr]);
            interpreter.resolve(eventsObj);
        }
        phaser.setSendEventsCallback(onUpdate)
    }

    static $_loadScene = [NO_BLOCK, NO_RETURN, 
    `Loads the given scene. 
        @object scene
    `];
    loadScene(scene) {  
        !scene.$isPnScene && reportError("load_scene_given_non_scene");
        this.$curScene.$unload();
        this.$curScene.$isActive = false;
        this.$curScene = scene;
        scene.$load();
    }

    static $_addGrid = [NO_BLOCK, NO_RETURN, 
    `Displays a grid on the screen for ease of coding. 
    `];
    addGrid() {  
        phaser.addGrid();
    }

    static $_hasKeyDown = [NO_BLOCK, RETURNS, 
    `Returns *True* if the given key is currently being pressed, otherwise returns *False*. 
        @string key    
    `];
    hasKeyDown(key) {
        return !!phaser.getKeysDown()[key];
    }

    static $_createDebugGraphic = [NO_BLOCK, NO_RETURN, 
    `Shows each Game Object's hit box, for ease of debugging. 
    `];
    createDebugGraphic() {  
        phaser.createDebugGraphic();
    }

    static $_debugKeysDown = [NO_BLOCK, NO_RETURN, 
    `Displays all keys currently being pressed to the debug console.
    `];
    debugKeysDown() {  
        const keysDown = Object.keys(phaser.getKeysDown());
        if (keysDown.length === 0)
            return;
        const list = createBuiltInTypeObject(keysDown);
        commonApi.globalObj.debug(list);
    }

    static $_detectCollision = [NO_BLOCK, NO_RETURN, 
    `Registers collision detection between the two given groups.  
        @string group1
        @string group2
        @boolean shouldBlock If *True*, the objects will be prevented from overlapping upon a collision and they will instead stop or rebound off each other.
    `];
    detectCollision(group1, group2, shouldBlock) {  
        phaser.detectCollision(group1, group2, shouldBlock);
    }

    static $_getMouseX = [NO_BLOCK, RETURNS,
    `Returns the current x coordinate of the mouse.
    `]
    getMouseX() {
        return phaser.getMouseCoordinates().x;
    }

    static $_getMouseY = [NO_BLOCK, RETURNS,
    `Returns the current y coordinate of the mouse.
    `]
    getMouseY() {
        return phaser.getMouseCoordinates().y;
    }

    static $_setGravity = [NO_BLOCK, NO_RETURN,
    `Sets the strength of the gravity.
        @number speed in pixels per second.
    `]
    setGravity(speed) {
        phaser.setGravity(speed);
    }

    static $_save = [NO_BLOCK, NO_RETURN,
    `Stores a value to the browser's local storage that can be access the next time the game is run on the same computer, under the same user accont.
        @string name A name to identify the content being saved.
        @any value The content being saved.
    `]
    save(name, value) {
        const key = name+"@"+window.location.search;
        localStorage.setItem(key, value);
    }

    static $_load = [NO_BLOCK, RETURNS,
    `Loads a value from the browser's local storage that was previously saved on the same computer, under the same user account. Returns the loaded value.
        @string name The name the content was saved under.
    `]
    load(name) {
        const key = name+"@"+window.location.search;
        let value = localStorage.getItem(key);
        if (value === null)
            value = 0;
        if (!isNaN(Number(value)))
            value = Number(value);
        return value;
    }
}