import Konva from "konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import KonvaShape from "./konva-shape";

export default class Circle extends KonvaShape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Create a new _Circle_ object, with the specified initial properties.
        @number x The x coordinate of the center of the circle
        @number y The y coordinate of the center of the circle
        @number radius
        @any color
    ` ]
    constructor(x=100, y=100, radius=50, color="black") {
        super();
        this.$boundsRelativeToXY = { top: -radius, bottom: radius, left: -radius, right: radius }
        this.$createShape(Konva.Circle, color, { x, y, radius });
    }

    static $propdoc_radius = "The radius of the circle.";
    set radius(value) { this.$konvaSetter('radius', value, "number") } 
    get radius() { return this.$konvaGetter('radius') }

}