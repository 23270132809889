import { api as commonApi } from "../common-api/common-api";
import { api as textApi } from "../host-text/text-api/text-api"
import { api as konvaApi } from "../host-konva/konva-api/konva-api";
import { api as phaserApi } from "../host-phaser/phaser-api/phaser-api";
import String from "../common-api/types/string";
import List from "../common-api/types/list";
import { getMethodMetaFromClassRef } from "../interpreter/api-helpers";

export const getDocFileContent = () => {
    const result = {};
    result["Common"] = getApiData({
        ...commonApi,
        classes: {
            ...commonApi.classes,
            String,
            List
        }
    });
    result["Terminal"] = getApiData(textApi);
    result["Graphics"] = getApiData(konvaApi);
    result["Game Engine"] = getApiData(phaserApi);
    return result;
}

const getApiData = api => {
    const result = {};
    for (let className in api.classes) {
        const cls = api.classes[className];
        result[className] = {};
        if (cls.$doc_title)
            result[className].title = cls.$doc_title;
        if (cls.$doc_description)
            result[className].description = cls.$doc_description;
        result[className].methods = getMethodMeta(cls);
        result[className].props = getPropMeta(cls);
        let parent = cls.__proto__;
        while (parent.name !== "") {
            result[className].methods = [ ...result[className].methods, ...getMethodMeta(parent) ];
            result[className].props = [ ...result[className].props, ...getPropMeta(parent) ];
            parent = parent.__proto__;
        }
        result[className].methods.sort((a, b) => a.methodName < b.methodName ? -1 : 1 );
        result[className].props.sort((a, b) => a.propName < b.propName ? -1 : 1 );
    }
    return result;
}

const getMethodMeta = cls => {
    const propNames = Object.getOwnPropertyNames(cls).filter(str => str.indexOf("$_") === 0);       
    const methods = propNames.map(pn => getMethodMetaFromClassRef(cls, pn.substring(2)));
    return methods;
}

const getPropMeta = cls => {
    const propNames = Object.getOwnPropertyNames(cls).filter(str => str.indexOf("$propdoc_") === 0);       
    const props = propNames.map(pn => ({ 
        propName: pn.substring(9),
        desc: cls[pn]
    }));
    return props;
}
