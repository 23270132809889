import Konva from "konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import KonvaShape from "./konva-shape";

export default class Rect extends KonvaShape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Create a new _Rect_ object, with the specified initial properties.
        @number x The x coordinate of the top-left corner of the rectangle
        @number y The y coordinate of the top-left corner of the rectangle
        @number width
        @number height
        @any color
    ` ]
    constructor(x=100, y=100, width=50, height=50, color="black") {
        super();
        this.$createShape(Konva.Rect, color, { x, y, width, height });
    }

    static $propdoc_width = "The width of the rectangle."
    set width(value) { 
        this.$konvaSetter('width', value, "number"); 
        this.$resetBounds();
        this.color = this.color;
    } 
    get width() { return this.$konvaGetter('width') }

    static $propdoc_height = "The height of the rectangle."
    set height(value) { 
        this.$konvaSetter('height', value, "number"); 
        this.$resetBounds();
        this.color = this.color;
    } 
    get height() { return this.$konvaGetter('height') }
}