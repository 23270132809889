export let curEventState = null;
export const setCurEventState = state => { curEventState = state };

export const fullPathFilenameToFilename = (pathFn) => pathFn.substring(pathFn.lastIndexOf('/')+1); 
export const fullPathFilenameToPath = (pathFn) => pathFn.substring(0, pathFn.lastIndexOf('/')); 
export const PATH_TO_ROOT = '/[Project Root]';
export const PATH_TO_TRASH = PATH_TO_ROOT+'/Trash';

// Returns the node object that a pathArray points to
export const getNode = (pathArray, branch) => {
    pathArray = [...pathArray];
    let search = pathArray[0];
    pathArray.shift();
    for (let node of branch) {
        if (node.name === search) {
            if (pathArray.length === 0)
                return node;
            return getNode(pathArray, node.children);
        }
    }
}

export const pathArrayToString = (pathArray) => '/' + pathArray.join('/');

export const getContainingFolder = (pathArray, tree) => pathArray.length === 1 ? tree : 
    getNode(pathArray.slice(0, -1), tree).children;

export const sortTree = (tree) => { 
    sortBranch(tree.children); 
} 

const sortBranch = (dir) => {
    dir = dir.sort((a, b) => {
        if (a.type === "trash") return 1;
        if (b.type === "trash") return -1;
        if (a.type === "folder" && b.type === "file") return -1;
        if (a.type === "file" && b.type === "folder") return 1;
        if (a.name < b.name) return -1;
        return 1;
    })
    for (let item of dir) {
        if (item.type === "folder") 
            sortBranch(item.children);
    }
}

export const getTrashName = (name) => `[${(new Date().toISOString())}] ${name}`;

export const getPathObj = (str, tree) => {
        const arr = str.substring(1).split('/');
        const node = getNode(arr, [tree]);
        const type = node.type;
        //const containingFolder = getContainingFolder(arr, [tree]);
        const containingFolderStr = fullPathFilenameToPath(str);
        const containingFolderNode = getNode(arr.slice(0, -1), [tree]);

        // The folderAsDest[...] properties indicate where a new node should be inserted if the given
        // node has been selected as a destination (eg, add a file here, move here, upload here)
        const folderAsDestStr = "children" in node ? str : containingFolderStr;
        const folderAsDestNode = "children" in node ? node : containingFolderNode;

        return { str, arr, node, type, containingFolderStr, containingFolderNode, folderAsDestStr, folderAsDestNode };
}



// Returns null if no error found, otherwise returns an error message as a string.
export const validateNewFilename = (sourcePathFn, destPathFn, tree) => {

    if (sourcePathFn && sourcePathFn === destPathFn)
        return null;

    // Extract destination paths and filenames
    const destFn = fullPathFilenameToFilename(destPathFn);
    const destPath = fullPathFilenameToPath(destPathFn);

    // We'll only accept characters of the "POSIX portable file name character set"
    const re = /^[\w-\.]+$/g;
    const match = destFn.match(re);
    if (match === null || match.length > 50) {
        return "Invalid filename. Filenames must be between 1 and 50 characters long, and can only include the characters A-Z, a-z, 0-9, -, _ and .";
    }

    // See if a file by the same name exists in the containing folder
    const destPathObj = getPathObj(destPath, tree);
    const fileExists = destPathObj.node.children.map(entry => entry.name).includes(destFn);

    if (fileExists) {
        return "Invalid filename. A file of the same name already exists in this folder.";
    }

    return null;
}