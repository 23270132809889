import { createBuiltInTypeObject } from "../../interpreter/api-helpers";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("common-api.types.base-type."+id, args);
}

export default class BaseType {
    $validateOperation(op, rightSide) {} // Implemented in subclass
    $getOperationResult(op, rightSide) { return null } // Subclass can overrride

    $operate(op, rightSide) {
        this.$validateOperation(op, rightSide);
        const result = this.$getOperationResult(op, rightSide)
        if (result === null) {
            const resultValue = this.$getDefaultOperationResult(op, this.value, rightSide.value);
            return createBuiltInTypeObject(resultValue);
        }
        return result;
    }

    $getDefaultOperationResult(op, left, right)  {
        //console.log({left, op, right});
        switch (op) {
            case 'plus': return left + right;
            case 'minus': return left - right; 
            case 'multiply': return left * right;
            case 'divide': return left / right;
            case 'modulo': return left % right;
            case 'gt': return left > right;
            case 'lt': return left < right; 
            case 'gte': return left >= right; 
            case 'lte': return left <= right; 
            case 'eq': return left === right; 
            case 'neq': return left !== right; 
            case 'and': return left && right; 
            case 'or': return left || right; 
            default: throw new Error("Invalid operator type: "+opcode);
        }
    }

    $getOpType(op) {
        if (["plus", "minus", "multiply", "divide", "modulo"].includes(op))
            return "arithmetic";
        if (["gt", "lt", "gte", "lte", "eq", "neq"].includes(op))
            return "comparison";
        if (["and", "or"].includes(op))
            return "boolean";
        if (op === "assignment")
            reportError("assignment_op_within_expression");
        throw new Error("Invalid op: "+op);
    }

    $reportIncompatibleTypes(op, right) {
        const opIdToSymbol = {
            plus: "+",
            minus: "-",
            multiply: "*",
            divide: "/",
            modulo: "%",
            gt: ">",
            lt: "<",
            gte: ">=",
            lte: "<=",
            eq:  "==",
            neq: "!=",
            and: "and",
            or: "or",
        }

        reportError("incompatible_types", { 
            leftType: Array.isArray(this.value) ? "list" : typeof this.value,
            rightType: Array.isArray(right.value) ? "list" : typeof right.value,
            op: opIdToSymbol[op]
        });
    }

    $index(isRange, start, end) {
        reportError("non_indexable_type", { type: this.$type });
    }

    $validateIndexRange(isRange, start, end, lastIndex) {
        //const maxIndex = isRange ? lastIndex + 1 : lastIndex;
        const maxIndex = lastIndex;
        const validate = index => {
            if (index === null) return;
            !Number.isInteger(index.value) && reportError("list_index_non_integer", { value: index.value });
            (index.value < 0 || index.value > maxIndex) && reportError("list_index_out_of_bounds", { value: index.value, max: maxIndex });
        }

        validate(start);
        validate(end);

        if (start !== null && end !== null && start.value > end.value)
            reportError("start_greater_than_end", { start: start.value, end: end.value });
    }
}