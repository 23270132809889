import Phaser from 'phaser';
import { colorNameToHex } from './util';
import { EVENT_FOR_ALL, EVENT_FOR_SCENE } from './phaser-api/events';
import { createBuiltInTypeObject } from '../interpreter/api-helpers';

let game;
let scene;
let gameObjects = {};
let events = [];
let resolveInitPromise;
let sendEvents;
let keysDown = {};
let groups = {};
let mapWidth;
let mapHeight;
let bgImg;

class PnScene extends Phaser.Scene {
    constructor(images) {
        super();
        this.images = images;
        events = [];
        keysDown = {};
        gameObjects = {};
        groups = {};
    }
      
    create() {  
        for (let key in this.images) {
            this.textures.addImage(key, this.images[key]);
        }

        this.input.keyboard.on('keydown', event => {
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key))
                event.preventDefault();
            if (!!keysDown[event.key])
                return; // Don't register another event if the key is being held
            if (events.filter(ev => ev.type === "keydown" && ev.key === event.key).length > 0)
                return; // Don't register the same key more than once per update
            events.push({
                type: "keydown",
                goId: EVENT_FOR_ALL,
                key: event.key
            });
            keysDown[event.key] = true;
        });
    
        this.input.keyboard.on('keyup', event => {
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key))
                event.preventDefault();
            if (events.filter(ev => ev.type === "keyup" && ev.key === event.key).length > 0)
                return; // Don't register the same key more than once per update
            events.push({
                type: "keyup",
                goId: EVENT_FOR_ALL,
                key: event.key
            })
            delete keysDown[event.key];
        });

        this.input.on('pointerdown', (e) => {
            events.push({
                type: "mouseDown",
                goId: EVENT_FOR_ALL,
                button: e.button
            })
        });

        this.input.on('pointerup', () => {
            events.push({
                type: "mouseUp",
                goId: EVENT_FOR_ALL
            })
        });

        mapWidth = 650;
        mapHeight = 400;

        resolveInitPromise();
    }

    update() {
        //console.log(scene.physics.world.bodies.entries);
        if (sendEvents) {
            sendEvents([...events]);
            events = [];
            sendEvents = null;
        }
    }
}

const init = (images) => {
    return new Promise((resolve, reject) => {
        resolveInitPromise = resolve;
        scene = new PnScene(images);
        const config = {
            type: Phaser.AUTO,
            parent: 'output-container',
            width: 640,
            height: 400,
            physics: {
                default: "arcade",
                // arcade: {
                //     gravity: { y: 10 }
                // }
            },
            scene
        };
        game = new Phaser.Game(config);
    })
}

const addGrid = () => {
    const cssColor = "#999999";
    const hexColor = colorNameToHex(cssColor);
    for (let i = 50; i < 400; i += 50) {
        scene.add.line(300, i, 0, 0, 650, 0, hexColor);
        scene.add.text(5, i, i, { color: cssColor, size: 9 });
    }
    for (let i = 50; i < 650; i += 50) {
        scene.add.line(i, 200, 0, 0, 0, 400, hexColor);
        scene.add.text(i-10, 5, i, { color: cssColor, size: 9 });
    }
}

const addToGroup = (sprite, groupName) => {
    groupName = groupName.toUpperCase();
    if (!groups[groupName]) 
        groups[groupName] = scene.physics.add.group();
    if (!groups[groupName].contains(sprite))
        groups[groupName].add(sprite);
}

const detectCollision = (groupName1, groupName2, shouldBlock) => {
    groupName1 = groupName1.toUpperCase();
    groupName2 = groupName2.toUpperCase();
    groups[groupName1] = groups[groupName1] ? groups[groupName1] : scene.physics.add.group();
    groups[groupName2] = groups[groupName2] ? groups[groupName2] : scene.physics.add.group();
    const g1ref = groups[groupName1];
    const g2ref = groups[groupName2];
    const callback = (sprite1, sprite2) => {
        const goId1 = sprite1.getData("pnObject").$goId;
        const goId2 = sprite2.getData("pnObject").$goId;
        
        // Throw out duplicate events
        for (let ev of events) {
            if (ev.other && ev.goId === goId1 && ev.other.$goId === goId2)
                return;
        }

        events.push({
            type: "collide",
            goId: goId1,
            other: sprite2.getData("pnObject")
        });
        events.push({
            type: "collide",
            goId: goId2,
            other: sprite1.getData("pnObject")
        });
    }
    if (shouldBlock)
        scene.physics.add.collider(g1ref, g2ref, callback);
    else
        scene.physics.add.overlap(g1ref, g2ref, callback);
}

const unloadScene = () => {
    // for (let id in groups) {
    //     groups[id].destroy();
    // }
    //groups = {};
    for (let id in gameObjects) {
        gameObjects[id].destroy();
    }
    gameObjects = {};
    events = [];
    if (bgImg && bgImg.destroy)
        bgImg.destroy();
    scene.cameras.main.stopFollow();
    scene.cameras.main.removeBounds();
    scene.cameras.main.setScroll(0, 0);
}

const externalRequests = {
    init,
    getScene: () => scene,
    getMapDimensions: () => ({ width: mapWidth, height: mapHeight }),
    setBackgroundColor: colstr => scene.cameras.main.setBackgroundColor(colorNameToHex(colstr)),
    setBackgroundImage: path => {
        if (path === "")
            return;
        bgImg = scene.add.image(0, 0, "/root"+path).setOrigin(0);
        bgImg.scaleX = bgImg.width == 0 ? 1 : 650/bgImg.width;
        bgImg.scaleY = bgImg.height == 0 ? 1 : 400/bgImg.height;
    },
    setScrollingBackgroundImage: (imagePath, followSprite) => {
        bgImg = scene.add.image(0, 0, "/root"+imagePath).setOrigin(0);
        scene.cameras.main.setBounds(0, 0, bgImg.displayWidth, bgImg.displayHeight);
        mapWidth = bgImg.displayWidth;
        mapHeight = bgImg.displayHeight;
        scene.cameras.main.startFollow(followSprite.$phaserObject);
    },
    addGameObject: (go, id) => {
        gameObjects[id] = go;
        scene.add.existing(go);
        scene.physics.add.existing(go);
    },
    removeGameObject: id => delete gameObjects[id],
    addMessageEvent: (id, message) => {
        events.push({
            type: "message",
            goId: id,
            message
        });
    },
    setSendEventsCallback: fn => { sendEvents = fn },
    addGrid,
    createDebugGraphic: () => scene.physics.world.createDebugGraphic(),
    pause: () => scene.physics.world.pause(),
    getKeysDown: () => keysDown,
    addToGroup, 
    detectCollision,
    unloadScene,
    getMouseCoordinates: () => ({ x: game.input.mousePointer.x, y: game.input.mousePointer.y}),
    setGravity: (speed) => scene.physics.world.gravity = { x: 0, y: speed},
    getGravity: () => scene.physics.world.gravity

}


export default externalRequests;



