import * as interpreter from "../../interpreter/interpreter";
import * as host from "../host-konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import { createObject, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import * as symbolTable from "../../interpreter/execution/model/symbol-tables";
import { setAutoAdd as autoAddSetter, layer } from "../konva";

export default class $Global {
   
    static $_pause = [BLOCKS, NO_RETURN, 
    `Pauses program execution for the given number of milliseconds.
        @number how_long    The amount of time to pause in milliseconds. `];
    pause(ms) {  setTimeout(() => interpreter.resolve(), ms);   }


    static $_addGrid = [NO_BLOCK, NO_RETURN, 
    `Adds a grid to the screen to help visualize the coordinate system.  `];
    addGrid() {  host.addGrid();  }


    // static $_setAutoAdd = [NO_BLOCK, NO_RETURN, 
    // `If set to _True_, pNatural will add all shapes to the stage upon creation. Otherwise, shapes will need to
    // be added to the stage or a group manually. This value is set to _True_ by default, and only needs to be set
    // to _False_ if you are using groups.
    //     @boolean autoAdd `];
    // setAutoAdd(value) { autoAddSetter (value) }

    static $_mouseX = [NO_BLOCK, NO_RETURN, 
        `Returns the current x-coordinate of mouse.
    `];
    mouseX() { 
        return layer.getRelativePointerPosition() ? createBuiltInTypeObject(layer.getRelativePointerPosition().x) : createBuiltInTypeObject(0);
    }

    static $_mouseY = [NO_BLOCK, NO_RETURN, 
        `Returns the current y-coordinate of mouse.
    `];
    mouseY() { 
        return layer.getRelativePointerPosition() ? createBuiltInTypeObject(layer.getRelativePointerPosition().y) : createBuiltInTypeObject(0);
    }

    static $_getNextKeyboardEvent = [NO_BLOCK, RETURNS, 
        `Returns the next event from the keyboard's event queue. `]
    getNextKeyboardEvent() {
        const kbEvents = symbolTable.getSymbol("kbEvents");
        if (kbEvents.value.length === 0)
            return createBuiltInTypeObject({ type: "none" });
        const ev = kbEvents.value[0];
        kbEvents.value.shift();
    return ev;
    }

    static $_getNextKeyboardEvent = [NO_BLOCK, RETURNS, 
        `Returns the next event from the keyboard's event queue. `]
    getNextKeyboardEvent() {
        const kbEvents = symbolTable.getSymbol("kbEvents");
        if (kbEvents.value.length === 0)
            return createBuiltInTypeObject({ type: "none" });
        const ev = kbEvents.value[0];
        kbEvents.value.shift();
        return ev;
    }

    static $_clearKeyboardEvents = [NO_BLOCK, NO_RETURN, 
        `Clears all events from the keyboard's event queue. `]
    clearKeyboardEvents() {
        const kbEvents = symbolTable.getSymbol("kbEvents");
        kbEvents.value.length = 0;
    }


    static $_hgrad = [NO_BLOCK, NO_RETURN, 
    `Returns a horizontal gradient object, with the given colors evenly spaced out.
        *string colors You can provide as many colors as you'd like
    `];
    hgrad() {  
        const obj = createObject();
        obj.grad_type = createBuiltInTypeObject("horizontal-simple");
        obj.colors = createBuiltInTypeObject([...arguments]);
        return obj;
    }
 
    static $_vgrad = [NO_BLOCK, NO_RETURN, 
    `Returns a vertical gradient object, with the given colors evenly spaced out.
        *string colors You can provide as many colors as you'd like
    `];
    vgrad() {  
        const obj = createObject();
        obj.grad_type = createBuiltInTypeObject("vertical-simple");
        obj.colors = createBuiltInTypeObject([...arguments]);
        return obj;
    }

    static $_rgrad = [NO_BLOCK, NO_RETURN, 
    `Returns a radial gradient object, with the given colors evenly spaced out.
        *string colors You can provide as many colors as you'd like
    `];
    rgrad() {  
        const obj = createObject();
        obj.grad_type = createBuiltInTypeObject("radial-simple");
        obj.colors = createBuiltInTypeObject([...arguments]);
        return obj;
    }

    static $_rgb = [NO_BLOCK, RETURNS, 
    `Returns a color string based on the given intensities of red, green and blue. Values for each of the intensities
    can be between 0 and 255.
        @number red
        @number green
        @number blue
    `];
    rgb(r, g, b) {  
        const componentToHex = c => {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b); 
    }

    static $_hsl = [NO_BLOCK, RETURNS, 
    `Returns a color string based on the given values of hue, saturation and lightness.
        @number hue The angle, in degrees, specifying the hue on the colour wheel.
        @number saturation A value between 0 and 100, specifying the percentage of saturation
        @number lightness A value between 0 and 100, specifying the percentage of lightness
    `];
    hsl(h, s, l) {  
        // Algorithm courtesy css-tricks
        s /= 100;
        l /= 100;
      
        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c/2,
            r = 0,
            g = 0, 
            b = 0; 
      
        if (0 <= h && h < 60) {
          r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
          r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
          r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
          r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
          r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
          r = c; g = 0; b = x;
        }
        // Having obtained RGB, convert channels to hex
        r = Math.round((r + m) * 255).toString(16);
        g = Math.round((g + m) * 255).toString(16);
        b = Math.round((b + m) * 255).toString(16);
      
        // Prepend 0s, if necessary
        if (r.length == 1)
          r = "0" + r;
        if (g.length == 1)
          g = "0" + g;
        if (b.length == 1)
          b = "0" + b;
      
        return "#" + r + g + b;
    }
}