import * as uiHandlers from "../handlers-ui";

const searchParams = new URLSearchParams(document.location.search);
const pathParam = searchParams.get('path') ? searchParams.get('path') : "";
const projectParam = searchParams.get('project') ? searchParams.get('project') : "";
const user = localStorage.getItem("user");
const auth = { pathParam, projectParam, user }

export const getAuth = () => auth;
export const getUsername = () => {
    if (auth.user === null)
        return null;
    const userEmail = window.atob(window.atob(auth.user));
    return userEmail.substring(0, userEmail.indexOf("@"));
}
export const getProjectName = () => {
    if (pathParam !== "")
        return pathParam.substring(pathParam.lastIndexOf('/')+1);
    if (projectParam !== "")
        return projectParam.substring(projectParam.lastIndexOf('/')+1);
}

if (user === null) {
    window.location = "/html/login.html";
}

async function postData(url, headers, body) {
    const response = await fetch(url, { method: 'POST', headers, body });
    return response.json(); 
}

export const handleEvent = (request, payload, successCallback, failCallback, timeoutCallback) => {

    let url;
    let headers;
    let body;
    //const protocol = location.hostname === "localhost" ? "http" : "https";
    let protocol = "https";
    let hostname = "pn22server.dstewart.ca";

    if (location.hostname == "localhost") {
        protocol = "http";
        hostname = "localhost:8081"
    }
    
        
    if (request === "uploadFile") {
        url = `${protocol}://${hostname}/upload`;
        headers = { 
            'pn-auth': JSON.stringify(auth),
            'pn-path': payload.path
        };
        let fd = new FormData();
        fd.append('myfile', payload.file);
        body = fd;
    }
    else {
        url = `${protocol}://${hostname}/filesystemrequest`;
        headers = { 'Content-Type': 'application/json' };
        body = JSON.stringify({ 
            type: request,
            payload,
            auth
        })     
    }

    //console.log({request, url, headers, body});

    postData(url, headers, body)
        .then(data => data.status === "success" ? onSuccess(request, payload, data.result, successCallback) : failCallback(data.result))
        .catch((err) => {
            console.log(err);
            timeoutCallback()
        });
}

const onSuccess = (request, requestPayload, result, fileExplorerCallback) => {
    fileExplorerCallback(request, result);
    uiHandlers.handleFileExplorerEvent(request, requestPayload, result);
}

