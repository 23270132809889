// Generated automatically by nearley, version 2.19.9
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }

const moo = require("moo");
const lexer = moo.compile({
    ws: /[ \t]+/,
    nl: { match: "\n", lineBreaks: true },
    class_def_marker: /===+/,
    arrow: "->",
    lte: "<=",
    lt: "<",
    gte: ">=",
    gt: ">",
    eq: "==",
    neq: "!=",
    lparan: "(",
    rparan: ")",
    comma: ",",
    lbracket: "[",
    rbracket: "]",
    lbrace: "{",
    rbrace: "}",
    assignment: "=",
    asst_plus: "+=",
    asst_minus: "-=",
    asst_multiply: "*=",
    asst_divide: "/=",
    plus: "+",
    minus: "-",
    multiply: "*",
    divide: "/",
    modulo: "%",
    colon: ":",
    dot: ".",
    single_quote: "'",
    code_block_start: "{",
    code_block_end: "}",
    string_literal: {
        match: /"(?:[^\n\\"]|\\["\\ntbfr])*"/,
        value: s => JSON.parse(s)
    },
    number_literal: {
        match: /[0-9]+(?:\.[0-9]+)?/,
        value: s => Number(s)
    },
    identifier: {
        match: /[A-Za-z_][A-Za-z_0-9]*/,
        type: moo.keywords({
            while: "while",
            for: "for",
            else: "else",
            in: "in",
            if: "if",
            return: "return",
            and: "and",
            or: "or",
            not: "not",
            true: "True",
            false: "False",
            repeat: "repeat",
            until: "until",
            break: "break",
            continue: "continue",
            forever: "forever",
            elif: "elif",
            extends: "extends",
        })
    },
    errorToken: moo.error
});

function tokenStart(token) {
    return {
        line: token.line,
        col: token.col - 1
    };
}

function tokenEnd(token) {
    const lastNewLine = token.text.lastIndexOf("\n");
    if (lastNewLine !== -1) {
        throw new Error("Unsupported case: token with line breaks");
    }
    return {
        line: token.line,
        col: token.col + token.text.length - 1
    };
}

function convertToken(token) {
    return {
        type: token.type,
        value: token.value,
        start: tokenStart(token),
        end: tokenEnd(token)
    };
}

function convertTokenId(data) {
    return convertToken(data[0]);
}


let string_literal, number_literal, identifier, ws, class_def_marker;

var grammar = {
    Lexer: lexer,
    ParserRules: [
    {"name": "input", "symbols": ["statements"], "postprocess": id},
    {"name": "class_definition", "symbols": [{"literal":"class"}, "__", "identifier", "_", {"literal":":"}, "_ml", {"literal":"{"}, "statements", {"literal":"}"}], "postprocess": 
        d => ({
            type: "class_definition",
            superclass: null,
            name: d[2],
            body: d[7],
            start: tokenStart(d[0]),
            end: tokenEnd(d[8])
        })
                },
    {"name": "class_definition", "symbols": [{"literal":"class"}, "__", "identifier", "__", {"literal":"extends"}, "__", "identifier", "_", {"literal":":"}, "_ml", {"literal":"{"}, "statements", {"literal":"}"}], "postprocess": 
        d => ({
            type: "class_definition",
            name: d[2],
            superclass: d[6],
            body: d[11],
            start: tokenStart(d[0]),
            end: tokenEnd(d[8])
        })
            },
    {"name": "if_statement", "symbols": ["if_clause"], "postprocess":   
        d => ({
            type: "if_statement",
            branches: [{
                name: "If",
                condition: d[0].condition,
                code_block: d[0].code_block,
            }],
            start: d[0].start,
            end: d[0].end
        })
            },
    {"name": "if_statement", "symbols": ["if_clause", "_ml", "else_clause"], "postprocess":   
        d => ({
            type: "if_statement",
            branches: [{
                name: "If",
                condition: d[0].condition,
                code_block: d[0].code_block
            }],
            else_codeblock: d[2].code_block,
            start: d[0].start,
            end: d[2].end
        })
            },
    {"name": "if_statement", "symbols": ["if_clause", "_ml", "elif_clauses"], "postprocess":   
        d => ({
            type: "if_statement",
            branches: [{
                name: "If",
                condition: d[0].condition,
                code_block: d[0].code_block
            }, ...d[2].branches],
            start: d[0].start,
            end: d[2].end
        })
            },
    {"name": "if_statement", "symbols": ["if_clause", "_ml", "elif_clauses", "_ml", "else_clause"], "postprocess":   
        d => ({
            type: "if_statement",
            branches: [{
                name: "If",
                condition: d[0].condition,
                code_block: d[0].code_block
            }, ...d[2].branches],
            else_codeblock: d[4].code_block,
            start: d[0].start,
            end: d[4].end
        })
            },
    {"name": "elif_clauses", "symbols": ["elif_clause", "_ml", "elif_clauses"], "postprocess":   
        d => ({
            branches: [{
                name: "Elif",
                condition: d[0].condition,
                code_block: d[0].code_block
            }, ...d[2].branches],
            start: d[0].start,
            end: d[2].end
        })
            },
    {"name": "elif_clauses", "symbols": ["elif_clause"], "postprocess":  
        d => ({
            branches: [{
                name: "Elif",
                condition: d[0].condition,
                code_block: d[0].code_block
            }],
            start: d[0].start,
            end: d[0].end
        })
            },
    {"name": "if_clause", "symbols": [{"literal":"if"}, "__", "expression", {"literal":":"}, "_ml", "code_block"], "postprocess": 
        d => ({
            condition: d[2],
            code_block: d[5],
            start: tokenStart(d[0]),
            end: d[5].end
        })
                },
    {"name": "else_clause", "symbols": [{"literal":"else"}, {"literal":":"}, "_ml", "code_block"], "postprocess": 
        d => ({
            code_block: d[3],
            start: tokenStart(d[0]),
            end: d[3].end
        })
                    },
    {"name": "elif_clause", "symbols": [{"literal":"elif"}, "__", "expression", {"literal":":"}, "_ml", "code_block"], "postprocess": 
        d => ({
            condition: d[2],
            code_block: d[5],
            start: tokenStart(d[0]),
            end: d[5].end
        })
                },
    {"name": "expression", "symbols": ["boolean_expression"], "postprocess": id},
    {"name": "boolean_expression", "symbols": ["comparison_expression"], "postprocess": id},
    {"name": "boolean_expression", "symbols": [{"literal":"not"}, "_", "comparison_expression"], "postprocess": 
        d => ({
            type: "not_operator",
            value: d[2],
            start: d[2].start,
            end: d[2].end
        })
            },
    {"name": "boolean_expression", "symbols": ["boolean_expression", "_", "boolean_operator", "_", "comparison_expression"], "postprocess": 
        d => ({
            type: "binary_operation",
            operator: convertToken(d[2]),
            left: d[0],
            right: d[4],
            start: d[0].start,
            end: d[4].end
        })
                },
    {"name": "boolean_operator", "symbols": [{"literal":"and"}], "postprocess": id},
    {"name": "boolean_operator", "symbols": [{"literal":"or"}], "postprocess": id},
    {"name": "comparison_expression", "symbols": ["additive_expression"], "postprocess": id},
    {"name": "comparison_expression", "symbols": ["comparison_expression", "_", "comparison_operator", "_", "additive_expression"], "postprocess": 
        d => ({
            type: "binary_operation",
            operator: d[2],
            left: d[0],
            right: d[4],
            start: d[0].start,
            end: d[4].end
        })
                },
    {"name": "comparison_operator", "symbols": [{"literal":">"}], "postprocess": convertTokenId},
    {"name": "comparison_operator", "symbols": [{"literal":">="}], "postprocess": convertTokenId},
    {"name": "comparison_operator", "symbols": [{"literal":"<"}], "postprocess": convertTokenId},
    {"name": "comparison_operator", "symbols": [{"literal":"<="}], "postprocess": convertTokenId},
    {"name": "comparison_operator", "symbols": [{"literal":"=="}], "postprocess": convertTokenId},
    {"name": "comparison_operator", "symbols": [{"literal":"!="}], "postprocess": convertTokenId},
    {"name": "comparison_operator", "symbols": [{"literal":"="}], "postprocess": convertTokenId},
    {"name": "additive_expression", "symbols": ["multiplicative_expression"], "postprocess": id},
    {"name": "additive_expression", "symbols": ["additive_expression", "_", /[+-]/, "_", "multiplicative_expression"], "postprocess": 
        d => ({
            type: "binary_operation",
            operator: convertToken(d[2]),
            left: d[0],
            right: d[4],
            start: d[0].start,
            end: d[4].end
        })
                },
    {"name": "multiplicative_expression", "symbols": ["unary_expression"], "postprocess": id},
    {"name": "multiplicative_expression", "symbols": ["multiplicative_expression", "_", /[*/%]/, "_", "unary_expression"], "postprocess": 
        d => ({
            type: "binary_operation",
            operator: convertToken(d[2]),
            left: d[0],
            right: d[4],
            start: d[0].start,
            end: d[4].end
        })
                },
    {"name": "unary_expression", "symbols": [{"literal":"-"}, "unary_expression"], "postprocess": 
        d => ({
            type: "unary_minus",
            value: d[1],
            start: d[1].start,
            end: d[1].end
        })
                },
    {"name": "unary_expression", "symbols": ["number"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["reference_path"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["string_literal"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["list_literal"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["object_literal"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["boolean_literal"], "postprocess": id},
    {"name": "unary_expression", "symbols": [{"literal":"("}, "_", "expression", "_", {"literal":")"}], "postprocess": 
        data => data[2]
                },
    {"name": "reference_path$ebnf$1", "symbols": []},
    {"name": "reference_path$ebnf$1$subexpression$1", "symbols": [{"literal":"."}, "prop_access"]},
    {"name": "reference_path$ebnf$1", "symbols": ["reference_path$ebnf$1", "reference_path$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "reference_path", "symbols": ["prop_access", "reference_path$ebnf$1"], "postprocess": 
        d => ({
                type: "reference_path",
                path: d[0].segment.concat(d[1].map(item=>item[1].segment).flat()),
                start: d[0].start,
                //ws: d[0].ws
            })
            },
    {"name": "prop_access$ebnf$1", "symbols": []},
    {"name": "prop_access$ebnf$1$subexpression$1", "symbols": ["indexed_access"]},
    {"name": "prop_access$ebnf$1$subexpression$1", "symbols": ["argument_list"]},
    {"name": "prop_access$ebnf$1", "symbols": ["prop_access$ebnf$1", "prop_access$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "prop_access", "symbols": ["identifier", "prop_access$ebnf$1"], "postprocess": 
        d => ({
            //segment: [d[0], ...d[1].map(item=>item[0])],
            segment: [d[0], ...d[1].map(item=>item[0])],
            start: d[0].start,
            //ws: d[1]
        })
            },
    {"name": "argument_list", "symbols": [{"literal":"("}, "_ml", {"literal":")"}], "postprocess": () => ({ type: "arglist", args: [] })},
    {"name": "argument_list$ebnf$1", "symbols": []},
    {"name": "argument_list$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "_ml", "expression", "_ml"]},
    {"name": "argument_list$ebnf$1", "symbols": ["argument_list$ebnf$1", "argument_list$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "argument_list", "symbols": [{"literal":"("}, "_ml", "expression", "_ml", "argument_list$ebnf$1", {"literal":")"}], "postprocess": 
        d => ({ 
            type: "arglist",
            args: [d[2], ...d[4].map(item=>item[2])]
        })
                },
    {"name": "indexed_access", "symbols": [{"literal":"["}, "_", "expression", "_", {"literal":"]"}], "postprocess": 
        d => ({
            type: "indexed_access",
            startIndex: d[2],
            isRange: false
        })
            },
    {"name": "indexed_access", "symbols": [{"literal":"["}, "_", "expression", "_", {"literal":":"}, "_", "expression", "_", {"literal":"]"}], "postprocess": 
        d => ({
            type: "indexed_access",
            startIndex: d[2],
            endIndex: d[6],
            isRange: true
        })
            },
    {"name": "indexed_access", "symbols": [{"literal":"["}, "_", "expression", "_", {"literal":":"}, "_", {"literal":"]"}], "postprocess": 
        d => ({
            type: "indexed_access",
            startIndex: d[2],
            isRange: true
        })
            },
    {"name": "indexed_access", "symbols": [{"literal":"["}, "_", {"literal":":"}, "_", "expression", "_", {"literal":"]"}], "postprocess": 
        d => ({
            type: "indexed_access",
            endIndex: d[4], 
            isRange: true
        })
            },
    {"name": "boolean_literal", "symbols": [{"literal":"True"}], "postprocess": 
        d => ({
            type: "boolean_literal",
            value: true,
            start: tokenStart(d[0]),
            end: tokenEnd(d[0])
        })
                },
    {"name": "boolean_literal", "symbols": [{"literal":"False"}], "postprocess": 
        d => ({
            type: "boolean_literal",
            value: false,
            start: tokenStart(d[0]),
            end: tokenEnd(d[0])
        })
                },
    {"name": "object_literal", "symbols": [{"literal":"{"}, "object_entries", {"literal":"}"}], "postprocess": 
        d => ({
            type: "object_literal",
            entries: d[1],
            start: tokenStart(d[0]),
            end: tokenEnd(d[2])
        })
                },
    {"name": "object_entries", "symbols": [], "postprocess": () => []},
    {"name": "object_entries", "symbols": ["_ml", "object_entry", "_ml"], "postprocess": 
        d => [d[1]]
                },
    {"name": "object_entries", "symbols": ["_ml", "object_entry", "_ml", {"literal":","}, "object_entries"], "postprocess": 
        d => [d[1], ...d[4]]
                },
    {"name": "object_entry", "symbols": ["identifier", "_ml", {"literal":":"}, "_ml", "expression"], "postprocess": 
        d => [d[0], d[4]]
                },
    {"name": "list_literal", "symbols": [{"literal":"["}, "list_items", {"literal":"]"}], "postprocess": 
        d => ({
            type: "list_literal",
            items: d[1],
            start: tokenStart(d[0]),
            end: tokenEnd(d[2])
        })
                },
    {"name": "list_items", "symbols": [], "postprocess": () => []},
    {"name": "list_items", "symbols": ["_ml", "expression", "_ml"], "postprocess": d => [d[1]]},
    {"name": "list_items", "symbols": ["_ml", "expression", "_ml", {"literal":","}, "list_items"], "postprocess": 
        d => [
            d[1],
            ...d[4]
        ]
                },
    {"name": "function_definition", "symbols": [{"literal":"def"}, "__", "identifier", "_", {"literal":"("}, "_", "parameter_list", "_", {"literal":")"}, "_", {"literal":":"}, "_ml", "code_block"], "postprocess": 
        d => ({
            type: "function_definition",
            name: d[2],
            parameters: d[6],
            body: d[12],
            start: tokenStart(d[0]),
            end: d[12].end
        })
                },
    {"name": "parameter_list", "symbols": [], "postprocess": () => []},
    {"name": "parameter_list", "symbols": ["identifier"], "postprocess": d => [d[0]]},
    {"name": "parameter_list", "symbols": ["identifier", "_ml", {"literal":","}, "_ml", "parameter_list"], "postprocess": 
        d => [d[0], ...d[4]]
                },
    {"name": "return_statement", "symbols": [{"literal":"return"}, "__", "expression"], "postprocess": 
        d => ({
            type: "return_statement",
            value: d[2],
            start: tokenStart(d[0]),
            end: d[2].end
        })
               },
    {"name": "return_statement", "symbols": [{"literal":"return"}], "postprocess": 
        d => ({
            type: "return_statement",
            start: tokenStart(d[0]),
            end: d[0].end
        })
               },
    {"name": "statements", "symbols": ["statement"], "postprocess": 
        d => [d[0]]
                },
    {"name": "statements", "symbols": ["statement", "_", {"literal":"\n"}, "_", "statements"], "postprocess": 
        d => [
            d[0],
            ...d[4]
        ]
                },
    {"name": "statements", "symbols": ["_", {"literal":"\n"}, "_", "statements"], "postprocess": 
        d => d[3]
                },
    {"name": "statements", "symbols": ["_"], "postprocess": 
        d => []
                },
    {"name": "statement", "symbols": ["return_statement"], "postprocess": id},
    {"name": "statement", "symbols": ["var_assignment"], "postprocess": id},
    {"name": "statement", "symbols": ["if_statement"], "postprocess": id},
    {"name": "statement", "symbols": ["forever_loop"], "postprocess": id},
    {"name": "statement", "symbols": ["repeat_counted_loop"], "postprocess": id},
    {"name": "statement", "symbols": ["repeat_until_loop"], "postprocess": id},
    {"name": "statement", "symbols": ["for_in_loop"], "postprocess": id},
    {"name": "statement", "symbols": ["break_statement"], "postprocess": id},
    {"name": "statement", "symbols": ["continue_statement"], "postprocess": id},
    {"name": "statement", "symbols": ["wrong_var_assignment"], "postprocess": id},
    {"name": "statement", "symbols": ["reference_path"], "postprocess": id},
    {"name": "statement", "symbols": ["function_definition"], "postprocess": id},
    {"name": "statement", "symbols": ["class_definition"], "postprocess": id},
    {"name": "code_block", "symbols": [{"literal":"{"}, "_", "statements", "_", {"literal":"}"}], "postprocess": 
        (d) => ({
            type: "code_block",
            statements: d[2],
            start: tokenStart(d[0]),
            end: tokenEnd(d[4])
        })
            },
    {"name": "var_assignment", "symbols": ["reference_path", "_", "asst_operator", "_", "expression"], "postprocess": 
        d => ({
            type: "var_assignment",
            var_name: d[0],
            op: d[2],
            value: d[4],
            start: d[0].start,
            end: d[4].end
        })
                },
    {"name": "asst_operator", "symbols": [{"literal":"="}]},
    {"name": "asst_operator", "symbols": [{"literal":"+="}]},
    {"name": "asst_operator", "symbols": [{"literal":"-="}]},
    {"name": "asst_operator", "symbols": [{"literal":"*="}]},
    {"name": "asst_operator", "symbols": [{"literal":"/="}]},
    {"name": "wrong_var_assignment$ebnf$1", "symbols": []},
    {"name": "wrong_var_assignment$ebnf$1$subexpression$1", "symbols": ["identifier", /[.]/]},
    {"name": "wrong_var_assignment$ebnf$1", "symbols": ["wrong_var_assignment$ebnf$1", "wrong_var_assignment$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "wrong_var_assignment", "symbols": ["wrong_var_assignment$ebnf$1", "identifier", "_", {"literal":"=="}, "_", "expression"], "postprocess": 
        d => ({
            type: "wrong_var_assignment",
            var_name: d[1],
            value: d[5],
            start: d[1].start,
            end: d[5].end
        })
                },
    {"name": "string_literal", "symbols": [(lexer.has("string_literal") ? {type: "string_literal"} : string_literal)], "postprocess":  
        d => {
            let matches = d[0].value.match(/(?<={).+?(?=})/g);
            if (matches === null)
                matches = [];
        
            return {
                value: d[0].value,
                type: d[0].type,
                matches,
                start: tokenStart(d[0]),
                end: tokenEnd(d[0])
            }
        }
            },
    {"name": "str_content", "symbols": [/[a-z]/]},
    {"name": "number", "symbols": [(lexer.has("number_literal") ? {type: "number_literal"} : number_literal)], "postprocess": convertTokenId},
    {"name": "identifier", "symbols": [(lexer.has("identifier") ? {type: "identifier"} : identifier)], "postprocess": convertTokenId},
    {"name": "_ml$ebnf$1", "symbols": []},
    {"name": "_ml$ebnf$1", "symbols": ["_ml$ebnf$1", "multi_line_ws_char"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_ml", "symbols": ["_ml$ebnf$1"]},
    {"name": "multi_line_ws_char", "symbols": [(lexer.has("ws") ? {type: "ws"} : ws)]},
    {"name": "multi_line_ws_char", "symbols": [{"literal":"\n"}]},
    {"name": "__$ebnf$1", "symbols": [(lexer.has("ws") ? {type: "ws"} : ws)]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", (lexer.has("ws") ? {type: "ws"} : ws)], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"]},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", (lexer.has("ws") ? {type: "ws"} : ws)], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"]},
    {"name": "repeat_counted_loop", "symbols": [{"literal":"repeat"}, "__", "expression", {"literal":":"}, "_ml", "code_block"], "postprocess": 
        d => ({
            type: "repeat_counted_loop",
            num_iterations: d[2],
            body: d[5],
            start: tokenStart(d[0]),
            end: d[5].end
        })
                },
    {"name": "repeat_until_loop", "symbols": [{"literal":"repeat"}, "__", {"literal":"until"}, "__", "expression", {"literal":":"}, "_ml", "code_block"], "postprocess": 
        d => ({
            type: "repeat_until_loop",
            condition: d[4],
            body: d[7],
            start: tokenStart(d[0]),
            end: d[7].end
        })
                },
    {"name": "forever_loop", "symbols": [{"literal":"forever"}, {"literal":":"}, "_ml", "code_block"], "postprocess": 
        d => ({
            type: "forever_loop",
            body: d[3],
            start: tokenStart(d[0]),
            end: d[3].end
        })
                },
    {"name": "for_in_loop", "symbols": [{"literal":"for"}, "__", "identifier", "__", {"literal":"in"}, "__", "expression", {"literal":":"}, "_ml", "code_block"], "postprocess": 
        d => ({
            type: "for_in_loop",
            item_id: d[2],
            list_expr: d[6],
            body: d[9],
            start: tokenStart(d[0])
        })
            },
    {"name": "break_statement", "symbols": [{"literal":"break"}], "postprocess": 
        d => ({
            type: "break_statement",
            start: tokenStart(d[0]),
            end: d[0].end
        })
               },
    {"name": "continue_statement", "symbols": [{"literal":"continue"}], "postprocess": 
        d => ({
            type: "continue_statement",
            start: tokenStart(d[0]),
            end: d[0].end
        })
               }
]
  , ParserStart: "input"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
