import Phaser from 'phaser';
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import GameObject from './game-object';
import phaser from "../phaser";

export default class Sprite extends GameObject {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Creates a new Sprite at the given coordinates. 
        @number x
        @number y
        @string path The path to the image that the sprite should use. It should be an absolute path beginning with /.
    `]
    constructor(x=20, y=20, path="") {
        super();
        this.setVelocityX = this.setVelocityX.bind(this);
        this.setVelocityY = this.setVelocityY.bind(this);
        this.getVelocityX = this.getVelocityX.bind(this);
        this.getVelocityY = this.getVelocityY.bind(this);
        this.$velocityX = 0;
        this.$velocityY = 0;
        this.$path = path;
        this.$phaserObject = new Phaser.Physics.Arcade.Sprite(phaser.getScene(), x, y, "/root"+path);
        this.$allowsGravity = true;
    }

    static $propdoc_image = "The absolute path to the image that the sprite should use.";
    get image() { return createBuiltInTypeObject(this.$path) };
    set image(path) {
        this.$path = path;
        this.$phaserObject.setTexture("/root"+path.value);
    }

    static $_setVelocityX = [NO_BLOCK, NO_RETURN, 
    `Sets the sprite's horizontal velocity. 
        @number velocity
    `];
    setVelocityX(velocity) {
        this.$velocityX = velocity;
        if (this.$phaserObject.displayList !== null) 
            this.$phaserObject.setVelocityX(velocity);
    } 

    static $_setVelocityY = [NO_BLOCK, NO_RETURN, 
    `Sets the sprite's vertical velocity.
        @number velocity
    `];
    setVelocityY(velocity) {
        this.$velocityY = velocity;
        if (this.$phaserObject.displayList !== null) 
            this.$phaserObject.setVelocityY(velocity);
    } 

    $handleAddToScene() {
        this.$phaserObject.setVelocityX(this.$velocityX);
        this.$phaserObject.setVelocityY(this.$velocityY);
    } 

    static $_getVelocityX = [NO_BLOCK, NO_RETURN, 
    `---. 
    `];
    getVelocityX() {
        if (this.$phaserObject.displayList !== null) 
            return this.$phaserObject.body.velocity.x;
        return 0;
    } 

    static $_getVelocityY = [NO_BLOCK, NO_RETURN, 
    `---. 
    `];
    getVelocityY() {
        if (this.$phaserObject.displayList !== null) 
            return this.$phaserObject.body.velocity.y;
        return 0;
    } 
}