import { createElement, appendChildById, setHTMLById } from "../dom-helpers";
import './test-results.css';

let latestResults = null;

export const getLatestResults = () => latestResults;
export const clearTestResults = () => { latestResults = null; }

export const render = (structuralResults, runtimeResults, target) => {
    const renderDetails = (data, earlyTermination, error) => {
        const table = createElement("table", "", { className: "test-result-details-table" });
        table.innerHTML = `
            <thead>
                <tr>
                    <th>Expected</th>
                    <th>Received</th>
                    <th>Pass/Fail</th>
                </tr>
            </thead>
            <tbody>
            </tbody>`;
        const tbody = table.querySelector("tbody");
        
        const appendTd = (tr, text) => tr.appendChild(createElement("td", text));
        data.forEach(test => {
            const tr = createElement("tr");
            appendTd(tr, test.expected);
            appendTd(tr, test.received);
            appendTd(tr, test.pass ? "Pass" : "Fail");
            tbody.appendChild(tr);
        })

        const details = createElement("div");
        details.appendChild(table);
        if (earlyTermination) {
            details.appendChild(createElement(
                "p", 
                "Program ended before all required input and output was received.",
                { className: "test-result-early-termination-message"}
            ));
        }
        if (error) {
            details.appendChild(createElement(
                "p", 
                "The following error occurred while running this test case:",
                { className: "test-result-early-termination-message"}
            ));
            details.appendChild(createElement("p", error.message));
        }

        return details;
    }
    
    const renderResult = result => {
        const tr = createElement("tr");
        tr.appendChild(createElement(
            "td", 
            result.result ? "<span class='test-result-pass'>PASS</span>" : "<span class='test-result-fail'>FAIL</span>", 
            { className: "test-result test-result-pass-fail" }));

        const td = createElement("td", "", { className: "test-result test-result-description" });
        if (result.details) {
            const details = createElement("details");
            const summary = createElement("summary", result.name);
            details.appendChild(summary);
            details.append(renderDetails(result.details, result.earlyTermination, result.error));
            td.appendChild(details);
        }
        else {
            td.insertAdjacentHTML("beforeend", "&#x25CF; "+result.name);
        }
        tr.appendChild(td);


        appendChildById(target+"-table-body", tr);
    }

    setHTMLById(target, `
        <table class='test-results-table'>
            <tbody id='${target}-table-body'>
            </tbody>
        </table>
    `);
    structuralResults && structuralResults.forEach(result => {
        renderResult(result);
    });
    runtimeResults && runtimeResults.forEach(result => {
        renderResult(result);
    });
    
    latestResults = { structuralResults, runtimeResults };
}