import Konva from "konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import KonvaShape from "./konva-shape";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("konva-api.polygon."+id, args);
}

export default class Polygon extends KonvaShape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Create a new _Polygon_ object, with the specified initial properties.
        @list points A list of points in the format [x1, y1, x2, y2, x3, y3, ...]
        @any color
    ` ]
    constructor(points=[0, 0, 100, 0, 0, 100], color="black") {
        super();
        this.$validatePointsArray(points, "polygon");
        points = points.map(point => point.value);
        const x = points[0];
        const y = points[1];
        let data = `M${points[0]-x},${points[1]-y}`;
        for (let i = 2; i < points.length; i += 2) {
            data += `,L${points[i]-x},${points[i+1]-y}`;
        }
        data += "Z";
        const xPoints = points.filter((value, i) => i % 2 === 0);
        const yPoints = points.filter((value, i) => i % 2 === 1);
        this.$boundsRelativeToXY = { 
            top: Math.min(...yPoints)-y, 
            bottom: Math.max(...yPoints)-y, 
            left: Math.min(...xPoints)-x, 
            right: Math.max(...xPoints)-x 
        }
        this.$createShape(Konva.Path, color, { data });
        this.$shape.x(x);
        this.$shape.y(y);
    }

    static $propdoc_points = "A list of points in the format [x1, y1, x2, y2, x3, y3, ...]"
    set points(pnList) { 
        const points = pnList.value.map(point => point.value);
        this.$shape.points(points);
    } 
    get points() { 
        return createBuiltInTypeObject(this.$shape.points());
    }


}