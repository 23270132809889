import PnObject from "../../common-api/types/object";
import { getSymbol, getCurScope, isInTopLevelScope } from "./model/symbol-tables.js";
import * as structure from "./model/structure";
import { evaluateExpression } from "./expression-evaluator.js";
import { executeCodeBlock } from "./execution";
import { createObject, createBuiltInTypeObject, getMethodMeta, getMethodMetaFromClassRef } from "../api-helpers";
import { setCurLocation } from "../interpreter.js";
import PnError from "../pn-error";
import * as state from "./model/state.js";

const reportError = (id, args) => { 
    throw new PnError("interpreter.execution.reference-path-evaluator."+id, args);
}

export async function evalReferencePath(expr, allowUndefinedResult=false, returnNullOnNonDataResult=false) {
    let curNode;
        // `curNode` is an object with 2 properties: `type` and `payload`, representing the resolved
        // value at the current node of the path.
        //
        // `curNode.type` can be "pn-function", "js-function", "pn-class", "js-class" or "data"
        //
        // `curNode.payload` will be dependent on the type, as follows:
        //      - pn-function - object w/ props:
        //          - name - the name of the function
        //          - params - array of parameter names
        //          - stmts - array of statements in the body
        //          - obj - the object the function is a method of (undefined if none)
        //      - js-function: object w/ props:
        //          - meta - meta data, as returned by api-helpers.getMethodMeta
        //          - ref - a reference to the JS Function
        //      - pn-class: a referent to the PN Class
        //      - js-class: object w/ props:
        //          - jsClass: a reference to the JS Class
        //          - meta: meta data from the class's constructor, null if no constructor
        //      - data: a reference to the data object

    for (let i = 0; i < expr.path.length; i++) {
        const pathNode = expr.path[i];
        switch (pathNode.type) {
            case "identifier": curNode = processIdentifier(
                pathNode.value, 
                curNode ? curNode.payload : undefined,
                allowUndefinedResult && i === expr.path.length-1); break;
            case "arglist": curNode = await processArgList(expr, curNode, pathNode.args); break;
            case "indexed_access": curNode = await processIndexedAccess(pathNode, curNode); break;
            default: throw new Error("Invalid path node type: "+pathNode.type)
        }

        (curNode === undefined || curNode.payload === undefined) && !allowUndefinedResult && 
            reportError("ref_path_evaluates_to_undefined");
    }

    if (curNode && ['pn-function', 'js-function', 'pn-class', 'js-class'].includes(curNode.type)) {
        if (returnNullOnNonDataResult)
            return null;
        reportError("ref_path_resolves_to_class_or_function");
    }
        

    return curNode ? curNode.payload : undefined;
}

const processIdentifier = (name, containingObj, allowUndefinedResult) => {
    // console.log({name})
    // console.log(containingObj);

    const getConstructorMeta = classRef => {
        return getMethodMetaFromClassRef(classRef, "constructor");
    }

    // An independent identifier is not a property or method attached to an object
    const processIndependentId = name => {

        // First check for the "self" keyword
        if (name === "self") {
            isInTopLevelScope() && reportError("self_referenced_in_top_level_scope");
            // console.log(getCurScope())
            return { type: "data", payload: getCurScope().obj }
        }
            
        // Next check in the symbol table
        const value = getSymbol(name);
        if (value !== undefined) return { type: "data", payload: value };

        // Next check for a user defined function
        const pnFunction = structure.getTopLevelDef(name);
        if (pnFunction) return { type: "pn-function", payload: { name, ...pnFunction } };

        // Next check for a function defined in the api
        const jsFunction = structure.getApiFunction(name);
        if (jsFunction) return { type: "js-function", payload: jsFunction };

        // Next check for a user defined class
        const pnClass = structure.getClasses()[name];
        if (pnClass) return { type: "pn-class", payload: pnClass };

        // Finally, check for an API defined class
        const jsClass = structure.getApiClass(name);
        if (jsClass) return { type: "js-class", payload: { classRef: jsClass, meta: getConstructorMeta(jsClass) } };
        
        !allowUndefinedResult && reportError("undefined_independent_id", { name });
    }

    const processIdOfObject = (name, containingObj) => {
        if (typeof containingObj !== "object") throw new Error("Containing object is not an object");

        // Check for a user defined method
        if (containingObj.$pnClassHierarchy) { 
            for (let classRef of containingObj.$pnClassHierarchy) {
                const classDefs = classRef.defs;
                if (name in classDefs) {
                    return { 
                        type: "pn-function",
                        payload: { ...classDefs[name], obj: containingObj, name }
                    }
                }
            }            
        }

        // Check for a property or api method
        if (name in containingObj) {
            if (typeof containingObj[name] === "function") { // it's an api method
                const ref = containingObj[name];
                return { 
                    type: "js-function", 
                    payload: {ref, meta: getMethodMeta(containingObj, name) }
                }
            }
            return { type: "data", payload: containingObj[name] } // it's a property
        }
        !allowUndefinedResult && reportError("undefined_prop_or_method", { name });
    }

    if (containingObj)
        return processIdOfObject(name, containingObj);
    else
        return processIndependentId(name);
}

const processArgList = async (expr, curNode, argList) => {

    const validateArgs = (meta, given) => {
        const { lastParamCanRepeat, passFullObjectForLiterals } = meta;

        // The generator function will return the next expected parameter, or null if no more
        // expected. This is useful for when the last parameter is permitted to repeat, as the
        // last parameter in this case will continuously be returned by the generator.
        const expCopy = [...meta.params];
        const getExpectedGenerator = function*()  {
            while (true) {
                if (expCopy.length === 0)
                    yield null;
                else {
                    yield expCopy[0];
                    const onLastParam = expCopy.length === 1;
                    const noShift = onLastParam && lastParamCanRepeat;
                    if (!noShift)
                        expCopy.shift();
                }
            }
        }
        const getExpected = getExpectedGenerator();

        for (let i = 0; i < given.length; i++) {
            const curExp = getExpected.next().value;
            const curGiven = passFullObjectForLiterals ? given[i].value : given[i];
            curExp === null && reportError("invalid_num_args", { 
                fn: meta.methodName, 
                given: given.length, 
                expected: meta.params.length 
            });
            if (curExp.type === "any")
                continue;
            if (curExp.type === "list" && Array.isArray(curGiven))
                continue;
            if (curExp.type !== typeof curGiven)
                reportError("invalid_arg_type", { 
                    fn: meta.methodName, 
                    argNum: i+1, 
                    expected: curExp.type, 
                    givenType: typeof curGiven,
                    givenValue: curGiven
            });
        }

        const curExp = getExpected.next().value;
        curExp !== null && !curExp.optional && reportError("invalid_num_args", { 
            fn: meta.methodName, 
            given: given.length, 
            expected: meta.params.length });
    }

    const processJsFunction = async ({ meta, ref }) => {
        validateArgs(meta, resolvedArgValues);
        let returnValue;
        if (meta.blocks) 
            returnValue = await callBlockingFunction(ref, resolvedArgValues);
        else 
            returnValue = ref(...resolvedArgValues);
        
        if (returnValue === undefined)
            return undefined;
        if (typeof returnValue === "object" && !Array.isArray(returnValue))
            return returnValue;
        return createBuiltInTypeObject(returnValue);
    }

    const processPnFunction = async ({ name, params, stmts, obj }) => {
        resolvedArgValues.length !== params.length && reportError("invalid_num_args", { 
            fn: name,
            expected: params.length,
            given: resolvedArgValues.length
        });
        const args = {};
        for (let i = 0; i < params.length; i++) {
            args[params[i]] = resolvedArgValues[i];
        }

        const result = await executeCodeBlock({ 
            type: "def", 
            stmts: stmts,
            args, obj
        });
        return result.returnValue;
    }

    const processPnObjectInstantiation = async (pnClass) => {
        // Calculate the class hierarchy
        const hier = [pnClass];
        let search = pnClass;
        while (search.superType === "pn-class") {
            search = search.superRef;
            hier.push(search)
        }

        const JsClass = search.superType === "js-class" ? search.superRef : PnObject;
        const newObj = await createObject(JsClass, [], hier);

        // Check for a constructor
        const constructorDef = structure.getPnClassDef(pnClass.name, "constructor");

        // A user defined constructor will return "object" here. Otherwise, "function" will be returned due
        // to the underlying JavaScript object's constructor
        if (typeof constructorDef === "object") {       
            await processPnFunction({                   
                ...constructorDef,
                obj: newObj,
                name: "constructor"
            }); 
        }
        return newObj;
    }

    const processJsObjectInstantiation = async ({classRef, meta}) => {
        if (meta === null) {
            resolvedArgValues.length > 0 && reportError("args_given_for_no_constructor");
        }
        else {
            validateArgs(meta, resolvedArgValues);
        }
        const newObj = await createObject(classRef, resolvedArgValues, []);
        return newObj;
    }


    // Evaluate the arguments
    const { meta } = curNode.payload;
    const resolvedArgValues = [];
    for (let arg of argList) {
        arg = await evaluateExpression(arg);
        if (meta && !meta.passFullObjectForLiterals && (arg.$typeCategory === "literal" || arg.$typeCategory === "list"))
            arg = arg.value;
        resolvedArgValues.push(arg);
    }

    let result;
    switch (curNode.type) {
        case "js-function": result = await processJsFunction(curNode.payload); break;
        case "pn-function": result = await processPnFunction(curNode.payload); break;
        case "pn-class": result = await processPnObjectInstantiation(curNode.payload); break;
        case "js-class": result = await processJsObjectInstantiation(curNode.payload); break;
        default: reportError("misplaced_arg_list");
    }
    setCurLocation(expr);
    return {type: "data", payload: result }
}

function callBlockingFunction(fn, args) {

    let rejectFn;
    setTimeout(() => {
        try {
            fn(...args)
        }
        catch (err) {
            rejectFn(err);
        }
    }, 0)

    return new Promise((resolve, reject) => { 
        state.setResolveCallback(value => resolve(value));
        rejectFn = reject;
    });
}

const processIndexedAccess = async (pathNode, curNode) => {
    const isRange = pathNode.isRange;
    const resolvedStart = "startIndex" in pathNode ? await evaluateExpression(pathNode.startIndex) : null;
    const resolvedEnd = "endIndex" in pathNode ? await evaluateExpression(pathNode.endIndex) : null;
    const result = curNode.payload.$index(isRange, resolvedStart, resolvedEnd);
    return {type: "data", payload: result}
}