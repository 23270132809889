import { preprocess } from "./preprocessor.js";
import { setCurLocation } from "../interpreter";
import nearley from "nearley";
import PnError from "../pn-error";
let grammar = require("./generated-grammar.cjs");

const reportError = (id, args, location) => { 
    throw new PnError("interpreter.parser.parser."+id, args, location);
}

export const parse = (code, filename="") => {
    code = preprocess(code, filename);
    let ast;

    // For some reason lines containing only whitespace slow down the parser, so this replaces those
    // lines with an empty string
    code = code
        .split('\n')
        .map(line => line.trim() === '' ? '' : line)
        .join('\n');

    if (window && window.grammar)        
        grammar = window.grammar; // It ends up here in production
    const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));

    try {
        parser.feed(code);
        ast = parser.results[0];
        if (parser.results.length === 0) {
            reportError("unfinished_statement", {}, { line: "unknown", filename });
        }
    }
    catch (e) {
        //throw(e);
        if (!e.token)
            throw e;
        switch (e.token.type) {
            case 'parseError': throw e; 
            case 'errorToken': reportError(
                "illegal_character", 
                { ch: e.token.text.substring(0, 1) }, 
                { filename, line: e.token.line }); 
                break;
            default: reportError("parse_error", {}, { filename, line: e.token.line });
        }
    }
    return ast;
}

export const parseStringLiteral = (node) => {
    node.expressions = [];
    for (const match of node.matches) {
        try {
            const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
            parser.feed("x="+match.trim());
            const ast = parser.results[0];
            if (parser.results.length === 0)  {
                reportError("invalid_expression_within_string", { expr: match });
            }
            ast[0].value.start = { ...node.start };
            node.expressions.push(ast[0].value);
        }
        catch (e) {
            if (!e.token)
                throw e;
            else
                reportError("invalid_expression_within_string", { expr: match });
        }
    }
}