let state;
export const init = () => {
    state = {
        resolveCallback: null,
        curLoopAbortionType: "none", // can be "none", "break", "continue"
    }
}

export const abortLoop = (type) => {
    console.assert(type === "break" || type === "continue");
    state.curLoopAbortionType = type;
}

export const getAndClearAbortLoopStatus = () => {
    const type = state.curLoopAbortionType;
    state.curLoopAbortionType = "none";
    return type;
}

export const abortCodeBlockRequested = () => {
    return state.curLoopAbortionType !== "none";
}

export const setResolveCallback = (cb) => state.resolveCallback = cb;
export const getResolveCallback = () => state.resolveCallback;

