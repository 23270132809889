import * as interpreter from "../../interpreter/interpreter";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import PnObject from "../../common-api/types/object";
import phaser from "../phaser";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("host-phaser.phaser-api.game-object."+id, args);
}

let nextGameObjectId = 1;

export default class GameObject extends PnObject {

    constructor() {
        super();
        this.sendMessage = this.sendMessage.bind(this);
        this.destroy = this.destroy.bind(this);
        this.addToGroup = this.addToGroup.bind(this);
        this.setStationary = this.setStationary.bind(this);
        this.destroyIfOffScreen = this.destroyIfOffScreen.bind(this);
        this.allowGravity = this.allowGravity.bind(this);
        this.setScroll = this.setScroll.bind(this);
        this.$goId = nextGameObjectId;
        this.$curScene = null; // The scene the game object is currently added to
        nextGameObjectId++;
        this.$isPnGameObject = true;
        this.$physicsGroups = [];
        this.$directAccessors = ['x', 'y', 'width', 'height', 'angle', 'scale', 'scaleX', 'scaleY', 'alpha', 'flipX', 'flipY'];
        for (let name of this.$directAccessors) {
            this.$addProp(name, 
                (pnValue) => { this.$phaserObject[name] = pnValue.value },
                () => createBuiltInTypeObject(this.$phaserObject[name])
            )
        }
        this.$isStationary = false;
        this.$allowsGravity = false;
        this.$scrolls = true;
    }

    static $propdoc_x = "The object's x position";
    static $propdoc_y = "The object's y position";
    static $propdoc_width = "The object's width";
    static $propdoc_height= "The object's height";
    static $propdoc_scale = "The object's scale. A value of 1 is the original size, a value between 0 and 1 is smaller, a value greater than 1 is larger.";
    static $propdoc_scaleX = "Similar to the *scale* property, but only scales horizontally.";
    static $propdoc_scaleY = "Similar to the *scale* property, but only scales vertically.";
    static $propdoc_alpha = "The object's transparency. Valid values are between 0 and 1, where 0 is fully transparent and 1 is fully opaque.";

    $addDirectAccessors(arr) {
        for (let name of arr) {
            this.$addProp(name, 
                (pnValue) => { this.$phaserObject[name] = pnValue.value },
                () => createBuiltInTypeObject(this.$phaserObject[name])
            )
        }
    }

    $addProp(name, setter, getter) {
        Object.defineProperty(this, name, {
            get() { return getter() },
            set(value) { setter(value) }
        })
    }

    static $_sendMessage = [NO_BLOCK, NO_RETURN, 
    `Sends a message to this game object after the given time delay. The message can be retrieved as an event from the game object's *update* method.
        @string message
        @number timeDelay
    `];
    sendMessage(message, timeDelay) {  
        setTimeout(() => phaser.addMessageEvent(this.$goId, message), timeDelay)
    }

    static $_destroy = [NO_BLOCK, NO_RETURN, 
    `Destroys this game object, fully removing it from the game. 
    `];
    destroy() {  
        this.$phaserObject.destroy();
        if (this.$curScene !== null) {
            this.$curScene.$removeGameObject(this.$goId);
        }
        phaser.removeGameObject(this.$goId);
    }

    static $_destroyIfOffScreen = [NO_BLOCK, NO_RETURN, 
    `Destroys this game object if it is off the screen. This is generally called in the *update* method of a game object such as a projectile that should be destroyed once it leaves the screen. Fogetting to use this method can cause performance issues if there are too many game objects in the scene at a time. 
    `];
    destroyIfOffScreen() {  
        const po = this.$phaserObject;
        const offscreen = po.x > 700 || po.x+po.width < -50 || po.y > 450 || po.y+po.height < -50;
        if (offscreen)
            this.destroy();
    }
    
    static $_addToGroup = [NO_BLOCK, NO_RETURN, 
    `Add this game object to the given group. Groups are used for collision detection.
        @string groupName
    `];
    addToGroup(groupName) {  
        this.$physicsGroups.push(groupName);
        phaser.addToGroup(this.$phaserObject, groupName);
    }

    static $_setStationary = [NO_BLOCK, NO_RETURN, 
    `If provided with a parameter of *True*, it will prevent this object from being pushed by another object in the event of a collision. A parameter of *False* will allow this object to be pushed.
        @boolean isStationary
    `];
    setStationary(isStationary) {  
        this.$isStationary = isStationary;
        if (this.$phaserObject.body) 
            this.$phaserObject.body.setImmovable(isStationary);
    }

    static $_allowGravity = [NO_BLOCK, NO_RETURN,
    `Specify if this game object is affected by gravity.
        @boolean isAffected If *True*, the game object will be affect by gravity, if *False* it will not.
    `
    ]
    allowGravity(isAffected) {
        this.$allowsGravity = isAffected;
        if (this.$phaserObject.body) 
            this.$phaserObject.body.setAllowGravity(isAffected);
    }

    static $_setScroll = [NO_BLOCK, NO_RETURN,
    `Specify if this game object scrolls along with a scrolling background
        @boolean scrolls If *True* (default), the object will scroll with the background, if *False*, the object remains fixed.
    `]
    setScroll(scrolls) {
        this.$scrolls = scrolls;
        if (this.$phaserObject.body) 
            this.$phaserObject.setScrollFactor(scrolls ? 1 : 0);
    }

    static $_update = [NO_BLOCK, NO_RETURN, 
    `This method is available for subclasses to override. Conventionally, each object's *update* method is called on each frame update.
        @object events
    `];
    update(events) {} // for superclass to override

    $handleAddToScene() {} // for superclass to override
    

}