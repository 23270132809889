import { executeCodeBlock } from "./execution";
import { evaluateExpression } from "./expression-evaluator";
import { evalReferencePath } from "./reference-path-evaluator";
import { createBuiltInTypeObject } from "./../api-helpers";
import PnError from "../pn-error";
import { setSymbol, getSymbol } from "./model/symbol-tables.js";

const reportError = (id, args) => { 
    throw new PnError("interpreter.execution.statement-processor."+id, args);
}

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export async function processStatement(stmt, curBlockStmts) {

    switch (stmt.type) {
        case "var_assignment": return await processAssignmentStatement(stmt); 
        case "wrong_var_assignment": reportError('wrong_var_assignment');
        case "reference_path": return await processReferencePathStatement(stmt); 
        case "forever_loop": return await processForeverLoop(stmt, curBlockStmts);
        case "repeat_counted_loop": return await processRepeatCountedLoop(stmt, curBlockStmts);
        case "repeat_until_loop": return await processRepeatUntilLoop(stmt, curBlockStmts);
        case "for_in_loop": return await processForInLoop(stmt, curBlockStmts);
        case "break_statement": return { abortSignal: "break" };
        case "continue_statement": return { abortSignal: "continue" };
        case "return_statement": return await processReturnStatement(stmt); 
        case "if_statement": return await processIfStatement(stmt);
        default: throw new Error("Invalid statement type: "+stmt.type);
    }
}

async function processAssignmentStatement(stmt) {
    const path = stmt.var_name.path;

    // If the path length is greater than 1, it means we are assigning a property to an object or reassigning a
    // list itme as opposed to updating the symbol table
    const pathEnd = path[path.length-1];
    let asstType = "symbolTableSet";
    if (path.length > 1) {
        if (pathEnd.type === "identifier") {
            asstType = "objPropSet";
        }
        else {
            asstType = "listElementSet";
            pathEnd.isRange === true && reportError("assign_value_to_list_range");
        }
    }
       
    const symbol = pathEnd.value;
    const rightSide = await evaluateExpression(stmt.value);

    let oldValue = await evalReferencePath(stmt.var_name, true, true);
    oldValue === null && reportError("assiging_value_to_class_or_function_name", { symbol });
    if (oldValue !== undefined)
        oldValue = oldValue.value;

    let newValue;
    if (stmt.op[0].type !== "assignment") {
        oldValue === undefined && reportError("asst_arith_op_leftside_undefined", { op: stmt.op[0].value });
        switch (stmt.op[0].type) {
            case "asst_plus": newValue = oldValue + rightSide.value; break;
            case "asst_minus": newValue = oldValue - rightSide.value; break;
            case "asst_multiply": newValue = oldValue * rightSide.value; break;
            case "asst_divide": newValue = oldValue / rightSide.value; break;
            default: throw new Error("Inavlid operator: "+stmt.op[0].type);
        }
        newValue = createBuiltInTypeObject(newValue);
    }
    else {
        newValue = rightSide;
    }

    if (asstType === "symbolTableSet") {
        setSymbol(symbol, newValue);
        return {};
    }

    // Here, we create a new "Reference Path Expression" that resolves to the second last item in the
    // path, as it is this item's property (or list index) that we need to update
    const destPath = [...path];
    destPath.pop();
    const destReferencePath = {
        ...stmt.var_name,
        path: destPath
    }

    if (asstType === "objPropSet") {
        const destObj = await evaluateExpression(destReferencePath);
        destObj[symbol] = newValue;
    }
    else if (asstType === "listElementSet") {
        const destList = await evaluateExpression(destReferencePath);
        const listIndex = await evaluateExpression(pathEnd.startIndex);
        destList.value[listIndex.value] = newValue;
    }
    else {
        throw new Error("Shouldn't be here");
    }
    return {};
}

async function processIfStatement(stmt) {
    let selectedCodeblock;
    for (let branch of stmt.branches) {
        const conditionMetRaw = await evaluateExpression(branch.condition);
        conditionMetRaw.$type !== "boolean" && reportError("if_condition_not_boolean", { type: conditionMetRaw.$type });
        const conditionMet = conditionMetRaw.value;
        if (conditionMet) {
            selectedCodeblock = branch.code_block;
            break;
        }
    }
    if (selectedCodeblock === undefined && ("else_codeblock") in stmt) {
        selectedCodeblock = stmt.else_codeblock
    }
    if (selectedCodeblock !== undefined) {
        return await executeCodeBlock({ type: "if", stmts: selectedCodeblock.statements })
    }
    return {}
}

async function processReferencePathStatement(stmt) {
    stmt.path[stmt.path.length-1].type !== "arglist" && reportError("ref_path_statement_not_fn_call");
    const result = await evalReferencePath(stmt, true);
    //result !== undefined && reportError("ref_path_statement_returned_value");
    return {}
}

function getAbortedLoopReturnValue(execResult) {
    if (execResult === "stopped" || execResult.abortSignal === "break")
        return {};
    if (execResult.abortSignal === "return")
        return execResult;
    throw "Invalid execResult";
}

async function processForeverLoop(stmt, curBlockStmts) {
    const result = await executeCodeBlock({ type: "loop", stmts: stmt.body.statements });
    if (result.abortSignal && result.abortSignal !== "continue") 
        return getAbortedLoopReturnValue(result);

    curBlockStmts.unshift(stmt);
    return {};
}

async function processRepeatCountedLoop(stmt, curBlockStmts) {
    const numIterations = await evaluateExpression(stmt.num_iterations);
    numIterations.$type !== "number" && reportError("repeat_count_wrong_type", { value: numIterations.value })
    const result = await executeCodeBlock({ type: "loop", stmts: stmt.body.statements });
    if (result.abortSignal && result.abortSignal !== "continue") 
        return getAbortedLoopReturnValue(result);

    if (numIterations.value > 1) {
        const newStmt = deepClone(stmt);
        newStmt.num_iterations.type = "number_literal";
        newStmt.num_iterations.value = numIterations.value - 1;
        curBlockStmts.unshift(newStmt);
    }
    return {};
}

async function processRepeatUntilLoop(stmt, curBlockStmts) {

    const isConditionMet = await evaluateExpression(stmt.condition);
    isConditionMet.$type !== "boolean" && reportError("repeat_until_condition_wrong_type", { type: isConditionMet.$type })
    if (isConditionMet.value) 
        return {};
   
    const result = await executeCodeBlock({ type: "loop", stmts: stmt.body.statements });
    if (result.abortSignal && result.abortSignal !== "continue") 
        return getAbortedLoopReturnValue(result);

    curBlockStmts.unshift(stmt);
    return {};
}

async function processForInLoop(stmt, curBlockStmts) {
   
    let curIndex = 0;
    let resolvedList;

    if (stmt.curIndex) { // we are at least on the second loop iteration
        curIndex = stmt.curIndex;
        resolvedList = stmt.resolvedList;
        if (curIndex === resolvedList.value.length) // We've reached the end 
            return {}
    }
    else { // we are on the first iteration
        resolvedList = await evaluateExpression(stmt.list_expr);
        resolvedList.$type !== "list" && reportError("for_in_loop_list_expr_wrong_type", { value: resolvedList.value });
        if (resolvedList.value.length === 0) // Do nothing for an empty list
            return {}
    }

    const itemValue = resolvedList.value[curIndex];
    const result = await executeCodeBlock({ 
        type: "loop", 
        stmts: stmt.body.statements,
        args: { [stmt.item_id.value]: itemValue }
    });
    if (result.abortSignal && result.abortSignal !== "continue") 
        return getAbortedLoopReturnValue(result);

    const newStmt = {
        ...stmt,
        curIndex: curIndex + 1,
        resolvedList
    }

    curBlockStmts.unshift(newStmt);

    return {};
}

async function processReturnStatement(stmt) {
    const returnValue = stmt.value ? await evaluateExpression(stmt.value) : undefined;
    return { returnValue, abortSignal: "return" }
}