import { layer } from "../konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";

export default class Stage  {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Creates a new _Stage_ object. You should only ever need to create one stage object, and you should 
    probably name it \`stage\`. A Stage object is only required if you are using grouping (an advanced technique)
    and have autoAdd set to False.   ` ];
    constructor() { }

    static $_add = [NO_BLOCK, NO_RETURN, 
    `Adds the given shape to the stage.
        @object shape   `];
    add(node) {  
        if (node.$shape)
            layer.add(node.$shape);
    }
    
}