import { getPathObj, sortTree, getTrashName, setCurEventState, curEventState, validateNewFilename,
    fullPathFilenameToFilename, PATH_TO_TRASH, PATH_TO_ROOT } from './util';

const deepClone = tree => JSON.parse(JSON.stringify(tree));

let stateOfFirstDownloadedFile;
let noUnsubmitStatusOfFirstDownloadedFile = false;

export const eventHandler = ({
        // Callbacks
        externalHandlers, 
        errorCallback, 
        timeoutCallback, 
        openFileCallback,

        // State getters/setters
        tree, 
        setTree,
        uiState,
        setUiState,

        // Additional parameters for internal handlers
        type,       // the event type: getTree, click, rename, etc
        phase,      // the stage of event processing: initialRequest, serverResponse, etc
        payload     // additional data required by the specific request
    }) => {

    let serverCall, onServerResponse;
    const treeCopy = deepClone(tree);        

    const handlers = {
        getTree: {
            initialRequest: () => {
                const getHidden  = window.location.search.indexOf("show-hidden") > -1;
                serverCall("getTree", { projectId: payload.projectId, getHidden });
                setUiState("waiting");
            },
            serverResponse: (treeFromServer) => {
                console.log(treeFromServer);
                sortTree(treeFromServer);
                setTree(treeFromServer);

                // Find the file to open, and simulate a click on it
                let pathToOpen = null;
                if (payload.fileToOpen !== null) {
                    pathToOpen = PATH_TO_ROOT+payload.fileToOpen;
                    stateOfFirstDownloadedFile = payload.stateOfFileToOpen;
                }
                else {
                    // Choose the first file in the root directory by default
                    for (let entry of treeFromServer.children) {
                        if (entry.type === "file") {
                            pathToOpen = PATH_TO_ROOT + "/" + entry.name;
                            stateOfFirstDownloadedFile = entry.state;
                            noUnsubmitStatusOfFirstDownloadedFile = entry.noUnsubmit;
                            break;
                        }
                    }
                }
                if (pathToOpen !== null) {
                    payload.setActiveNode(pathToOpen);
                    payload.path = pathToOpen;
                    serverCall("downloadFile", { path: pathToOpen });
                    return;
                }
                
                // Go to normal state if no file found to open
                setUiState("normal");
            }
        },
        click: {
            initialRequest: () => {
                if (uiState !== "normal")
                    return;
                const { node } = getPathObj(payload.path, treeCopy);
                if (node.type === "folder" || node.type === "trash") {
                    node.isExpanded = !node.isExpanded;
                }
                else {
                    serverCall("downloadFile", payload);
                    setUiState("waiting");
                }
                setTree(treeCopy);
            }, 
            serverResponse: ({fileContent, testYaml, testJs}) => {
                let submissionState = stateOfFirstDownloadedFile;
                let noUnsubmit = noUnsubmitStatusOfFirstDownloadedFile;
                if (treeCopy !== null) {
                    const { node } = getPathObj(payload.path, treeCopy);
                    submissionState = node.state;
                    noUnsubmit = node.noUnsubmit;
                }

                openFileCallback({
                    pathFilename: payload.path,
                    filename: fullPathFilenameToFilename(payload.path),
                    contentBase64: fileContent,
                    testYaml,
                    testJs,
                    submissionState,
                    noUnsubmit
                });
                setUiState("normal");
            }
        },
        rename: {
            initialRequest: () => {
                setUiState("naming");
                setCurEventState({ eventType: "rename", sourcePath: payload.path, namingNodePath: payload.path });
            },
            userConfirm: () => {
                const sourcePath = curEventState.sourcePath;
                const destPath = getPathObj(sourcePath, treeCopy).containingFolderStr + "/" + payload.newName;
                if (sourcePath === destPath) {
                    setUiState("normal");
                    return;
                }
                const err = validateNewFilename(sourcePath, destPath, tree);
                if (err !== null) {
                    errorCallback(err);
                    return;
                }
                serverCall("rename", { 
                    sourcePath: sourcePath,
                    newName: payload.newName
                });
                setUiState("waiting");
            },
            serverResponse: () => {
                getPathObj(curEventState.sourcePath, treeCopy).node.name = payload.newName;
                sortTree(treeCopy);
                setTree(treeCopy);
                setUiState("normal");
            },
            userCancel: () => {
                setUiState("normal");
            }
        },
        addNode: {
            initialRequest: () => {
                const pathObj = getPathObj(payload.path, treeCopy); 

                // If they chose a folder as the insert location, make sure it's expanded
                if (pathObj.type === "folder") 
                    pathObj.node.isExpanded = true;
                
                setCurEventState({ 
                    eventType: "addNode", 
                    namingNodePath: pathObj.folderAsDestStr + "/", // empty filename in the destination folder
                    revertTree: deepClone(treeCopy)  
                })
                
                const newNode = { name: "", type: payload.type };
                if (payload.type === "folder") {
                    newNode.isExpanded = false;
                    newNode.children = [];
                }
                const insertArr = pathObj.folderAsDestNode.children;
                insertArr.unshift(newNode);
                setTree(treeCopy);

                // The uiState needs to be set after the new tree is rendered; otherwise text text box won't
                // properly receive focus
                setTimeout(() => setUiState("naming"), 0);
                
            },
            userConfirm: () => {
                const pathObj = getPathObj(curEventState.namingNodePath, treeCopy); 

                if (payload.newName === "") { // Cancel if they left the filename blank
                    setTree(curEventState.revertTree);
                    setUiState("normal");
                    return;
                }

                const newPathFn = pathObj.str + payload.newName;
                const err = validateNewFilename(null, newPathFn, tree);
                if (err !== null) {
                    errorCallback(err);
                    setTree(curEventState.revertTree);
                    setUiState("error");
                    return;
                }

                payload.path = pathObj.folderAsDestStr;
                payload.type = pathObj.node.type;
                serverCall("addNode", payload);
                setUiState("waiting");
            },
            serverResponse: () => {
                getPathObj(curEventState.namingNodePath, treeCopy).node.name = payload.newName;
                sortTree(treeCopy);
                setTree(treeCopy);
                setUiState("normal");
            },
            userCancel: () => {
                setTree(curEventState.revertTree);
                setUiState("normal");
            }
        },
        uploadFile: {
            initialRequest: () => {
                setCurEventState({ 
                    eventType: "uploadFile", 
                    destFolder: getPathObj(payload.path, treeCopy).folderAsDestStr
                })
                payload.inputFileRef.current.click();
            },
            userConfirm: () => {
                const newNodePathFn = curEventState.destFolder + "/" + payload.file.name;
                //console.log(newNodePathFn);
                const err = validateNewFilename(null, newNodePathFn, tree);
                if (err !== null) {
                    errorCallback(err);
                    setUiState("error");
                    return;
                }
                serverCall("uploadFile", { file: payload.file, path: newNodePathFn });
                setCurEventState({ ...curEventState, filename: payload.file.name });
                setUiState("waiting");
            },
            serverResponse: () => {
                getPathObj(curEventState.destFolder, treeCopy).node.children.push({
                    type: "file",
                    name: curEventState.filename
                });
                sortTree(treeCopy);
                setTree(treeCopy);
                setUiState("normal");
            }
        },
        moveNode: {
            initialRequest: () => {
                const { dragPathFn, dropPathFn } = payload;               
                const dragPathObj = getPathObj(dragPathFn, treeCopy); 
                const dropPathObj = getPathObj(dropPathFn, treeCopy); 

                if (dragPathObj.folderAsDestStr === dropPathObj.folderAsDestStr) 
                    return; // Ignore if source and dest are the same

                const dragFn = fullPathFilenameToFilename(dragPathFn);
                const newNodePathFn = dropPathObj.folderAsDestStr + "/" + dragFn;

                const err = validateNewFilename(null, newNodePathFn, tree);
                if (err !== null) {
                    errorCallback(err);
                    setUiState("error");
                    return;
                }

                serverCall("moveNode", { source: dragPathFn, dest: newNodePathFn });
                setUiState("waiting");
            },
            serverResponse: () => {
                const { dragPathFn, dropPathFn } = payload;
                const dragPathObj = getPathObj(dragPathFn, treeCopy);
                const dropPathObj = getPathObj(dropPathFn, treeCopy); 
                const dragContainingNode = dragPathObj.containingFolderNode;
                const dragNode = dragPathObj.node;
                const dropDestNode = dropPathObj.folderAsDestNode;
                const sourceArr = dragContainingNode.children;
                for (let i = 0; i < sourceArr.length; i++) {
                    if (sourceArr[i].name === dragNode.name) {
                        sourceArr.splice(i, 1);
                    }
                }
                dropDestNode.children.push(dragNode);
                sortTree(treeCopy);
                setTree(treeCopy);
                setUiState("normal");
            }
        },
        moveToTrash: {
            initialRequest: () => {
                const pathObj = getPathObj(payload.path, treeCopy); 
                if (pathObj.arr[0] === 'Trash')
                    return; // Don't allow a file to be dragged from the trash back to the trash

                serverCall("moveToTrash", { path: payload.path, newName: getTrashName(pathObj.node.name) });
                setUiState("waiting");
            },
            serverResponse: ({trashName}) => {
                const pathObj = getPathObj(payload.path, treeCopy); 
                let parent = pathObj.containingFolderNode.children;
                for (let i = 0; i < parent.length; i++) {
                    if (parent[i].name === pathObj.node.name) {
                        let target = parent[i];
                        parent.splice(i, 1);
                        let trashNode = getPathObj(PATH_TO_TRASH, treeCopy).node;
                        target.name = trashName;
                        trashNode.children.push(target);
                    }
                }
                sortTree(treeCopy);
                setTree(treeCopy);
                setUiState("normal");
            }
        },
        deletePermanently: {
            initialRequest: () => {
                if (confirm(`Are you sure you want to permanently delete ${payload.path}?`)) {
                    serverCall("deletePermanently", {path: payload.path});
                    setUiState("waiting");
                }
            },
            serverResponse: () => {
                let node = getPathObj(payload.path, treeCopy).node;
                let parent = getPathObj(PATH_TO_TRASH, treeCopy).node.children;
                console.log(parent)
                for (let i = 0; i < parent.length; i++) {
                    if (parent[i].name === node.name) {
                        parent.splice(i, 1);
                    }
                }
                sortTree(treeCopy);
                setTree(treeCopy);
                setUiState("normal");
            }
        },
        emptyTrash: {
            initialRequest: () => {
                if (confirm(`Are you sure you want to permanently delete all items in the trash?`)) {
                    serverCall("emptyTrash");
                    setUiState("waiting");
                }
            },
            serverResponse: () => {
                getPathObj(PATH_TO_TRASH, treeCopy).node.children = [];
                sortTree(treeCopy);
                setTree(treeCopy);
                setUiState("normal");
            }
        }
    }

    serverCall = (requestType, payload) => {
        externalHandlers(requestType, payload, onServerResponse, errorCallback, timeoutCallback);
    }

    onServerResponse = (type, payload) => {
        if (type === "downloadFile")
            type = "click";
        handlers[type].serverResponse(payload);
    }

    type = type === null ? curEventState.eventType : type;
    return handlers[type][phase](payload);

}

