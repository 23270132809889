import Konva from "konva";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import KonvaShape from "./konva-shape";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("host-konva.konva-api.text."+id, args);
}

export default class Text extends KonvaShape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Create a new _Text_ object, with the specified initial properties.
        @number x The x coordinate of the top-left corner of the text
        @number y The y coordinate of the top-left corner of the text
        @string message
        @any color
    ` ]
    constructor(x=0, y=0, message="Default Text", color="black") {
        super();
        this.$createShape(Konva.Text, color, {
            x,
            y,
            text: message,
            fontSize: 30,
            fontFamily: 'Calibri'
        });
    }

    static $propdoc_fontSize = "The font size."
    set fontSize(value) { 
        this.$konvaSetter('fontSize', value, "number");
        this.$resetBounds();
        this.color = this.color;
    } 
    get fontSize() { return this.$konvaGetter('fontSize') }

    static $propdoc_fontFamily = "The font family."
    set fontFamily(value) { 
        this.$konvaSetter('fontFamily', value, "string");
        this.$resetBounds();
        this.color = this.color;
    } 
    get fontFamily() { return this.$konvaGetter('fontFamily') }

    static $propdoc_fontStyle = "The font style. Valid values are _normal_, _bold_, _italic_, and _italic bold_."
    set fontStyle(value) { 
        !['normal', 'bold', 'italic', 'italic bold'].includes(value.value) && reportError("invalid_font_style");
        this.$konvaSetter('fontStyle', value, "string");
        this.$resetBounds();
        this.color = this.color;
    } 
    get fontStyle() { return this.$konvaGetter('fontStyle') }

    static $propdoc_message = "The text to be displayed."
    set message(value) {
        this.$konvaSetter('text', value, "string");
        this.$resetBounds();
        this.color = this.color;
    } 
    get message() { return this.$konvaGetter('text') }

}