import PnList from "../../common-api/types/list";
import PnObject from "../../common-api/types/object";
import { createObject, createBuiltInTypeObject } from "../api-helpers";
import { setCurLocation } from "../interpreter.js";
import { evalReferencePath } from "./reference-path-evaluator";
import { parseStringLiteral } from "../parser/parser";

import PnError from "../pn-error";
const reportError = (id, args) => { 
    throw new PnError("interpreter.execution.expression-evaluator."+id, args);
}

export async function evaluateExpression(expr) {
    setCurLocation(expr);

    switch (expr.type) {
        case "number_literal": return createBuiltInTypeObject(expr.value);
        case "string_literal": return await evalStringLiteral(expr); 
        case "boolean_literal": return createBuiltInTypeObject(expr.value);
        case "list_literal": return await evalListLiteral(expr);
        case "object_literal": return await evalObjectLiteral(expr);
        case "binary_operation": return await evalBinaryOperation(expr); 
        case "unary_minus": return await evalUnaryMinus(expr); 
        case "not_operator": return await evalNotOperator(expr); 
        case "reference_path": return await evalReferencePath(expr);
        default: throw new Error("Invalid expression type: "+expr.type);
    }
}

async function evalStringLiteral(expr) {
    parseStringLiteral(expr);
    let result = expr.value;
    for (let i = 0; i < expr.matches.length; i++) {
        const resolved = await evaluateExpression(expr.expressions[i]);
        result = result.replace("{"+expr.matches[i]+"}", resolved.value);
    }
    
    return createBuiltInTypeObject(result);
}

async function evalBinaryOperation(expr) {
    const leftObj = await evaluateExpression(expr.left);
    const rightObj = await evaluateExpression(expr.right);
    const result = leftObj.$operate(expr.operator.type, rightObj);
    return result;
}

async function evalUnaryMinus(expr)  {
    const oldValue = await evaluateExpression(expr.value);
    oldValue.$type !== "number" && reportError("unary_minus_nan", { value: expr.value.value });
    return createBuiltInTypeObject(-oldValue.value)
}

async function evalNotOperator(expr) {
    const oldValue = await evaluateExpression(expr.value);
    oldValue.$type !== "boolean" && reportError("taking_not_of_non_boolean", { value: expr.value.value });
    return createBuiltInTypeObject(!oldValue.value)
}

async function evalListLiteral(expr) {
    const result = [];
    for (let item of expr.items) {
        result.push(await evaluateExpression(item));
    }
    return createObject(PnList, [result]);
}

async function evalObjectLiteral(expr) {
    const props = {};
    for (let entry of expr.entries) {
        const key = entry[0].value;
        const value = await evaluateExpression(entry[1])
        props[key] = value;
    }
    return createObject(PnObject, [props]);
}