import * as interpreter from "../../interpreter/interpreter";
import PnObject from "../../common-api/types/object";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createObject } from "../../interpreter/api-helpers";
import { createBuiltInTypeObject } from "../../interpreter/api-helpers";
import { autoAdd, addToImage } from "../konva";
import PnError from "../../interpreter/pn-error"
const reportError = (id, args) => { 
    throw new PnError("host-konva.konva-api.konva-node."+id, args);
}

export const isColor = (strColor) => {
    if (typeof strColor !== "string")
        return false;
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
}

export default class KonvaNode extends PnObject {
    constructor() {
        super();
        this.$events = [];
        this.konvaCall = this.konvaCall.bind(this);
        this.glideTo = this.glideTo.bind(this);
        this.glideToAsync = this.glideToAsync.bind(this);
        this.getNextEvent = this.getNextEvent.bind(this);
        this.setPos = this.setPos.bind(this);
    }

    $addEventListeners() {
        const addEvent = (name, obj) => {
            this.$shape.on(name, () => this.$events.push(createBuiltInTypeObject(obj)));
        }

        addEvent("click", { type: "click" });
        addEvent("mouseenter", { type: "mouseEnter" });
        addEvent("mouseleave", { type: "mouseLeave" });


    }

    $konvaSetter(prop, value, type="any") { 
        if (type === "colorStr") {
            !isColor(value.value) && reportError("invalid_color", { str: value.$toPrintable() }  );
        }
        else {
            type !== "any" && value.$type !== type && reportError("invalid_prop_type", { 
                prop,
                expectedType: type,
                receivedType: value.$type,
                receivedValue: value.$toPrintable()
            })
        }
        this.$shape[prop](value.value) 
    }
    $konvaGetter(prop) { 
        const kValue = this.$shape[prop]();
        if (kValue === undefined)
            throw new Error("Undefined kValue");
        return createBuiltInTypeObject(kValue);
    }
    
    static $propdoc_x = "The x position of the object.";
    set x(value) { this.$konvaSetter('x', value, "number") } 
    get x() { return this.$konvaGetter('x') }

    static $propdoc_y = "The y position of the object.";
    set y(value) { this.$konvaSetter('y', value, "number") } 
    get y() { return this.$konvaGetter('y') }

    static $propdoc_offsetX = "The movement of the object origin on the x axis.";
    set offsetX(value) { this.$konvaSetter('offsetX', value, "number") } 
    get offsetX() { return this.$konvaGetter('offsetX') }

    static $propdoc_offsetY = "The movement of the object origin on the y axis.";
    set offsetY(value) { this.$konvaSetter('offsetY', value, "number") } 
    get offsetY() { return this.$konvaGetter('offsetY') }

    static $propdoc_opacity = "A value between 0 and 1 representing the opacity of the object. 0 is fully opaque, 1 is fully transparent. Default is 0.";
    set opacity(value) { this.$konvaSetter('opacity', value, "number") } 
    get opacity() { return this.$konvaGetter('opacity') }

    static $propdoc_rotation = "The angle of rotation of the object in degrees.";
    set rotation(value) { this.$konvaSetter('rotation', value, "number") } 
    get rotation() { return this.$konvaGetter('rotation') }

    static $_setPos = [NO_BLOCK, NO_RETURN,
    `Sets the shape's x and y coordinates.
        @number x
        @number y `, true]
    setPos(x, y) {
        this.x = x;
        this.y = y;
    }


    static $_glideTo = [BLOCKS, NO_RETURN,
    `Glides the object to the specified (x, y) coordinates in the given number of milliseconds.
        @number x
        @number y
        @number time The amount of time the glide lasts for in milliseconds` ]
    glideTo(x, y, time) {
        if (this.$shape.getAncestors().length === 0)
            return; // not yet added to a layer
        this.$shape.to({ x, y, duration: time/1000, onFinish: interpreter.resolve });
    }


    static $_glideToAsync = [NO_BLOCK, NO_RETURN, 
    `Glides the object to the specified (x, y) coordinates in the given number of milliseconds, but does not wait
    until the glide completes to execute the next program instruction. This method is useful for making multiple
    shapes glide at the same time
        @number x
        @number y
        @number time The amount of time the glide lasts for in milliseconds ` ]
    glideToAsync(x, y, time) {
        if (this.$shape.getAncestors().length === 0)
            return; // not yet added to a layer
        this.$shape.to({ x, y, duration: time/1000 });
    }


    static $_getNextEvent = [NO_BLOCK, RETURNS, 
    `Returns the next event from this object's event queue. `]
    getNextEvent() {
        if (this.$events.length === 0)
            return createBuiltInTypeObject({ type: "none" });
        const ev = this.$events[0];
        this.$events.shift();
        
        return ev;
    }


    static $_konvaCall = [NO_BLOCK, RETURNS, 
    `Calls a method on the object from the underlying JavaScript Konva library.
        @string methodName  The name of the method to call
        @list params        A list to send to the method call as parameters `, true]
    konvaCall(methodName, params) {
        try {
            this.$shape[methodName.value](...params.$toDebugDisplay());
        }
        catch (e) {
            console.log(e);
            reportError("invalid_konva_call");
        }
    }

}