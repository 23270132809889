import * as konva from "./konva";
import * as interpreter from "../interpreter/interpreter";
import * as konvaApi from "./konva-api/konva-api";
import { createBuiltInTypeObject } from "../interpreter/api-helpers";

let config;

// Functions called by UI
export const init = (cfg) => {
    config = cfg;
    interpreter.init({
        ...config, 
        terminationHandler,
        errorHandler,
        hostApi: konvaApi.api
    });
    konva.init();
}

export const run = (code, curTree) => {
    konva.reset();
    interpreter.run(code, curTree, { kbEvents: createBuiltInTypeObject([]) });
}

export const forceStop = () => {
    interpreter.forceStop();
}

export const runTests = async (code, tests, onFinish) => {
    const results = await interpreter.runStructureTests(code, tests);
    onFinish(results, null);
} 

export const handleKeyPress = (keycode) => {
    const kbEvents = interpreter.getSymbol("kbEvents");
    if (kbEvents === null)
        return;
    const evs = [...kbEvents];
    evs.push({ type: "keyPress", keycode });
    interpreter.setSymbol("kbEvents", evs);
}


// Functions called by the Interpreter
export const errorHandler = (err) => {
    config.errorHandler(err);
}

const terminationHandler = () => {
    config.terminationHandler();
}


// Functions called by API
export const addToImage = (obj) => konva.addToImage(obj);
export const addGrid = () => konva.addGrid();