import * as interpreter from "../interpreter/interpreter";

let testQueue;
let curTestScript;
let curTestResult;
let curTestName;
let testCode;
let structuralTestResults;
let runtimeTestResults;
let onTestFinish;

const raiseFailedRuntimeTestError = () => {
    runtimeTestResults.push({ 
        name: curTestName, 
        result: false, 
        details: curTestResult,
        earlyTermination: false
    });
    setTimeout(execNextRuntimeTest, 0);
    const e = Error("raiseFailedRuntimeTestError");
    e.isFailedRuntimeTest = true;
    throw e;
}

const execNextRuntimeTest = () => {
 
    if (!testQueue || testQueue.length === 0) {
        onTestFinish(structuralTestResults, runtimeTestResults);
        return;
    }
        
    const test = testQueue.shift();
    if (test.script) {
        curTestName = test.name;
        curTestScript = test.script;
        curTestResult = [];
        interpreter.run(testCode);
    }
}

export const runTests = async (code, tests, onFinish) => {

    structuralTestResults = await interpreter.runStructureTests(code, tests);
    runtimeTestResults = [];
    testQueue = tests.runtime;
    testCode = code;
    onTestFinish = onFinish;
    execNextRuntimeTest();
}

export const handleTermination = () => {
    if (curTestScript.length === 0) {
        runtimeTestResults.push({ 
            name: curTestName, 
            result: true, 
            details: curTestResult,
            earlyTermination: false
        });
    }
    else {
        while (curTestScript.length > 0) {
            const cur = curTestScript.shift();
            let expected;
            if ("expect" in cur)
                expected = "<code>[print] <b>"+cur.expect+"</b></code>";
            else if ("send" in cur) 
                expected = "An input statement";
            else
                throw new Error("Invalid Test Script");
            curTestResult.push({
                expected,
                received: "<i>None - Program has ended</i>",
                pass: false
            })
        }
        runtimeTestResults.push({ 
            name: curTestName, 
            result: false, 
            details: curTestResult,
            earlyTermination: true
        });
    }
    
    execNextRuntimeTest();
}


export const handleError = (err) => {
    runtimeTestResults.push({ 
        name: curTestName, 
        result: false, 
        details: curTestResult,
        earlyTermination: false,
        error: err
    });
    setTimeout(execNextRuntimeTest, 0);
}

export const handlePrintStatement = (data) => {
    const received = "<code>[print] <b>"+data+"</b></code>";
        let result;
        if (curTestScript.length === 0) {
            result = {
                pass: false,
                expected: "No further input or output",
                received
            };
        }
        else if ("send" in curTestScript[0]) {
            result = {
                pass: false,
                expected: "An input statement",
                received
            };
        }
        else if ("expect" in curTestScript[0]) {
            if (typeof data === "string")
                data = data.toLowerCase();
            if (curTestScript[0].expect === data) {
                result = {
                    pass: true,
                    expected: received,
                    received
                };
            }
            else {
                result = {
                    pass: false,
                    expected: "<code>[print] <b>"+curTestScript[0].expect+"</b></code>",
                    received
                };
            }
        }
        else
            throw new Error("Invalid test script");

        curTestResult.push(result);
        !result.pass && raiseFailedRuntimeTestError();
        curTestScript.shift();
}

export const handleInputStatement = prompt => {
    const received = "<code>[input] <b>"+prompt+"</b></code>";
        let result;
        if (curTestScript.length === 0) {
            result = {
                pass: false,
                expected: "No further input or output",
                received
            };
        }
        else if ("expect" in curTestScript[0]) {
            result = {
                pass: false,
                expected: "An output statement",
                received
            };
        }
        else if ("send" in curTestScript[0]) {
            result = {
                pass: true,
                expected: "An input statement",
                received: received+"<br><code>(Responded with <b>"+curTestScript[0].send+"</b>)</code>"
            };
            interpreter.resolve(curTestScript[0].send)
        }
        else
            throw new Error("Invalid test script");

        curTestResult.push(result);
        !result.pass && raiseFailedRuntimeTestError();
        curTestScript.shift();
}