import { processStatement } from "./statement-processor.js";
import * as state from "./model/state.js";
import * as structure from "./model/structure.js";
import * as model from "./model/model.js";
import * as symbolTables from "./model/symbol-tables";
import { setCurLocation } from "../interpreter.js";

import PnError from "../pn-error";
import { createBuiltInTypeObject } from "../api-helpers.js";
const reportError = (id, args) => { 
    throw new PnError("interpreter.execution.execution."+id, args);
}

const SUSPECTED_INFINITE_LOOP_THRESHOLD = 100000;
let handleTermination;
let hasStopSignal;
let stmtCount;

export const run = async (ast, hostApi, handleError, _handleTermination, globalVars, curTree) => {
    try {
        await model.init(ast, hostApi, curTree);
        handleTermination = _handleTermination;
        hasStopSignal = false;
        stmtCount = 0;
        for (let name in globalVars) {
            globalVars[name] = globalVars[name];
        }


        await executeCodeBlock({ 
            type: "top_level",
            stmts: structure.getTopLevelStatements(),
            args: globalVars
        })  
        handleTermination();
    }
    catch (err) {
        handleError(err)
    }
}


export const getSymbol = symbolTables.getSymbol;
export const setSymbol = symbolTables.setSymbol;


export const resolve = value => {
    stmtCount = 0
    const rcb = state.getResolveCallback();
    if (!rcb)
        throw new Error("Attempting to resolve a blocking function without one waiting to be resolved.")
    state.setResolveCallback(null);
    rcb(value);
}

export const stop = () => {
    handleTermination && handleTermination();
    hasStopSignal = true;
}

export async function executeCodeBlock({type, stmts, args, obj }) {
    if (hasStopSignal)
        return "stopped";
    stmts = [...stmts];
    let result = {};
    symbolTables.pushScope(args, obj);
    while (stmts.length > 0 && !hasStopSignal && stmtCount < SUSPECTED_INFINITE_LOOP_THRESHOLD) {
        const stmt = stmts.shift();
        //console.log(stmt);
        setCurLocation(stmt);
        result = await processStatement(stmt, stmts);

        if (result.abortSignal) {
            const isLoopAbort = result.abortSignal === "break" || result.abortSignal === "continue";
            isLoopAbort && (type === "top_level" || type === "def") && reportError("misplaced_break_or_continue", { type: result.abortSignal });
            result.abortSignal === "return" && type === "top_level" && reportError("misplaced_return");
            break;
        }

        stmtCount++;
        if (stmtCount >= SUSPECTED_INFINITE_LOOP_THRESHOLD) {
            reportError("suspected_infinite_loop");
        }
    }
    symbolTables.popScope();
    return result;
}

export const runStructureTests = async (ast, hostApi, tests) => {
    await model.init(ast, hostApi);

    if (tests === undefined)
        return [];

    const getType = val => typeof val === "object" && Array.isArray(val) ? "array" : typeof val;
    const isLiteral = type => type !== "object" && type !== "array";

    const validate = (testNode, structNode) => {
        const testType = getType(testNode);
        const structType = getType(structNode);

        // We will return true iff the object of the testNode validates against any of the
        // elements of the structNode's array.
        if (testType === "object" && structType === "array") {
            for (let item of structNode) {
                if (validate(testNode, item))
                    return true;
            }
            return false;
        }

        // We will return true iff each key in the testNode exists in the structNode (but not
        // necessarily the opposite), and the testNode's value validates against the structNode's
        // value. 
        if (testType === "object" && structType === "object") {
            for (let key in testNode) {

                // Special case - if the testNode's key is ".anyKey", then we return true if the value
                // of any key of the testNode validates against structNode's value
                if (key === '.anyKey') {
                    for (let skey in structNode) {
                        if (validate(testNode[key], structNode[skey]))
                            return true;
                    }
                    return false;
                }

                if (!(key in structNode))
                    return false;  

                if (!validate(testNode[key], structNode[key])) 
                    return false;
            }
            return true;
        }

        // We return true iff the testNode matches the structNode, or if the testNode is null, as the
        // latter indicates that we were just checking for the existence of the key in the parent object
        if (isLiteral(testType) && isLiteral(structType)) {
            if (testNode === structNode)
                return true;
            if (testNode === null)
                return true; 
        }

        // We return true the iff the structNode is an array that matches the conditions specified
        // in the testNode's string. Expected format of the string:
        //  .array - matches any array
        //  .array[3] - matches an array of length 3
        //  .array[3+] - matches an array of length at least 3
        if (testType === "string" && structType === "array") {
            if (testNode.indexOf(".array") !== 0)    
                return false;
         
            if (testNode === ".array")
                return true;
            const lbPos = testNode.indexOf('[');
            const rbPos = testNode.indexOf(']');
            if (lbPos === -1 || rbPos === -1)
                throw new Error("Malformed array test");
            const atLeast = testNode[rbPos-1] === "+";
            const arrLen = Number(testNode.substring(lbPos+1, atLeast ? rbPos-1 : rbPos));
            if (isNaN(arrLen))
                throw new Error("Malformed array test");
            if (atLeast)
                return structNode.length >= arrLen;
            return structNode.length === arrLen;
          
        }
        return false;
    }

    const testReport = [];
    tests.forEach(test => {
        const name = test.name;
        const content = {...test}
        delete content.name;  
        const result = validate(content, structure.getFullStructure());
        testReport.push({name, result});
    });
    return testReport;
}
