import { createElement, appendChildById, getElementById } from "./dom-helpers";
import * as uiHandlers from "./handlers-ui";
import { getUsername } from './file-explorer/handlers';

export const addHeaderButton = (text, handler, parentId, id) => {
    const button = createElement("button", text, { 
        events: { click: handler },
        attributes: { 
            class: "header-button", id }
    });
    appendChildById(parentId, button);
}

export const init = () => {
    addHeaderButton("&#9989; Run", () => uiHandlers.handleRunButton(false), "main-header-buttons", "run-button");
    addHeaderButton("&#128721; Stop", uiHandlers.handleStopButton, "main-header-buttons", "stop-button");
    //addHeaderButton("Save", uiHandlers.handleSaveButton, "main-header-buttons");
    addHeaderButton("Exit Project", uiHandlers.handleExitProject, "right-header-buttons");
    addHeaderButton("Logout "+getUsername(), uiHandlers.handleLogout, "right-header-buttons");
    getElementById("stop-button").disabled = true;
}

