import Phaser from 'phaser';
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import Shape from './shape';
import phaser from "../phaser";
import { colorNameToHex } from '../util';

export default class Circle extends Shape {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Creates a new Circle object. 
        @number x
        @number y
        @number radius
        @string color
    `]
    constructor(x=20, y=20, radius=20, color="black") {
        super(color);
        this.$radius = createBuiltInTypeObject(radius);
        this.$phaserObject = new Phaser.GameObjects.Ellipse(phaser.getScene(), x, y, radius*2, radius*2, colorNameToHex(color));

        this.$addProp('radius',
            (pnValue) => {
                this.$radius = pnValue;
                this.$phaserObject.setSize(pnValue.value*2, pnValue.value*2);
            },
            () => this.$radius
        );
    }
    static $propdoc_radius = "The radius of the circle.";
}