import * as interpreter from "../../interpreter/interpreter";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject } from "../../interpreter/api-helpers";
import { EVENT_FOR_SCENE  } from "./events";
import PnObject from "../../common-api/types/object";
import phaser from "../phaser";
import PnError from "../../interpreter/pn-error";
const reportError = (id, args) => { 
    throw new PnError("host-phaser.phaser-api.scene."+id, args);
}


const phaserActions = {
    backgroundColor: value => phaser.setBackgroundColor(value),
    backgroundImage: value => phaser.setBackgroundImage(value),
}

export default class Scene extends PnObject {

    static $_constructor = [NO_BLOCK, NO_RETURN,
    `Creates a new Scene object. ` ]
    constructor() {
        super();
        this.add = this.add.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.setScrollingBackground = this.setScrollingBackground.bind(this);
        this.$isPnScene = true;
        this.$isActive = false;
        this.$gameObjects = {};
        this.$backgroundColor = createBuiltInTypeObject("white");
        this.$backgroundImage = createBuiltInTypeObject("");
        this.$scrollingBgFollow = null;
        this.$scrollingBgImage = null;
    }

    static $_add = [NO_BLOCK, RETURNS, 
    `Adds a game object to the scene. 
        @object gameObject
    `];
    add(go) {  
        !go.$isPnGameObject && reportError("add_non_game_object");
        this.$gameObjects[go.$goId] = go;
        if (this.$isActive)
            this.$addGameObjectToActiveScene(go);
        return createBuiltInTypeObject(go); 
    }

    $addGameObjectToActiveScene(go) {
        phaser.addGameObject(go.$phaserObject, go.$goId);
        go.$phaserObject.setData("pnObject", go);
        go.$curScene = this;
        const defaultGroupName = go.$pnClassHierarchy.length > 0 ? go.$pnClassHierarchy[0].name : go.$jsClassRef.$className;
        !go.$physicsGroups.includes(defaultGroupName) && go.$physicsGroups.push(defaultGroupName);
        go.$physicsGroups.forEach(groupName => phaser.addToGroup(go.$phaserObject, groupName));
        go.$isStationary && go.$phaserObject.body.setImmovable(true);
        !go.$allowsGravity && go.$phaserObject.body.setAllowGravity(false);
        !go.$scrolls && go.$phaserObject.setScrollFactor(0);
        go.$handleAddToScene();
    }

    $removeGameObject(goId) {
        delete this.$gameObjects[goId];
    }

    static $_sendMessage = [NO_BLOCK, NO_RETURN, 
    `Sends a message to this scene after the given time delay. The message can be retrieved as an event from the scene's *update* method.
        @string message
        @number timeDelay
    `];
    sendMessage(message, timeDelay) {  
        setTimeout(() => phaser.addMessageEvent(EVENT_FOR_SCENE, message), timeDelay)
    }

    static $_update = [NO_BLOCK, NO_RETURN, 
    `This method is available for subclasses to override. Conventionally, each object's *update* method is called on each frame update. 
        @object events
    `];
    update(events) {} // for superclass to override


    static $_setScrollingBackground = [NO_BLOCK, NO_RETURN,
    `Sets the background to a given image. If the background is larger than the screen size, it will scroll, maintaining focus on the specified sprite.
        @string imagePath The path to the background image
        @object followSprite The sprite the camera should follow
    `];
    setScrollingBackground(image, followSprite) {
        this.$scrollingBgImage = image;
        this.$scrollingBgFollow = followSprite;
    }

    $load() {
        // console.log("Loading Scene");
        this.$isActive = true;
        for (let action in phaserActions) {
            phaserActions[action](this["$"+action].value);
        }

        if (this.$scrollingBgImage && this.$scrollingBgFollow)
            phaser.setScrollingBackgroundImage(this.$scrollingBgImage, this.$scrollingBgFollow);

        for (let goId in this.$gameObjects) {
            this.$addGameObjectToActiveScene(this.$gameObjects[goId]);
        }


    }

    $unload() {
        phaser.unloadScene();
        this.$isActive = false;
        this.$gameObjects = {};
    }

    $setProp = (prop, value) => {
        this["$"+prop] = value;
        if (this.$isActive) {
            phaserActions[prop](value.value);
        }

    }

    static $propdoc_backgroundColor = "Sets the background colour of the scene.";
    set backgroundColor(value) { this.$setProp("backgroundColor", value) }
    get backgroundColor() { return this.$backgroundColor }

    static $propdoc_backgroundImage = "A string containing the absolute path to an image to use as a background. If both a background color and background image are specified, the image will take precedence.";
    set backgroundImage(value) { this.$setProp("backgroundImage", value) }
    get backgroundImage() { return this.$backgroundImage }

    static $propdoc_gameObjects = "A read only property containing a list of objects in scene.";
    set gameObjects(value) { reportError("setting_gameObjects_property") }
    get gameObjects() {
        const result = [];
        for (let key in this.$gameObjects)
            result.push(this.$gameObjects[key]);
        return createBuiltInTypeObject(result);
    }

}