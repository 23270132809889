import * as interpreter from "../../interpreter/interpreter";
import KonvaNode, { isColor } from "./konva-node";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createObject } from "../../interpreter/api-helpers";
import { createBuiltInTypeObject } from "../../interpreter/api-helpers";
import { autoAdd, addToImage } from "../konva";
import PnError from "../../interpreter/pn-error"
const reportError = (id, args) => { 
    throw new PnError("host-konva.konva-api.konva-shape."+id, args);
}

export default class KonvaShape extends KonvaNode {
    constructor() {
        super();
        this.setOutline = this.setOutline.bind(this);
        this.setShadow = this.setShadow.bind(this);
    }
   
    $resetBounds() {
        this.$boundsRelativeToXY = { top: 0, bottom: this.$shape.height(), left: 0, right: this.$shape.width() }
    }
    
    $createShape(classRef, color, props) {
        const commonProps = {
            stroke: "black",
            strokeWidth: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: "black",
            shadowBlur: 0
        }

        this.$curColor = "";

        this.$shape = new classRef({...commonProps, ...props});
        if (!this.$boundsRelativeToXY)
            this.$boundsRelativeToXY = { top: 0, bottom: this.$shape.height(), left: 0, right: this.$shape.width() }
        this.color = typeof color === "string" ? createBuiltInTypeObject(color) : color;
        addToImage(this.$shape);
        this.$addEventListeners();
    }


    $validatePointsArray(points, shape) { // used for lines and polygons
        const minLen = shape === "line" ? 4 : 6;
        points.length < minLen && reportError("points_array_too_short", { shape, minLen, minPoints: minLen/2 });
        points.length % 2 !== 0 && reportError("points_array_len_not_even", { len: points.length });
        points.forEach(point => point.$type !== "number" && reportError("non_numerical_point", { value: point.value}));
    }

    static $propdoc_outlineWidth = "The width of the shape's outline. Default is 0.";
    set outlineWidth(value) { this.$konvaSetter('strokeWidth', value, "number") } 
    get outlineWidth() { return this.$konvaGetter('strokeWidth') }

    static $propdoc_outlineColor = "The color of the shape's outline.";
    set outlineColor(value) { this.$konvaSetter('stroke', value, "colorStr") } 
    get outlineColor() { return this.$konvaGetter('stroke') }

    static $propdoc_shadowX = "The offset of the object's shadow on the x axis.";
    set shadowX(value) { this.$konvaSetter('shadowOffsetX', value, "number") } 
    get shadowX() { return this.$konvaGetter('shadowOffsetX') }

    static $propdoc_shadowY = "The offset of the object's shadow on the y axis.";
    set shadowY(value) { this.$konvaSetter('shadowOffsetY', value, "number") } 
    get shadowY() { return this.$konvaGetter('shadowOffsetY') }

    static $propdoc_shadowColor = "The color of the object's shadow.";
    set shadowColor(value) { this.$konvaSetter('shadowColor', value, "colorStr") } 
    get shadowColor() { return this.$konvaGetter('shadowColor') }

    static $propdoc_shadowBlur = "How blurry the shadow will be. A larger number is more blurry. Try starting with a value of 10.";
    set shadowBlur(value) { this.$konvaSetter('shadowBlur', value, "number") } 
    get shadowBlur() { return this.$konvaGetter('shadowBlur') }

    static $propdoc_color = "The shape's colour."
    set color(value) { 
        const getStops = colors => {
            const numStops = colors.length;
            if (numStops < 2)
                reportError("gradient_less_than_2_stops");
            const stops = [];
            for (let i = 0; i < numStops; i++) {
                !isColor(colors[i]) && reportError("invalid_color", { str: colors[i]} );
                stops.push(i / (numStops-1));
                stops.push(colors[i]);
            }
            return stops;
        }

        this.$curColor = value;
        if (value.$type === "string") {
            !isColor(value.value) && reportError("invalid_color", { str: value.$toPrintable() }  );
            this.$konvaSetter('fill', value);
            this.$konvaSetter('fillPriority', 'fill');
            return;
        }
        else if (value.grad_type && value.grad_type.value === "linear") {
            const obj = value.$toDebugDisplay();
            this.$shape.setAttrs({
                fillLinearGradientStartPoint: { x: obj.start_x, y: obj.start_y },
                fillLinearGradientEndPoint: { x: obj.end_x, y: obj.end_y },
                fillLinearGradientColorStops: obj.stops,
                fillPriority: 'linear-gradient'
            });         
        }
        else if (value.grad_type && value.grad_type.value === "radial") {
            const obj = value.$toDebugDisplay();
            this.$shape.setAttrs({
                fillRadialGradientStartPoint: { x: obj.start_x, y: obj.start_y },
                fillRadialGradientStartRadius: obj.start_radius,
                fillRadialGradientEndPoint: { x: obj.end_x, y: obj.end_y },
                fillRadialGradientEndRadius: obj.end_radius,
                fillRadialGradientColorStops: obj.stops,
                fillPriority: 'radial-gradient'
            });
        }
        else if (value.grad_type && value.grad_type.value === "horizontal-simple") {
            const obj = value.$toDebugDisplay();
            const { left, right, top, bottom } = this.$boundsRelativeToXY;
            const y = (top+bottom)/2;
            this.$shape.setAttrs({
                fillLinearGradientStartPoint: { x: left, y },
                fillLinearGradientEndPoint: { x: right, y },
                fillLinearGradientColorStops: getStops(obj.colors),
                fillPriority: 'linear-gradient'
            }); 
        }
        else if (value.grad_type && value.grad_type.value === "vertical-simple") {
            const obj = value.$toDebugDisplay();
            const { left, right, top, bottom } = this.$boundsRelativeToXY;
            const x = (left+right)/2;
            this.$shape.setAttrs({
                fillLinearGradientStartPoint: { x, y: top },
                fillLinearGradientEndPoint: { x, y: bottom },
                fillLinearGradientColorStops: getStops(obj.colors),
                fillPriority: 'linear-gradient'
            }); 
        }
        else if (value.grad_type && value.grad_type.value === "radial-simple") {
            const obj = value.$toDebugDisplay();
            const { left, right, top, bottom } = this.$boundsRelativeToXY;
            const xMid = (left+right)/2;
            const yMid = (top+bottom)/2;
            this.$shape.setAttrs({
                fillRadialGradientStartPoint: { x: xMid, y: yMid },
                fillRadialGradientStartRadius: 0,
                fillRadialGradientEndPoint: { x: xMid, y: yMid },
                fillRadialGradientEndRadius: Math.min(right-left, bottom-top)/2,
                fillRadialGradientColorStops: getStops(obj.colors),
                fillPriority: 'radial-gradient'
            });
        }
    } 
    get color() { return this.$curColor }

    static $_setOutline = [NO_BLOCK, NO_RETURN,
    `Sets the width and color of the shape's outline.
        @number width
        @any color `, true]
    setOutline(w, c) {
        this.outlineWidth = w;
        this.outlineColor = c;
    }


    static $_setShadow = [NO_BLOCK, NO_RETURN,
    `Sets all the shape's shadow properties.
        @number x
        @number y
        @number blur
        @any color `, true]
    setShadow(x, y, blur, color) {
        this.shadowX = x;
        this.shadowY = y;
        this.shadowBlur = blur;
        this.shadowColor = color;
    }

}