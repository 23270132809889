import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
import { createBuiltInTypeObject } from "../../interpreter/api-helpers";
import BaseType from "./base-type";


export default class PnString extends BaseType {
    constructor(value) {
        super();
        this.value = value;
        this.$type = "string";
        this.$typeCategory = "literal";
        this.capitalize = this.capitalize.bind(this);
        this.count = this.count.bind(this);
        this.endswith = this.endswith.bind(this);
        this.find = this.find.bind(this);
        this.isalpha = this.isalpha.bind(this);
        this.isnumeric = this.isnumeric.bind(this);
        this.isalnum = this.isalnum.bind(this);
        this.islower = this.islower.bind(this);
        this.isspace = this.isspace.bind(this);
        this.isupper = this.isupper.bind(this);
        this.join = this.join.bind(this);
        this.lower = this.lower.bind(this);
        this.removePrefix = this.removePrefix.bind(this);
        this.removeSuffix = this.removeSuffix.bind(this);
        this.replace = this.replace.bind(this);
        this.split = this.split.bind(this);
        this.startswith = this.startswith.bind(this);
        this.strip = this.strip.bind(this);
        this.upper = this.upper.bind(this);
    }

    // Evenutally get rid of this since python doesn't have this method
    get length() {
        return this.value.length;
    }

    $toPrintable() { return this.value }
    $toDebugDisplay() { return this.value }
    $toJsLiteral() { return this.value }

    $validateOperation(op, rightSide) {
        const opType = this.$getOpType(op);
        const rsType = typeof rightSide.value;
        let valid = false;
        //if (opType === "comparison" && rsType === "string")
        if (opType === "comparison")
            valid = true;

        if (op === "plus" && (rsType === "number" || rsType === "string"))
            valid = true;

        !valid && this.$reportIncompatibleTypes(op, rightSide);
    }


    $index(isRange, start, end) {

        this.$validateIndexRange(isRange, start, end, this.value.length-1);

        if (!isRange) 
            return createBuiltInTypeObject(this.value[start.value]);
        let newStr;
        if (end === null) 
            newStr = this.value.substring(start.value);
        else if (start === null)
            newStr = this.value.substring(0, end.value+1);
        else 
            newStr = this.value.substring(start.value, end.value+1);
        
        return createBuiltInTypeObject(newStr);
        
    }

    static $_capitalize = [NO_BLOCK, RETURNS,
    `Returns a copy of the string with its first character capitalized and the rest lowercased.` ]
    capitalize()    {  
        const lower = this.value.toLowerCase();
        return lower[0].toUpperCase() + lower.substring(1);
    }

    static $_count = [NO_BLOCK, RETURNS,
    `Returns the number of occurrences of the parameter _substring_ in the string.
        @string substring` ]
    count(substr)    {  
        const str = this.value;
        let pos = str.indexOf(substr);
        let count = 0;
        while (pos !== -1) {
            count++;
            pos = str.indexOf(substr, pos+1);
        }
        return count;
    }


    static $_endswith = [NO_BLOCK, RETURNS,
    `Returns _True_ if the string ends with the specified suffix, otherwise return _False_.
        @string suffix` ]
    endswith(suffix)  { return this.value.lastIndexOf(suffix) === this.value.length - suffix.length }


    static $_find = [NO_BLOCK, RETURNS,
    `Returns the lowest index in the string where the parameter _substring_ is found, optionally within a given
    range of the string. Returns -1 if _substring_ is not found.
        @string substring  
        ?number start Where to start searching. The default is 0. 
        ?number end Where to end searchng. The default is the end of the string. `]
    find(needle, start=0, end)  { 
        let haystack = this.value;
        if (end !== undefined)
            haystack = haystack.substring(0, end+1);
        return haystack.indexOf(needle, start);
    }

    static $_isalpha = [NO_BLOCK, RETURNS,
    `Returns _True_ if the string is at least one character long, and all characters are in the Enlish alphabet, 
    either upper or lower case. Otherwise return _False_. ` ]
    isalpha()  { return this.value.match(/^[A-Za-z]+$/) !== null; }
        

    static $_isnumeric = [NO_BLOCK, RETURNS,
    `Returns _True_ if the string is at least one character long, and all characters are digits in the range of
    0 to 9. Otherwise returns _False_.` ]
    isnumeric()  { return this.value.match(/^[0-9]+$/) !== null; }


    static $_isalnum = [NO_BLOCK, RETURNS,
    `Returns _True_ if the string is at least one character long, and the string is alphanumeric (ie, the string
    only contains letters and numbers). Otherwise returns _False_.` ]
    isalnum()  { return this.value.match(/^[A-Za-z0-9]+$/) !== null; }


    static $_islower = [NO_BLOCK, RETURNS,
    `Returns _True_ if all cased characters 4 in the string are lowercase and there is at least one cased character,
    _False_ otherwise.` ]
    islower()  { 
        if (this.value.match(/[a-zA-Z]/) === null) return false; // Check for no cased chracters
        return (this.value.match(/[A-Z]/)) === null;
    }


    static $_isspace = [NO_BLOCK, RETURNS,
    `Returns _True_ if there are only whitespace characters in the string and there is at least one character, 
    _False_ otherwise. Whitespace includes spaces, tabs, and new line chracters.` ]
    isspace()  {  return (this.value.match(/^\s+$/)) !== null;  }

    
    static $_isupper = [NO_BLOCK, RETURNS,
    `Returns _True_ if all cased characters 4 in the string are uppercase and there is at least one cased character,
    _False_ otherwise.` ]
    isupper()  { 
        if (this.value.match(/[a-zA-Z]/) === null) return false; // Check for no cased chracters
        return (this.value.match(/[a-z]/)) === null;
    }
    

    static $_join = [NO_BLOCK, RETURNS,
    `Joins (ie concatenates) _string2_ to the string, and returns the result.
        @string string2` ]
    join(str)  { return this.value + str }


    static $_lower = [NO_BLOCK, RETURNS,
    `Returns a copy of the string with all the cased characters converted to lowercase.` ]
    lower()  {  return this.value.toLowerCase() }


    static $_removePrefix = [NO_BLOCK, RETURNS,
    `If the string starts with the prefix string, return the original string without the prefix. Otherwise, return a 
    copy of the original string:
        @string prefix` ]
    removePrefix(prefix)  {  
        const str = this.value;
        if (str.substring(0, prefix.length) === prefix)
            return str.substring(prefix.length);
        return str;
    }


    static $_removeSuffix = [NO_BLOCK, RETURNS,
    `If the string ends with the suffix string, return the original string without the suffix. Otherwise, return a 
    copy of the original string:
        @string suffix` ]
    removeSuffix(suffix)  {  
        const str = this.value;
        if (str.substring(str.length-suffix.length) === suffix)
            return str.substring(0, str.length-suffix.length);
        return str;
    }
    

    static $_replace = [NO_BLOCK, RETURNS,
    `Return a copy of the string with all occurrences of substring _old_ replaced by _new_. 
        @string old
        @string new` ]
    replace(old, rep)  {  return this.value.replaceAll(old, rep)  }


    static $_split = [NO_BLOCK, RETURNS,
    `Returns a list of the words in the string, using _seperator_ as the delimiter string. If no separator is given,
    words will be separated by any non-alphanumeric/non-underscore characters.
        ?string seperator The character used to separate words. `]
    split(seperator=/[^A-Za-z0-9_]+/)  {  return this.value.split(seperator)  }


    static $_startswith = [NO_BLOCK, RETURNS,
    `Returns _True_ if the string starts with the specified prefix, otherwise return _False_.
        @string prefix` ]
    startswith(prefix)  { return this.value.indexOf(prefix) === 0 }


    static $_strip = [NO_BLOCK, RETURNS,
    `Removes whitespace (spaces, tabs, etc) from the beginning and end of the string and returns the result.` ]
    strip()  { return this.value.trim() }


    static $_upper = [NO_BLOCK, RETURNS,
    `Returns a copy of the string with all the cased characters converted to uppercase.` ]
    upper()  {  return this.value.toUpperCase() }
    
}