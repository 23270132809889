import { getElementById, createElement } from "./dom-helpers";
import * as monaco from 'monaco-editor';
import { monarch } from './monarch';

const langConfig = {
    comments: { lineComment: "#" },
    onEnterRules: [
        {
        beforeText: new RegExp(
            '^\\s*(?:def|if|elif|else|while|repeat|forever|for|class).*?:\\s*$'
        ),
        action: { indentAction: monaco.languages.IndentAction.Indent }
        }
    ],
    autoClosingPairs: [
        { open: '"', close: '"' },
        { open: '(', close: ')' },
        { open: '[', close: ']' },
        { open: '{', close: '}' }
    ]
}

monaco.languages.register({ id: 'pNatural' });
monaco.languages.setMonarchTokensProvider('pNatural', monarch);
monaco.languages.setLanguageConfiguration('pNatural', langConfig);


export const centerContainer = getElementById("center-container");
export const imageContainer = createElement("div", "", { attributes: { id: "image-container "}});
export const monacoContainer = getElementById("monaco-container");
export const monacoEditor = monaco.editor.create(monacoContainer, {
    language: 'pNatural',
    minimap: { enabled: false },
    automaticLayout: true,
});

// After Monaco has attached itself to its container, we're going to remove it from the DOM
// until we have a code file loaded
centerContainer.removeChild(monacoContainer);
centerContainer.appendChild(createElement("div", "No file loaded"));