import React from "react";
import ReactDOM from "react-dom/client";
import { createElement, appendChildById } from "../dom-helpers";
import { DebugConsole } from "./debug-console";
import './tabs.css';

/**
 * Vanilla JavaScript Tabs v1.0.0
 * https://zoltantothcom.github.io/vanilla-js-tabs
 */
const Tabs=function(e){var n=document.getElementById(e.elem),a=e.open||0,r="js-tabs__title",c="js-tabs__title-active",l="js-tabs__content",o=n.querySelectorAll("."+r).length;function t(e){n.addEventListener("click",i);for(var t=u(null==e?a:e),l=0;l<o;l++)n.querySelectorAll("."+r)[l].setAttribute("data-index",l),l===t&&f(l)}function i(e){-1!==e.target.className.indexOf(r)&&(e.preventDefault(),f(e.target.getAttribute("data-index")))}function s(){[].forEach.call(n.querySelectorAll("."+l),function(e){e.style.display="none"}),[].forEach.call(n.querySelectorAll("."+r),function(e){var t,l;e.className=(t=e.className,l=new RegExp("( )"+c+"()","g"),t.replace(l,""))})}function u(e){return e<0||isNaN(e)||o<e?0:e}function f(e){s();var t=u(e);n.querySelectorAll("."+r)[t].className+=" "+c,n.querySelectorAll("."+l)[t].style.display=""}function d(){n.removeEventListener("click",i)}return t(),{open:f,update:function(e){d(),s(),t(e)},destroy:d}};

export const ERROR_TAB = 0;
export const DEBUG_CONSOLE_TAB = 1;
export const TESTS_TAB = 2;
let tabs;

const addTab = (id, title) => {
    let li = createElement("li")
    let a = createElement("a", title, { attributes: { href: "#", class: "js-tabs__title"}})
    li.appendChild(a);
    appendChildById("tabs-header", li)
    let div = createElement("div", "", { attributes: { class: "js-tabs__content", id }})
    appendChildById("tabs", div);
    return div;
}

export const initTabs = (setEntrySetter) => {   
    addTab("error-tab", "Errors/Warnings")
    const debugContainer = addTab("debug-console-tab", "Debug Console")
    addTab("tests-tab", "Test Results")

    tabs = new Tabs({
        elem: "tabs",
        open: DEBUG_CONSOLE_TAB
    });
    
    ReactDOM.createRoot(debugContainer).render(<DebugConsole setEntrySetter={setEntrySetter} />); 
}

export const openTab = (id) => tabs.open(id);


