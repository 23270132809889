import { createObject } from "../../interpreter/api-helpers";
import $Global from './_global';
import Game from './game';
import Scene from './scene';
import Rectangle from './rectangle';
import Circle from './circle';
import Text from './text';
import Sprite from './sprite';
import Events from './events';
import PlayerSprite from './player-sprite';

export const api = {
    globalObj: createObject($Global),
    classes: {
        Game, Scene, Rectangle, Circle, Text, Sprite, PlayerSprite, Events
    },
    entryPoint: "/game.pn"
}
