import * as interpreter from "../../interpreter/interpreter";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, OPTIONAL } from "../../interpreter/api-helpers";
import * as host from "../host-text";

export default class $Global {

    static $_print = [BLOCKS, NO_RETURN, // Make this a blocking function so it can be interupted in an infinite loop
    `Prints a value to the terminal.
        @any value          The value to be printed
        ?boolean newline    Whether to add a new line character. Default is true. `, true];
    print(value, newline={value: true}) {
        host.print(value.$toPrintable(), newline.value)
        setTimeout(() => interpreter.resolve(), 0);
    }


    static $_clear = [BLOCKS, NO_RETURN, // Make this a blocking function so it can be interupted in an infinite loop
    `Clears the terminal`];
    clear() {
        host.clearTerminal();
        setTimeout(() => interpreter.resolve(), 0);
    }


    static $_input = [BLOCKS, RETURNS,
    `Prompts the user for a value, and returns the value that the user typed in.
        @string prompt  The prompt text.    `];
    input(prompt) { host.input(prompt)  }
    

    static $_pause = [BLOCKS, NO_RETURN, 
    `Pauses program execution for the given number of milliseconds.
        @number how_long    The amount of time to pause in milliseconds. `];
    pause(ms) {  setTimeout(() => interpreter.resolve(), ms);   }
}