import * as interpreter from "../../interpreter/interpreter";
//import * as host from "../host-phaser";
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN } from "../../interpreter/api-helpers";
// import { createObject, createBuiltInTypeObject } from "../../interpreter/api-helpers";
// import * as symbolTable from "../../interpreter/execution/model/symbol-tables";

export default class $Global {
    // static $_updateAll = [BLOCKS, NO_RETURN, 
    // `desc `];
    // updateAll() {  
    //     console.log("update all");
    //     interpreter.resolve();
    // }
}