import * as interpreter from "../interpreter/interpreter";
import * as phaserApi from "./phaser-api/phaser-api";
import phaser from "./phaser";
import { handleEvent as serverRequest } from "../ui/file-explorer/handlers";
import { createBuiltInTypeObject, createObject } from "../interpreter/api-helpers";
import Game from "./phaser-api/game";
import Scene from "./phaser-api/scene";

let config;
const images = {};
let isDownloading = true;
const container = document.getElementById('output-container');

export const hasEntryPoint = true;

// Functions called by UI
export const init = (cfg) => {
    config = cfg;
    interpreter.init({
        ...config, 
        terminationHandler,
        errorHandler,
        hostApi: phaserApi.api
    });    
}

export const handleTreeUpdate = newTree => {
    const getImageFilenames = (dir, dirname) => {
        let names = [];
        dir.forEach(entry => {
            const ext = entry.name.lastIndexOf('.') < 0 ? "" : entry.name.toLowerCase().substring(entry.name.lastIndexOf('.')+1)
            if (entry.type === "file" && ["jpg", "png", "gif"].includes(ext))
                names.push(dirname+entry.name);
            if (entry.type === "folder")
                names = names.concat(getImageFilenames(entry.children, dirname+entry.name+"/"));
            
        });
        return names;
    }

    const imageFiles = getImageFilenames(newTree.children, "/root/");
    const toDownload = imageFiles.filter(fn => !(fn in images));
    
    const downloadNext = () => {
        if (toDownload.length === 0) {
            if (isDownloading)
                container.innerHTML = "Ready";
            isDownloading = false;
            return;
        }
        isDownloading = true;    

        const path = toDownload.pop();
        const onImageLoaded = (request, result) => {
            const image = new Image();
            image.src = 'data:image/jpg;base64,'+result.fileContent;
            images[path] = image;
            downloadNext();
        }
        container.innerHTML = "Downloading image: "+path;
        serverRequest("downloadFile", { path }, onImageLoaded, 
            (err) => console.log(err),
            () => {}
        );
    }
    downloadNext();
}

export const run = async (code, curTree) => {
    if (isDownloading) {
        alert("Please wait until all images have downloaded before running.");
        config.terminationHandler();
        return;
    }

    container.innerHTML = "";
    await phaser.init(images);
    interpreter.run(code, curTree, { 
        kbEvents: createBuiltInTypeObject([]),
        game: createObject(Game, [createObject(Scene)])
    });
}

export const forceStop = () => {
    interpreter.forceStop();
}

export const runTests = async (code, tests, onFinish) => {
    const results = await interpreter.runStructureTests(code, tests);
    onFinish(results, null);
} 

export const handleKeyPress = (keycode) => {
    // const kbEvents = interpreter.getSymbol("kbEvents");
    // if (kbEvents === null)
    //     return;
    // const evs = [...kbEvents];
    // evs.push({ type: "keyPress", keycode });
    // interpreter.setSymbol("kbEvents", evs);
}


// Functions called by the Interpreter
export const errorHandler = (err) => {
    config.errorHandler(err);
}

const terminationHandler = () => {
    phaser.pause();
    config.terminationHandler();
}


// Functions called by API
//export const addGrid = () => konva.addGrid();