// import Phaser from 'phaser';
import { BLOCKS, NO_BLOCK, RETURNS, NO_RETURN, createBuiltInTypeObject, createObject } from "../../interpreter/api-helpers";
import PnObject from "../../common-api/types/object";
import PnError from "../../interpreter/pn-error";
import Scene from './scene';
import GameObject from './game-object';
const reportError = (id, args) => { 
    throw new PnError("host-phaser.phaser-api.events."+id, args);
}

export const EVENT_FOR_SCENE = 0;
export const EVENT_FOR_ALL = -1;

export default class Events extends PnObject {

    constructor(arr) {
        super();
        this.filter = this.filter.bind(this);
        this.hasMessage = this.hasMessage.bind(this);
        this.hasKeyDown = this.hasKeyDown.bind(this);
        this.hasKeyUp = this.hasKeyUp.bind(this);
        this.hasMouseDown = this.hasMouseDown.bind(this);
        this.hasMouseButtonDown = this.hasMouseButtonDown.bind(this);
        this.hasMouseUp = this.hasMouseUp.bind(this);
        this.hasCollide = this.hasCollide.bind(this);
        this.hasCollideWith = this.hasCollideWith.bind(this);
        this.all = createBuiltInTypeObject(arr);
        this.$rawAll = arr;
    }

    static $propdoc_all = "A list of all events in the set."

    static $_filter = [NO_BLOCK, RETURNS, 
    `Returns a new *Events* object containing only the events that belong to the given game object or scene. 
        @object who The game object or scene that we want to retrieve events for.
    `];
    filter(who) {  
        if (who instanceof Scene) {
            const filteredList = this.$rawAll.filter(
                ev => ev.goId === EVENT_FOR_SCENE || ev.goId === EVENT_FOR_ALL);
            return createObject(Events, [filteredList]);
        }
        else if (who instanceof GameObject) {
            const filteredList = this.$rawAll.filter(
                ev => ev.goId === who.$goId || ev.goId === EVENT_FOR_ALL);
            return createObject(Events, [filteredList]);
        }
        else {
            reportError("invalid_filter_param")
        }
    }

    static $_hasMessage = [NO_BLOCK, RETURNS, 
    `Returns *True* if the given message is in the set of events, otherwise returns *False*. 
        @string message
    `];
    hasMessage(msg) {  
        return this.$rawAll.filter(ev => ev.type === "message" && ev.message === msg).length > 0;
    }

    static $_hasKeyDown = [NO_BLOCK, RETURNS, 
    `Returns *True* if there is a keyDown event for the given key in the set of events, otherwise returns *False*.  
        @string key
    `];
    hasKeyDown(key) {  
        return this.$rawAll.filter(ev => ev.type === "keydown" && ev.key === key).length > 0;
    }

    static $_hasKeyUp = [NO_BLOCK, RETURNS, 
    `Returns *True* if there is a keyUp event for the given key in the set of events, otherwise returns *False*. 
        @string key
    `];
    hasKeyUp(key) {  
        return this.$rawAll.filter(ev => ev.type === "keyup" && ev.key === key).length > 0;
    }

    static $_hasMouseDown = [NO_BLOCK, RETURNS, 
    `Returns *True* if there is a mouseDown event in the set of events, otherwise returns *False*.  
    `];
    hasMouseDown() {  
        return this.$rawAll.filter(ev => ev.type === "mouseDown").length > 0;
    }

    static $_hasMouseUp = [NO_BLOCK, RETURNS, 
    `Returns *True* if there is a mouseUp event in the set of events, otherwise returns *False*.  
    `];
    hasMouseUp() {  
        return this.$rawAll.filter(ev => ev.type === "mouseUp").length > 0;
    }

    static $_hasMouseButtonDown = [NO_BLOCK, RETURNS, 
    `Returns *True* if there is a mouseDown event in the set of events, otherwise returns *False*.  
        @number button
    `];
    hasMouseButtonDown(button) {  
        return this.$rawAll.filter(ev => ev.type === "mouseDown" && ev.button === button).length > 0;
    }


    static $_hasCollide = [NO_BLOCK, RETURNS, 
    `Returns *True* if there is a collide event in the set of events, otherwise returns *False*.  
    `];
    hasCollide() {  
        return this.$rawAll.filter(ev => ev.type === "collide").length > 0;
    }

    static $_hasCollideWith = [NO_BLOCK, RETURNS, 
    `Returns *True* if there is a collide event with the give group in the set of events, otherwise returns *False*.  
        @string group
    `];
    hasCollideWith(group) {  
        return this.$rawAll.filter(ev => ev.type === "collide" && ev.other.$physicsGroups.includes(group)).length > 0;
    }

}