import * as interpreter from "../interpreter/interpreter";
import * as textApi from "./text-api/text-api";
import * as xterm from "./xterm";
import * as tester from "./tester";

let config;
let state = "stopped"; // can be "stopped", "executing" or "waiting"
let testMode;

// Functions called by UI
export const init = (cfg) => {
    config = cfg;
    interpreter.init({
        ...config, 
        terminationHandler,
        errorHandler,
        hostApi: textApi.api
    });
    xterm.init();
    xterm.setEnterHandler(handleXTermInput);
}

export const run = (code, curTree) => {
    testMode = false;
    state = "executing";
    xterm.clear();
    xterm.print("Running program...", true);
    xterm.print("", true);
    interpreter.run(code, curTree);
}

export const forceStop = () => {
    interpreter.forceStop();
}

export const runTests = (code, tests, onFinish) => {
    testMode = true;
    tester.runTests(code, tests, onFinish);
} 

// Functions called by the Interpreter
const terminationHandler = () => {
    if (testMode) 
        return tester.handleTermination();

    if (state === "stopped")
        return;
    state = "stopped";
    config.terminationHandler();
    xterm.print("");
    xterm.print("Program Terminated", true, "\u001b[31m\u001b[1m")
}


export const errorHandler = (err) => {
    if (testMode) 
        return tester.handleError(err);

    state = "stopped";
    xterm.print("");
    xterm.print("Program Terminated due to Error", true, "\u001b[31m\u001b[1m")
    config.errorHandler(err);
}


// Functions called by XTerm
const handleXTermInput = (input) => {
    if (state === "stopped") {
        xterm.print("Error: Program is not running", true, "\u001b[31m\u001b[1m");
    }
    else if (state === "executing") {
        xterm.print("Error: Unexpected input", true, "\u001b[31m\u001b[1m");
    }
    else if (state === "waiting") {
        const numVal = Number(input);
        interpreter.resolve(isNaN(numVal) || input === "" ? input : numVal);
        state = "executing";
    }
    else {
        throw "Invalid state: "+state;
    }
}


// Functions called by API
export const print = (data, newline) => {
    if (testMode) 
        return tester.handlePrintStatement(data);

    if (typeof data === "string") {
        xterm.print(data, newline, "\u001b[36m\u001b[1m");
    }

    if (typeof data === "number") {
        xterm.print(""+data, newline, "\u001b[33m\u001b[1m");
    }
    
    if (typeof data === "boolean") {
        xterm.print(data ? "True" : "False", newline, "\u001b[33m\u001b[1m");
    }

    if (typeof data === "object" && Array.isArray(data)) {
        xterm.print("["+data+"]", newline, "\u001b[33m\u001b[1m")
    }
}

export const input = (prompt) => {
    if (testMode) 
        return tester.handleInputStatement(prompt);

    prompt = prompt === undefined ? "" : prompt;
    xterm.print(prompt+" ", false, "\u001b[36m\u001b[1m");
    xterm.focus();
    state = "waiting";
}

export const clearTerminal = () => {
    xterm.clear();
}